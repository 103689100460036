import React from 'react';

const StyleChat = () => {
    return (
        <div>
            <style jsx>{`
                .hover-bg-light:hover {
                    background-color: #f8fafc;
                }

                .transition-all {
                    transition: all 0.3s ease;
                }

                .chat-message-user,
                .chat-message-admin {
                    display: flex;
                    margin-bottom: 1rem;
                }

                .chat-message-admin {
                    justify-content: flex-end;
                }

                .message-bubble {
                    max-width: 80%;
                    padding: 12px 16px;
                    border-radius: 15px;
                    position: relative;
                }

                .user-bubble {
                    background: white;
                    border: 1px solid #e2e8f0;
                    border-top-left-radius: 5px;
                }

                .admin-bubble {
                    background: #2563eb;
                    color: white;
                    border-top-right-radius: 5px;
                }

                .message-content {
                    margin-bottom: 4px;
                    word-wrap: break-word;
                }

                .message-time {
                    font-size: 0.75rem;
                    opacity: 0.7;
                }

                /* Filter section styles */
                .form-select,
                .form-control {
                    border: 1px solid #e2e8f0;
                    font-size: 0.875rem;
                }

                .form-select:focus,
                .form-control:focus {
                    border-color: #2563eb;
                    box-shadow: 0 0 0 0.2rem rgba(37, 99, 235, 0.25);
                }

                /* Badge styles */
                .badge {
                    font-weight: 500;
                    padding: 0.35em 0.65em;
                }

                /* Custom scrollbar */
                ::-webkit-scrollbar {
                    width: 6px;
                    height: 6px;
                }

                ::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }

                ::-webkit-scrollbar-thumb {
                    background: #c1c1c1;
                    border-radius: 3px;
                }

                ::-webkit-scrollbar-thumb:hover {
                    background: #a8a8a8;
                }

                /* Responsive adjustments */
                @media (max-width: 992px) {
                    .col-lg-4,
                    .col-lg-8 {
                        max-height: none;
                    }

                    .overflow-auto {
                        height: auto;
                        max-height: 400px;
                    }
                }
            `}</style>
        </div>
    );
};

export default StyleChat;