import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import auth from '../../firebase.init';
import DashboardSplineChart from './DashboardSplineChart';
import StatisticsSplineChartNew from './StatisticsSplineChartNew';

const AdminMainDashboardContent = () => {
    const [user] = useAuthState(auth);
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [sellers, setSellers] = useState([]);
    const [products, setProducts] = useState([]);
    const [withdrawals, setWithdrawals] = useState([]);

    const fetchOrders = () => {
        if (user?.email) {
            fetch(`https://server.enjoywiki.com/flip-server/orders`)
                .then((res) => res.json())
                .then((info) => {
                    setOrders(info.reverse());
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching orders:", error);
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/products`)
            .then((res) => res.json())
            .then(setProducts);

        fetch(`https://server.enjoywiki.com/flip-server/users`)
            .then((res) => res.json())
            .then((info) => setSellers(info.filter(user => user.userRole === 'Seller')));

        fetch(`https://server.enjoywiki.com/flip-server/all-withdraw`)
            .then((res) => res.json())
            .then(setWithdrawals);
    }, []);

    useEffect(() => {
        fetchOrders();
    }, [user]);

    const calculateDashboardStats = () => {
        const paidOrders = orders.filter(order => {
            const status = order?.paymentStatus?.toLowerCase() || '';
            return status === 'paid' || status === 'completed';
        });

        const totalSales = paidOrders.reduce((sum, order) =>
            sum + (Number(order?.packagePrice) || 0), 0
        );

        const completedWithdrawals = withdrawals.filter(w => {
            const status = w?.withdrawalStatus?.toLowerCase() || '';
            return status === 'paid' || status === 'completed';
        });

        const totalWithdrawn = completedWithdrawals.reduce((sum, w) =>
            sum + (Number(w?.withdrawalAmount) || 0), 0
        );

        return {
            totalSales: totalSales.toFixed(2),
            totalProfit: (totalSales * 0.2).toFixed(2),
            totalWithdrawn: totalWithdrawn.toFixed(2),
            totalProducts: products?.length || 0,
            totalOrders: orders?.length || 0,
            totalSellers: sellers?.length || 0
        };
    };

    if (loading) return <div>Loading...</div>;

    const stats = calculateDashboardStats();

    const getStatusBadgeClass = (status) => {
        status = status.toLowerCase();
        if (status === 'paid' || status === 'completed') return 'status-badge completed';
        if (status === 'pending') return 'status-badge pending';
        return 'status-badge in-progress';
    };

    return (
        <>
            <Helmet>
                <title>Admin Dashboard | FlipBundle - Manage Your Digital Products</title>
                <meta name="description" content="Access your Seller Dashboard on FlipBundle to manage your digital products, track sales, and monitor performance. Effortlessly update your listings and grow your business." />
            </Helmet>
            <main className="dashboard-main min-vh-100">
                <div className="d-flex flex-column gap-4">
                    {/* Page Header */}
                    <div className="d-flex gap-4 flex-column flex-md-row align-items-md-center justify-content-between">
                        <div>
                            <h3 className="text-24 fw-bold text-dark-300 mb-2">Admin Dashboard</h3>
                        </div>
                    </div>
                    {/* Statistics Cards */}
                    <div className="row g-4">
                        <div className="col-xl col-md-6">
                            <div className="p-4 d-flex align-items-center dashobard-widget justify-content-between bg-white rounded-4">
                                <div>
                                    <h3 className="dashboard-widget-title fw-bold text-dark-300">
                                        ${stats.totalSales}
                                    </h3>
                                    <p className="text-18 text-dark-200">Total Sales</p>
                                </div>
                                <div className="dashboard-widget-icon">
                                    <img src="/assets/img/dashboard/icon/icon-1.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl col-md-6">
                            <div className="p-4 d-flex align-items-center dashobard-widget justify-content-between bg-white rounded-4">
                                <div>
                                    <h3 className="dashboard-widget-title fw-bold text-dark-300">
                                        ${stats.totalProfit}
                                    </h3>
                                    <p className="text-18 text-dark-200">Total Profit</p>
                                </div>
                                <div className="dashboard-widget-icon">
                                    <img src="/assets/img/dashboard/icon/icon-2.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl col-md-6">
                            <div className="p-4 d-flex align-items-center dashobard-widget justify-content-between bg-white rounded-4">
                                <div>
                                    <h3 className="dashboard-widget-title fw-bold text-dark-300">
                                        ${stats.totalWithdrawn}
                                    </h3>
                                    <p className="text-18 text-dark-200">Total Withdrawn</p>
                                </div>
                                <div className="dashboard-widget-icon">
                                    <img src="/assets/img/dashboard/icon/icon-3.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <DashboardSplineChart orders={orders} withdrawals={withdrawals} />
                        </div>
                    </div>
                    <div className="row g-4">
                        <div className="col-xl col-md-6">
                            <div className="p-4 d-flex align-items-center dashobard-widget justify-content-between bg-white rounded-4">
                                <div>
                                    <h3 className="dashboard-widget-title fw-bold text-dark-300">
                                        {stats.totalProducts}
                                    </h3>
                                    <p className="text-18 text-dark-200">Total Products</p>
                                </div>
                                <div className="dashboard-widget-icon">
                                    <img src="/assets/img/dashboard/icon/icon-1.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl col-md-6">
                            <div className="p-4 d-flex align-items-center dashobard-widget justify-content-between bg-white rounded-4">
                                <div>
                                    <h3 className="dashboard-widget-title fw-bold text-dark-300">
                                        {stats.totalOrders}
                                    </h3>
                                    <p className="text-18 text-dark-200">Total Orders</p>
                                </div>
                                <div className="dashboard-widget-icon">
                                    <img src="/assets/img/dashboard/icon/icon-2.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl col-md-6">
                            <div className="p-4 d-flex align-items-center dashobard-widget justify-content-between bg-white rounded-4">
                                <div>
                                    <h3 className="dashboard-widget-title fw-bold text-dark-300">
                                        {stats.totalSellers}
                                    </h3>
                                    <p className="text-18 text-dark-200">Total Sellers</p>
                                </div>
                                <div className="dashboard-widget-icon">
                                    <img src="/assets/img/dashboard/icon/icon-3.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <StatisticsSplineChartNew
                            products={products}
                            orders={orders}
                            sellers={sellers}
                        />
                    </div>
                    {/* Latest Orders */}
                    <div>
                        <h3 className="text-24 fw-bold text-dark-300 mb-2">Latest Orders</h3>
                        <div className="overflow-x-auto">
                            <div className="w-100">
                                <table className="w-100 dashboard-table">
                                    <thead className="pb-3">
                                        <tr>
                                            <th scope="col" className="ps-4">Product Name</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Order Date</th>
                                            <th scope="col">Customer Name</th>
                                            <th scope="col" className="text-start">Payment Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders.slice(0, 10).map((order) => (
                                            <tr key={order._id}>
                                                <td>
                                                    <div className="d-flex gap-3 align-items-center project-name">
                                                        <div className="order-img">
                                                            <img
                                                                src={order.featuredImage || "/assets/img/dashboard/orders/o-1.png"}
                                                                alt=""
                                                                className="rounded-circle"
                                                                style={{ width: '40px', height: '40px' }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <Link to={`/${order.productSlug}/${order.productId}`} className="text-dark-200 project-link">
                                                                {order.packageName}
                                                            </Link>
                                                            <small className="d-block text-muted">
                                                                ID: {order.orderId}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-dark-200">${order.packagePrice}</td>
                                                <td className="text-dark-200">{order.orderDate}</td>
                                                <td className="text-dark-200">
                                                    <div>
                                                        {order.customerName}
                                                        <small className="d-block text-muted">
                                                            {order.customerEmail}
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className={getStatusBadgeClass(order.paymentStatus)}>
                                                        {order.paymentStatus}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                        {orders.length === 0 && (
                                            <tr>
                                                <td colSpan="6" className="text-center py-4">
                                                    No orders found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                {orders.length > 9 && (
                                    <div className="d-flex justify-content-center py-4">
                                        <Link className="col-4 w-btn-secondary-lg" to="/admin/orders" style={{ textAlign: "center" }}>All Orders</Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <style jsx>{`
                .status-badge {
                    padding: 6px 12px;
                    border-radius: 4px;
                    font-size: 14px;
                    font-weight: 500;
                    display: inline-block;
                }
                
                .status-badge.completed {
                    background-color: #e8f5e9;
                    color: #2e7d32;
                }
                
                .status-badge.pending {
                    background-color: #fff3e0;
                    color: #ef6c00;
                }
                .status-badge.in-progress {
                    background-color: #e3f2fd;
                    color: #1565c0;
                }
                .project-link {
                    color: #333;
                    text-decoration: none;
                    font-weight: 500;
                }
                .project-link:hover {
                    color: #007bff;
                }
                .dashboard-table thead th {
                    background-color: #f8f9fa;
                    padding: 12px;
                    font-weight: 600;
                }
                .dashboard-table tbody td {
                    padding: 16px 12px;
                    border-bottom: 1px solid #eee;
                }
                .text-dark-200 {
                    color: #6c757d;
                }
                .text-dark-300 {
                    color: #343a40;
                }
            `}</style>
        </>
    );
};

export default AdminMainDashboardContent;
