import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useParams, useNavigate } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import auth from "../../firebase.init";
import { Helmet } from "react-helmet";

const PayNow = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [order, setOrder] = useState(null);
    const [paypal, setPaypal] = useState([]);
    const [user] = useAuthState(auth);
    const currentDomain = window.location.origin;
    const [error, setError] = useState(null);
    const [paypalLoaded, setPaypalLoaded] = useState(false);

    // PayPal configuration options
    const paypalOptions = {
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: "USD",
        intent: "capture",
        components: "buttons"
    };

    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/order/${id}`)
            .then((res) => res.json())
            .then((info) => {
                setOrder(info);
            })
            .catch(err => {
                console.error("Error loading order:", err);
                setError("Failed to load order details");
            });
    }, [id]);

    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/payments`)
            .then((res) => res.json())
            .then((info) => {
                setPaypal(info);
                setPaypalLoaded(true);
            })
            .catch(err => {
                console.error("Error loading PayPal config:", err);
                setError("Failed to load payment configuration");
            });
    }, []);

    const createOrder = (data, actions) => {
        if (!order) {
            console.error("Order data not available");
            return;
        }

        console.log("Creating PayPal order with amount:", order.totalPrice);

        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: order.totalPrice,
                        breakdown: {
                            item_total: {
                                currency_code: "USD",
                                value: order.packagePrice
                            },
                            handling: {
                                currency_code: "USD",
                                value: order.processingFee
                            }
                        }
                    },
                    description: order.packageName,
                    custom_id: order.orderId,
                    invoice_id: order.paymentId
                },
            ],
        });
    };

    const onApprove = async (data, actions) => {
        try {
            const details = await actions.order.capture();
            console.log('Payment completed', details);

            await fetch(`https://server.enjoywiki.com/flip-server/update-payment-status/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    paypalTransactionID: details?.purchase_units[0]?.payments?.captures[0]?.id,
                    payerEmail: details.payer?.email_address,
                    captureTime :details?.purchase_units[0]?.payments?.captures[0]?.create_time,
                    paymentId: order.paymentId,
                    orderId: order.orderId,
                    status: 'Paid',
                    orderStatus: 'Completed',
                    paymentMethod: 'Paypal'
                }),
            });

            navigate(`/received-payment/${order._id}/${order.paymentId}`);
        } catch (error) {
            console.error('Payment failed:', error);
            setError('Payment failed. Please try again.');
            navigate(`/cancelled-payment/${order._id}`);
        }
    };

    if (!order || !paypalLoaded) {
        return <div className="text-center py-5">Loading...</div>;
    }

    return (
        <>
            <Helmet>
                <title>Pay Now | FlipBundle - Complete Your Payment to Access Products</title>
                <meta name="description" content="Complete your payment on FlipBundle to unlock your purchased digital products. Secure payment options for a seamless transaction." />
            </Helmet>

            <main>
                {/* Breadcrumb Section */}
                <section
                    className="w-breadcrumb-area"
                    style={{
                        backgroundImage:
                        "url(assets/img/breadcrumb-bg.svg)"
                    }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-auto">
                                <div className="position-relative z-2 aos-init">
                                    <h2 className="section-title-light mb-2">Payment</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb w-breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page">
                                                Payment
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Payment Details Section */}
                <section className="py-110 bg-offWhite">
                    <div className="container">
                        <div className="row justify-content-center row-gap-4">
                            <div className="col-xl-10 mt-30 mt-xl-0">
                                <aside className="d-flex flex-column gap-4">
                                    <div>
                                        <div className="package-tab-content bg-white p-4 rounded">
                                            {/* Order Details */}
                                            <div className="mb-4">
                                                <div className="d-flex mb-4 justify-content-between align-items-center">
                                                    <h4 className="package-name fw-semibold mb-0">Order ID: {order.orderId}</h4>
                                                    <h3 className="package-price fw-bold mb-0">Total: ${order.totalPrice}</h3>
                                                </div>

                                                <div className="mb-4">
                                                    <h5 className="mb-3">{order.packageName}</h5>
                                                    {/* {order.featuredImage && (
                                                        <img 
                                                            src={order.featuredImage} 
                                                            alt={order.packageName}
                                                            className="img-fluid rounded mb-3"
                                                            style={{ maxHeight: '200px', objectFit: 'cover' }}
                                                        />
                                                    )} */}
                                                </div>

                                                {/* Order Information */}
                                                <div className="bg-light p-3 rounded mb-4">
                                                    <h5 className="mb-3">Order Information</h5>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <p className="mb-2"><strong>Name:</strong> {order.customerName}</p>
                                                            <p className="mb-2"><strong>Email:</strong> {order.customerEmail}</p>
                                                            <p className="mb-2"><strong>Address:</strong> {order.address}</p>
                                                            <p className="mb-2"><strong>City:</strong> {order.cityName}</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p className="mb-2"><strong>Package Price:</strong> ${order.packagePrice}</p>
                                                            <p className="mb-2"><strong>Processing Fee:</strong> ${order.processingFee}</p>
                                                            <p className="mb-2"><strong>Total Price:</strong> ${order.totalPrice}</p>
                                                            <p className="mb-2"><strong>Status:</strong> {order.paymentStatus}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Payment Section */}
                                                <div className="text-center">
                                                    <PayPalScriptProvider options={paypalOptions}>
                                                        {error && (
                                                            <div className="alert alert-danger mb-3">
                                                                {error}
                                                            </div>
                                                        )}

                                                        <div className="mb-4">
                                                            <PayPalButtons
                                                                createOrder={createOrder}
                                                                onApprove={onApprove}
                                                                style={{
                                                                    layout: "vertical",
                                                                    color: "blue",
                                                                    shape: "rect",
                                                                    label: "pay"
                                                                }}
                                                                onError={(err) => {
                                                                    console.error('PayPal error:', err);
                                                                    setError('There was an error with PayPal. Please try again.');
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="text-center mt-2">
                                                            <small className="text-muted">
                                                                * 3% processing fee included in total price
                                                            </small>
                                                        </div>
                                                    </PayPalScriptProvider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default PayNow;