import React, { useEffect, useState } from 'react';
import { AlertCircle, CheckCircle } from 'lucide-react';
import toast from 'react-hot-toast';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';

const DisputeManagement = ({ order, onStatusUpdate }) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const [buyerNote, setBuyerNote] = useState(order?.disputeDetails?.buyerNote || '');
    const [sellerNote, setSellerNote] = useState(order?.disputeDetails?.sellerNote || '');
    const [status, setStatus] = useState(null);
    const [user] = useAuthState(auth);
    const [seller, setSeller] = useState({});

    // Calculate refund amount and updated balance
    const marketFeePercentage = 20;
    const packagePrice = parseFloat(order?.packagePrice) || 0;
    const refundAmount = packagePrice ? 
        packagePrice - (packagePrice * (marketFeePercentage / 100)) : 0;
    
    // Ensure seller.currentBalance is a number and calculate updated balance
    const currentBalance = parseFloat(seller?.currentBalance) || 0;
    const updatedBalance = Math.max(0, currentBalance - refundAmount);

    // Check if order is already refunded
    const isRefunded = order?.paymentStatus === 'Refunded';

    const alertStyles = {
        success: {
            backgroundColor: '#d1e7dd',
            color: '#0f5132',
            border: '1px solid #badbcc',
            borderRadius: '4px',
            padding: '1rem',
            marginBottom: '1rem',
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem'
        },
        error: {
            backgroundColor: '#f8d7da',
            color: '#842029',
            border: '1px solid #f5c2c7',
            borderRadius: '4px',
            padding: '1rem',
            marginBottom: '1rem',
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem'
        },
        info: {
            backgroundColor: '#cff4fc',
            color: '#055160',
            border: '1px solid #b6effb',
            borderRadius: '4px',
            padding: '1rem',
            marginBottom: '1rem',
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem'
        }
    };

    useEffect(() => {
        const fetchSeller = async () => {
            try {
                if (order?.sellerEmail) {
                    const response = await fetch(`https://server.enjoywiki.com/flip-server/users?userEmail=${order.sellerEmail}`);
                    if (!response.ok) {
                        throw new Error("Failed to fetch seller");
                    }
                    const data = await response.json();
                    if (data && data.length > 0) {
                        // Ensure currentBalance is a number
                        const sellerData = {
                            ...data[0],
                            currentBalance: parseFloat(data[0].currentBalance) || 0
                        };
                        setSeller(sellerData);
                    }
                }
            } catch (error) {
                console.error("Error fetching seller:", error);
                setStatus({
                    type: 'error',
                    message: 'Failed to fetch seller information. Please try again.'
                });
            }
        };

        fetchSeller();
    }, [order?.sellerEmail]);

    const handleDispute = async () => {
        if (isRefunded) {
            setStatus({ type: 'info', message: 'This order has already been refunded.' });
            return;
        }

        if (!buyerNote || !sellerNote) {
            setStatus({ type: 'error', message: 'Please provide notes for both buyer and seller' });
            return;
        }

        setIsProcessing(true);
        try {
            // Process the dispute/refund
            const response = await fetch(`https://server.enjoywiki.com/flip-server/orders/dispute/${order._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    paymentStatus: 'Refunded',
                    buyerNote,
                    sellerNote,
                    orderId: order.orderId,
                    buyerEmail: order.customerEmail,
                    sellerEmail: order.sellerEmail,
                    packageName: order.packageName,
                    packagePrice: packagePrice
                }),
            });

            if (!response.ok) throw new Error('Failed to process refund');

            // Update seller's balance
            const balanceResponse = await fetch(`https://server.enjoywiki.com/flip-server/user-balance/${seller._id}`, {
                method: "PUT",
                headers: {
                    "Content-type": "application/json",
                },
                body: JSON.stringify({
                    currentBalance: updatedBalance
                })
            });

            if (!balanceResponse.ok) throw new Error('Failed to update seller balance');

            toast("Refund processed successfully");
            setStatus({
                type: 'success',
                message: 'Refund processed successfully. Notifications sent to buyer and seller.'
            });
            onStatusUpdate();
        } catch (error) {
            console.error("Error processing refund:", error);
            setStatus({
                type: 'error',
                message: 'Failed to process refund. Please try again.'
            });
        } finally {
            setIsProcessing(false);
        }
    };

    // Format currency values for display
    const formatCurrency = (value) => {
        const numValue = parseFloat(value);
        return isNaN(numValue) ? '0.00' : numValue.toFixed(2);
    };

    return (
        <div style={{ fontFamily: "'Inter', sans-serif" }}>
            {/* Display refunded status if applicable */}
            {isRefunded && (
                <div className="mb-4" style={alertStyles.info}>
                    <AlertCircle size={20} />
                    <span>This order has already been refunded and cannot be processed again.</span>
                </div>
            )}

            {/* Order Summary */}
            <div
                className="mb-4 p-3"
                style={{
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                    border: '1px solid #e9ecef'
                }}
            >
                <div className="row">
                    <div className="col-md-6">
                        <h6 className="mb-2" style={{ color: '#6c757d', fontWeight: 500 }}>Order Details</h6>
                        <p className="mb-1" style={{ fontSize: '0.9rem' }}>
                            <strong>Product Name:</strong> {order.packageName}
                        </p>
                        <p className="mb-1" style={{ fontSize: '0.9rem' }}>
                            <strong>Order ID:</strong> {order.orderId}
                        </p>
                        <p className="mb-1" style={{ fontSize: '0.9rem' }}>
                            <strong>Amount:</strong> ${formatCurrency(packagePrice)} USD
                        </p>
                    </div>
                    <div className="col-md-6">
                        <h6 className="mb-2" style={{ color: '#6c757d', fontWeight: 500 }}>Customer Details</h6>
                        <p className="mb-1" style={{ fontSize: '0.9rem' }}>
                            <strong>Name:</strong> {order.customerName}
                        </p>
                        <p className="mb-1" style={{ fontSize: '0.9rem' }}>
                            <strong>Email:</strong> {order.customerEmail}
                        </p>
                    </div>
                </div>
            </div>

            {/* Seller Information */}
            <div
                className="mb-4 p-3"
                style={{
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                    border: '1px solid #e9ecef'
                }}
            >
                <div className="row">
                    <div className="col-md-12">
                        <h6 className="mb-2" style={{ color: '#6c757d', fontWeight: 500 }}>Seller Information</h6>
                        <p className="mb-1" style={{ fontSize: '0.9rem' }}>
                            <strong>Email:</strong> {order.sellerEmail}
                        </p>
                        <p className="mb-1" style={{ fontSize: '0.9rem' }}>
                            <strong>Current Seller Balance:</strong> ${formatCurrency(currentBalance)} USD
                        </p>
                        <p className="mb-1" style={{ fontSize: '0.9rem' }}>
                            <strong>Refund Amount:</strong> ${formatCurrency(refundAmount)} USD (Package Price - 20% Market Fee)
                        </p>
                        <p className="mb-1" style={{ fontSize: '0.9rem' }}>
                            <strong>After Refunded Balance:</strong> ${formatCurrency(updatedBalance)} USD
                        </p>
                    </div>
                </div>
            </div>

            {/* Notes Section */}
            <div className="row g-3">
                <div className="col-md-6">
                    <div className="form-group">
                        <label
                            className="form-label"
                            style={{
                                fontSize: '0.875rem',
                                fontWeight: 500,
                                color: '#344054',
                                marginBottom: '0.5rem'
                            }}
                        >
                            Note to Buyer
                        </label>
                        <textarea
                            className="form-control"
                            rows="4"
                            value={buyerNote}
                            onChange={(e) => setBuyerNote(e.target.value)}
                            placeholder="Explain refund details to buyer..."
                            style={{
                                fontSize: '0.875rem',
                                border: '1px solid #d0d5dd',
                                borderRadius: '8px',
                                padding: '0.625rem',
                                resize: 'vertical'
                            }}
                        />
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                        <label
                            className="form-label"
                            style={{
                                fontSize: '0.875rem',
                                fontWeight: 500,
                                color: '#344054',
                                marginBottom: '0.5rem'
                            }}
                        >
                            Note to Seller
                        </label>
                        <textarea
                            className="form-control"
                            rows="4"
                            value={sellerNote}
                            onChange={(e) => setSellerNote(e.target.value)}
                            placeholder="Explain dispute details to seller..."
                            style={{
                                fontSize: '0.875rem',
                                border: '1px solid #d0d5dd',
                                borderRadius: '8px',
                                padding: '0.625rem',
                                resize: 'vertical'
                            }}
                        />
                    </div>
                </div>
            </div>

            {/* Status Alert */}
            {status && (
                <div className='mt-3' style={status.type === 'error' ? alertStyles.error : alertStyles.success}>
                    {status.type === 'error' ?
                        <AlertCircle size={20} /> :
                        <CheckCircle size={20} />
                    }
                    <span>{status.message}</span>
                </div>
            )}

            {/* Action Button */}
            <div className="mt-4 d-grid">
                <button
                    onClick={handleDispute}
                    disabled={isProcessing || isRefunded}
                    className={`btn ${isRefunded ? 'btn-secondary' : isProcessing ? 'btn-secondary' : 'btn-danger'}`}
                    style={{
                        padding: '0.75rem 1.5rem',
                        fontWeight: 500,
                        fontSize: '0.875rem',
                        borderRadius: '8px',
                        transition: 'all 0.2s ease',
                        boxShadow: 'none',
                        opacity: isRefunded ? 0.65 : 1
                    }}
                >
                    <div className="d-flex align-items-center justify-content-center gap-2">
                        {isProcessing && (
                            <div
                                className="spinner-border spinner-border-sm"
                                role="status"
                                style={{ width: '1rem', height: '1rem' }}
                            >
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        )}
                        <span>
                            {isRefunded 
                                ? 'Already Refunded' 
                                : isProcessing 
                                    ? 'Processing Refund...' 
                                    : 'Process Refund'
                            }
                        </span>
                    </div>
                </button>
            </div>

            {/* Warning Message */}
            <div
                className="mt-3"
                style={{
                    fontSize: '0.75rem',
                    color: '#6c757d',
                    textAlign: 'center'
                }}
            >
                <p className="mb-0">
                    {isRefunded 
                        ? 'This refund has been processed and cannot be modified.' 
                        : 'This action cannot be undone. Both parties will be notified via email.'
                    }
                </p>
            </div>
        </div>
    );
};

export default DisputeManagement;