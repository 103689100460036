import React from 'react';

const VideoPlayer = () => {
  const containerStyle = {
    maxWidth: '1200px',
    margin: '2rem auto',
    padding: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    boxShadow: '0 4px 6px rgba(255, 255, 255, 0.1)',
  };

  const videoWrapperStyle = {
    position: 'relative',
    width: '100%',
    paddingBottom: '56.25%', // 16:9 aspect ratio
    backgroundColor: '#f8f9fa',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 2px 4px rgba(255, 255, 255, 0.08)',
  };

  const iframeStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 'none',
    borderRadius: '8px',
  };


  return (
    <div className="container-fluid" style={containerStyle}>
      <div className="row">
        <div className="col-12">
          {/* Video Container with modified URL parameters */}
          <div style={videoWrapperStyle}>
            <iframe
              style={iframeStyle}
              src="https://www.youtube.com/embed/EeD2pK3R-gA?rel=0&modestbranding=1"
              title="How to Sell Digital Products on FlipBundle"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </div>

          {/* Video Information Card */}
          
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;