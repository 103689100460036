import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import "react-quill/dist/quill.snow.css";
import auth from "../../firebase.init";
import Pagination from "../../components/Shared/Pagination";
import { Helmet } from "react-helmet";
import LoadingAnimations from "../../components/Shared/LoadingAnimations";
import { Search, SquarePen, PlusCircle, Trash2 } from "lucide-react";
import AuthorModal from "./AuthorModal";

const AdminAllAuthors = () => {
    const [users, setUsers] = useState([]);
    const [user] = useAuthState(auth);
    const [searchTerm, setSearchTerm] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedAuthor, setSelectedAuthor] = useState(null);
    const [modalMode, setModalMode] = useState('add'); // 'add' or 'edit'
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [authorToDelete, setAuthorToDelete] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);

    // Fetch authors
    const fetchAuthors = () => {
        fetch(`https://server.enjoywiki.com/flip-server/authors`)
            .then((res) => res.json())
            .then((info) => setUsers(info.reverse()));
    };

    useEffect(() => {
        fetchAuthors();
    }, [user?.email]);

    // Filter users based on search term
    const filteredUsers = users.filter((user) => {
        const searchValue = searchTerm.toLowerCase();
        return (
            user.authorName?.toLowerCase().includes(searchValue) ||
            user.authorEmail?.toLowerCase().includes(searchValue)
        );
    });
    // Delete author
    const handleDeleteClick = (author) => {
        setAuthorToDelete(author);
        setShowDeleteConfirm(true);
    };

    const handleDeleteConfirm = async () => {
        if (!authorToDelete) return;

        setIsDeleting(true);
        try {
            const response = await fetch(`https://server.enjoywiki.com/flip-server/delete-author/${authorToDelete._id}`, {
                method: 'DELETE',
            });

            if (!response.ok) {
                throw new Error('Failed to delete author');
            }

            // Refresh the authors list
            fetchAuthors();
            setShowDeleteConfirm(false);
            setAuthorToDelete(null);
        } catch (error) {
            console.error('Error deleting author:', error);
        } finally {
            setIsDeleting(false);
        }
    };

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentService = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleAddAuthor = () => {
        setSelectedAuthor(null);
        setModalMode('add');
        setIsModalOpen(true);
    };

    const handleEditAuthor = (author) => {
        setSelectedAuthor(author);
        setModalMode('edit');
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedAuthor(null);
        setModalMode('add');
    };

    const handleModalSuccess = () => {
        fetchAuthors();
        handleModalClose();
    };

    // Reset to first page when search term changes
    useEffect(() => {
        setCurrentPage(1);
    }, [searchTerm]);

    return (
        <main className="dashboard-main min-vh-100">
            <div className="d-flex flex-column gap-4 pb-110 p-4">
                <Helmet>
                    <title>Authors List | FlipBundle - Manage Your Digital Product Listings</title>
                    <meta name="description" content="View and manage your digital product listings on FlipBundle. Easily update, promote, and track your products for increased sales." />
                </Helmet>

                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <h3 className="text-24 fw-bold text-dark-300 mb-2">Authors List</h3>
                        <ul className="d-flex align-items-center gap-2">
                            <li className="text-dark-200 fs-6">Admin Dashboard</li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width={5} height={11} viewBox="0 0 5 11" fill="none">
                                    <path d="M1 10L4 5.5L1 1" stroke="#5B5B5B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </li>
                            <li className="text-lime-300 fs-6">All Authors</li>
                        </ul>
                    </div>

                    <div className="d-flex gap-3 align-items-center">
                        {/* Search Input */}
                        <div className="position-relative">
                            <input
                                type="text"
                                className="form-control ps-5"
                                placeholder="Search by name or email..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <Search className="position-absolute" style={{ left: '10px', top: '50%', transform: 'translateY(-50%)' }} size={20} />
                        </div>

                        {/* Add Author Button */}
                        <button className="btn btn-primary d-flex align-items-center gap-2" onClick={handleAddAuthor}>
                            <PlusCircle size={20} />
                            Add Author
                        </button>
                    </div>
                </div>

                <div className="gig-info-card">
                    <div className="gig-info-body">
                        <div className="overflow-x-auto">
                            <div className="w-100">
                                <table className="w-100 dashboard-table">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="ps-4">User Info</th>
                                            <th scope="col">Status</th>
                                            <th scope="col" className="text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users === null ? (
                                            <tr>
                                                <td colSpan="5" className="text-center p-4">
                                                    <LoadingAnimations
                                                        type="pulse"
                                                        color="#2563eb"
                                                        size="md"
                                                    />
                                                </td>
                                            </tr>
                                        ) : currentService.length === 0 ? (
                                            <tr>
                                                <td colSpan="5" className="text-center p-4">
                                                    {searchTerm ? "No authors found matching your search." : "No Author Found!"}
                                                </td>
                                            </tr>
                                        ) : (
                                            currentService.map((user) => (
                                                <tr key={user._id}>
                                                    <td>
                                                        <div className="d-flex gap-3 align-items-center project-name">
                                                            <div className="order-img">
                                                                {user.authorImage && (
                                                                    <img
                                                                        src={user.authorImage}
                                                                        alt={user.userName}
                                                                        style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '8px' }}
                                                                    />
                                                                )}
                                                            </div>
                                                            <div>
                                                                {user.authorName}
                                                                <ul className="d-flex gap-1 order-category mt-1">
                                                                    {user.authorEmail && <li className="text-dark-200">{user.authorEmail}</li>}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-start">
                                                        <button
                                                            className={`btn btn-sm ${user.authorStatus === 'pending' ? 'btn-warning' :
                                                                user.authorStatus === 'Approved' ? 'btn-info' :
                                                                    user.authorStatus === 'Buyer' ? 'btn-success' :
                                                                        'btn-secondary'
                                                                }`}
                                                        >
                                                            {user.authorStatus}
                                                        </button>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="d-flex justify-content-center gap-2">
                                                            <button
                                                                className="btn btn-primary btn-sm d-flex align-items-center gap-1"
                                                                onClick={() => handleEditAuthor(user)}
                                                            >
                                                                <SquarePen size={15} />
                                                                Edit
                                                            </button>
                                                            <button
                                                                className="btn btn-danger btn-sm d-flex align-items-center gap-1"
                                                                onClick={() => handleDeleteClick(user)}
                                                            >
                                                                <Trash2 size={15} />
                                                                Delete
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* Pagination */}
                        {filteredUsers.length > itemsPerPage && (
                            <div className="pagination-container mt-4 d-flex justify-content-center">
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={Math.ceil(filteredUsers.length / itemsPerPage)}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        )}
                    </div>
                </div>

                {/* Author Modal */}
                <AuthorModal
                    isOpen={isModalOpen}
                    onClose={handleModalClose}
                    author={selectedAuthor}
                    mode={modalMode}
                    onSuccess={handleModalSuccess}
                />
                {/* Delete Confirmation Modal */}
                {showDeleteConfirm && (
                    <div className="modal-backdrop">
                        <div className="modal-content" style={{ maxWidth: '400px' }}>
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Delete</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => {
                                        setShowDeleteConfirm(false);
                                        setAuthorToDelete(null);
                                    }}
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete the author "{authorToDelete?.authorName}"? This action cannot be undone.</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => {
                                        setShowDeleteConfirm(false);
                                        setAuthorToDelete(null);
                                    }}
                                    disabled={isDeleting}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={handleDeleteConfirm}
                                    disabled={isDeleting}
                                >
                                    {isDeleting ? 'Deleting...' : 'Delete'}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                <style jsx>{`
                .modal-backdrop {
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, 0.5);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1050;
                }

                .modal-content {
                    background-color: white;
                    border-radius: 8px;
                    width: 100%;
                    animation: modalFadeIn 0.3s ease-out;
                }

                .modal-header {
                    padding: 1rem;
                    border-bottom: 1px solid #dee2e6;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .modal-title {
                    margin: 0;
                    font-size: 1.25rem;
                }

                .btn-close {
                    background: none;
                    border: none;
                    font-size: 1.5rem;
                    cursor: pointer;
                }

                .modal-body {
                    padding: 1rem;
                }

                .modal-footer {
                    padding: 1rem;
                    border-top: 1px solid #dee2e6;
                    display: flex;
                    justify-content: flex-end;
                    gap: 0.5rem;
                }

                @keyframes modalFadeIn {
                    from {
                        opacity: 0;
                        transform: translateY(-20px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }

                .btn-danger {
                    background-color: #dc3545;
                    border-color: #dc3545;
                    color: white;
                }

                .btn-danger:hover {
                    background-color: #bb2d3b;
                    border-color: #b02a37;
                }

                .btn-danger:disabled {
                    background-color: #dc3545;
                    border-color: #dc3545;
                    opacity: 0.65;
                    cursor: not-allowed;
                }
            `}</style>
            </div>
        </main>
    );
};

export default AdminAllAuthors;