import React, { useState, useEffect } from "react";

const PendingProduct = () => {
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const productId = localStorage.getItem("pendingProductPurchase");

        if (productId) {
            fetch(`https://server.enjoywiki.com/flip-server/product-info/${productId}`)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to fetch product info");
                    }
                    return res.json();
                })
                .then((info) => {
                    setProduct(info);
                    setLoading(false);
                })
                .catch((err) => {
                    console.error("Error fetching product info:", err);
                    setError(err.message);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!product) {
        return null;
    }

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="nav-all"
                role="tabpanel"
                aria-labelledby="nav-all-tab"
                tabIndex={0}
            >
                <div>
                    <div className="overflow-x-auto">
                        <div className="w-100">
                            <table className="w-100 dashboard-table">
                                <thead className="pb-3">
                                    <tr>
                                        <th scope="col" className="ps-4">
                                            Product Info
                                        </th>
                                        <th scope="col">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="d-flex gap-3 align-items-center project-name">
                                                <div className="order-img">
                                                    <img
                                                        src={product.featuredImage}
                                                        alt={product.productName}
                                                    />
                                                </div>
                                                <div>
                                                    <p className="text-dark-200">
                                                        {product.productName}
                                                    </p>
                                                    <ul className="d-flex gap-1 order-category">
                                                        <li className="text-dark-200">{product.category}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-dark-200">$30.00</td>
                                    </tr>
                                </tbody>

                            </table>
                            <div style={{
                                backgroundColor: "#e9ecef",
                                padding: "12px 16px",
                                borderRadius: "8px",
                                border: "1px solid #dee2e6",
                                marginTop: "16px",
                                marginBottom: "16px",
                                fontWeight: "600",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-center",
                                color: "#5B5B5B"
                            }}>
                                <span>To complete your purchase, please Log In or Register</span>
                               
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
};

export default PendingProduct;