import React from 'react';

const SellerSupportStyle = () => {
    return (
        <>
            <style jsx>{`
                .dashboard-main {
                    background: #f0f2f5;
                }

                .chat-container {
                    min-height: 80vh;
                    overflow: hidden;
                }

                .btn-create-ticket {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    padding: 0.75rem 1.5rem;
                    color: #fff;
                    background-color: #2563eb;
                    border: none;
                    font-weight: 600;
                    border-radius: 8px;
                    text-decoration: none;
                    transition: background-color 0.2s;
                }

                .btn-create-ticket:hover {
                    background-color: #1d4ed8;
                    color: #fff;
                }

                .filter-container {
                    border: 1px solid #e5e7eb;
                }

                .filter-group {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                }

                .filter-label {
                    font-size: 0.875rem;
                    color: #666;
                    font-weight: 500;
                }

                .filter-select,
                .filter-date {
                    padding: 0.5rem;
                    border: 1px solid #e5e7eb;
                    border-radius: 0.5rem;
                    min-width: 150px;
                    outline: none;
                    transition: border-color 0.2s;
                }

                .filter-select:focus,
                .filter-date:focus {
                    border-color: #2563eb;
                }

                .filter-date {
                    min-width: 120px;
                }

                .tickets-header {
                    padding: 1rem;
                    border-bottom: 1px solid #e5e7eb;
                    background-color: #f8fafc;
                }

                .tickets-list {
                    height: calc(80vh - 57px);
                    overflow-y: auto;
                }

                .ticket-item {
                    padding: 1rem;
                    border-bottom: 1px solid #e5e7eb;
                    cursor: pointer;
                    transition: background-color 0.2s;
                }

                .ticket-item:hover {
                    background-color: #f8fafc;
                }

                .ticket-item.active {
                    background-color: #f1f5f9;
                }

                .ticket-subject {
                    font-size: 0.9rem;
                    margin-bottom: 0.5rem;
                    color: #1a1a1a;
                }

                .ticket-info {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    flex-wrap: wrap;
                }

                .status-badge {
                    padding: 0.25rem 0.75rem;
                    border-radius: 1rem;
                    font-size: 0.75rem;
                    color: white;
                }

                .priority-badge {
                    padding: 0.25rem 0.75rem;
                    border-radius: 1rem;
                    font-size: 0.75rem;
                    color: white;
                }

                .priority-high {
                    background-color: #ef4444;
                }

                .priority-medium {
                    background-color: #f59e0b;
                }

                .priority-low {
                    background-color: #10b981;
                }

                .bg-warning {
                    background-color: #f59e0b;
                }

                .bg-success {
                    background-color: #10b981;
                }

                .bg-secondary {
                    background-color: #6b7280;
                }

                .bg-info {
                    background-color: #3b82f6;
                }

                .ticket-date {
                    font-size: 0.75rem;
                    color: #666;
                }

                .chat-area {
                    display: flex;
                    flex-direction: column;
                    height: 80vh;
                }

                .chat-header {
                    padding: 1rem;
                    border-bottom: 1px solid #e5e7eb;
                    background-color: #f8fafc;
                }

                .chat-title {
                    font-size: 1.1rem;
                    font-weight: 600;
                    margin-bottom: 0.5rem;
                }

                .chat-status {
                    display: flex;
                    gap: 0.5rem;
                    align-items: center;
                }

                .messages-container {
                    flex: 1;
                    padding: 1rem;
                    background-color: #f8fafc;
                    overflow-y: auto;
                }

                .messages-list {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                }

                .message {
                    display: flex;
                    flex-direction: column;
                    max-width: 70%;
                }

                .message-outgoing {
                    align-self: flex-end;
                }

                .message-bubble {
                    padding: 0.75rem 1rem;
                    border-radius: 1rem;
                    position: relative;
                }

                .message-outgoing .message-bubble {
                    background-color: #2563eb;
                    color: white;
                    border-bottom-right-radius: 0.25rem;
                }

                .message-incoming .message-bubble {
                    background-color: white;
                    border: 1px solid #e5e7eb;
                    border-bottom-left-radius: 0.25rem;
                }

                .message-content {
                    margin-bottom: 0.25rem;
                }

                .message-time {
                    font-size: 0.75rem;
                    opacity: 0.7;
                }

                .message-input-container {
                    padding: 1rem;
                    border-top: 1px solid #e5e7eb;
                    background-color: white;
                }

                .message-form {
                    display: flex;
                    gap: 0.75rem;
                }

                .message-input {
                    flex: 1;
                    padding: 0.75rem 1rem;
                    border: 1px solid #e5e7eb;
                    border-radius: 1.5rem;
                    outline: none;
                    transition: border-color 0.2s;
                }

                .message-input:focus {
                    border-color: #2563eb;
                }

                .send-button {
                    padding: 0.75rem 2rem;
                    background-color: #2563eb;
                    color: white;
                    border: none;
                    border-radius: 1.5rem;
                    font-weight: 500;
                    transition: background-color 0.2s;
                }

                .send-button:hover {
                    background-color: #1d4ed8;
                }

                .empty-state {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    padding: 2rem;
                }

                .loading-spinner {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100vh;
                    font-size: 1.2rem;
                    color: #2563eb;
                }

                /* Custom scrollbar */
                ::-webkit-scrollbar {
                    width: 6px;
                    height: 6px;
                }

                ::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }

                ::-webkit-scrollbar-thumb {
                    background: #c1c1c1;
                    border-radius: 3px;
                }

                ::-webkit-scrollbar-thumb:hover {
                    background: #a8a8a8;
                }

                /* Responsive Styles */
                @media (max-width: 992px) {
                    .filter-container .d-flex {
                        flex-direction: column;
                        align-items: stretch;
                    }

                    .filter-group {
                        margin-bottom: 1rem;
                    }

                    .chat-container {
                        min-height: 90vh;
                    }

                    .col-lg-4 {
                        height: 40vh;
                        overflow-y: auto;
                    }

                    .tickets-list {
                        height: calc(40vh - 57px);
                    }

                    .chat-area {
                        height: 50vh;
                    }
                }
            `}</style>

        </>
    );
};

export default SellerSupportStyle;