import React, { useState } from 'react';
import { X, Send, User, Package2, Mail, AlertCircle } from 'lucide-react';

const AdminEmailModal = ({ 
    show, 
    handleClose, 
    recipientEmail, 
    recipientName, 
    productName 
}) => {
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [status, setStatus] = useState({ type: '', message: '' });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSending(true);
        setStatus({ type: '', message: '' });

        try {
            const response = await fetch('https://server.enjoywiki.com/flip-server/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    to: recipientEmail,
                    recipientName,
                    subject,
                    content: message
                }),
            });

            const data = await response.json();

            if (response.ok) {
                setStatus({
                    type: 'success',
                    message: 'Email sent successfully!'
                });
                setTimeout(() => {
                    handleClose();
                    setSubject('');
                    setMessage('');
                    setStatus({ type: '', message: '' });
                }, 2000);
            } else {
                throw new Error(data.message || 'Failed to send email');
            }
        } catch (error) {
            setStatus({
                type: 'error',
                message: error.message || 'Failed to send email'
            });
        } finally {
            setIsSending(false);
        }
    };

    if (!show) return null;

    return (
        <div className="modal-backdrop" style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1050
        }}>
            <div className="modal-content bg-white" style={{
                width: '100%',
                maxWidth: '650px',
                borderRadius: '12px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                margin: '20px'
            }}>
                {/* Modal Header */}
                <div style={{
                    padding: '20px',
                    borderBottom: '1px solid #e5e7eb',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <div className="d-flex align-items-center gap-2">
                        <Mail size={24} className="text-primary" />
                        <h5 className="mb-0 fw-bold" style={{ color: '#1a1a1a' }}>Send Email to Seller</h5>
                    </div>
                    <button
                        onClick={handleClose}
                        className="btn btn-link p-0"
                        style={{
                            color: '#6b7280',
                            border: 'none',
                            background: 'none'
                        }}
                    >
                        <X size={24} />
                    </button>
                </div>

                {/* Modal Body */}
                <form onSubmit={handleSubmit} style={{ padding: '24px' }}>
                    {status.message && (
                        <div className={`alert ${status.type === 'success' ? 'alert-success' : 'alert-danger'} d-flex align-items-center gap-2 mb-4`}>
                            <AlertCircle size={20} />
                            <span>{status.message}</span>
                        </div>
                    )}

                    <div className="mb-4">
                        <div className="d-flex align-items-center gap-2 mb-2">
                            <User size={18} className="text-primary" />
                            <label className="form-label mb-0 fw-semibold">Recipient</label>
                        </div>
                        <div className="input-group bg-light rounded p-2">
                            <span className="text-muted">{recipientName}</span>
                            <span className="mx-2 text-muted">•</span>
                            <span className="text-primary">{recipientEmail}</span>
                        </div>
                    </div>

                    <div className="mb-4">
                        <div className="d-flex align-items-center gap-2 mb-2">
                            <Package2 size={18} className="text-primary" />
                            <label className="form-label mb-0 fw-semibold">Product</label>
                        </div>
                        <div className="bg-light rounded p-2">
                            <span className="text-muted">{productName}</span>
                        </div>
                    </div>

                    <div className="mb-4">
                        <label className="form-label fw-semibold">Subject</label>
                        <input
                            type="text"
                            className="form-control"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            placeholder="Enter email subject"
                            required
                            style={{
                                padding: '12px',
                                borderRadius: '8px',
                                border: '1px solid #e5e7eb'
                            }}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="form-label fw-semibold">Message</label>
                        <textarea
                            className="form-control"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            rows={6}
                            placeholder="Type your message here..."
                            required
                            style={{
                                padding: '12px',
                                borderRadius: '8px',
                                border: '1px solid #e5e7eb',
                                resize: 'vertical'
                            }}
                        />
                    </div>

                    {/* Modal Footer */}
                    <div className="d-flex justify-content-end gap-2 mt-4">
                        <button
                            type="button"
                            onClick={handleClose}
                            className="btn btn-light"
                            style={{
                                padding: '10px 20px',
                                borderRadius: '8px'
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={isSending}
                            className="btn btn-primary d-flex align-items-center gap-2"
                            style={{
                                padding: '10px 20px',
                                borderRadius: '8px'
                            }}
                        >
                            <Send size={18} />
                            {isSending ? 'Sending...' : 'Send Email'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AdminEmailModal;