import React, { useState, useEffect } from 'react';
import { ChevronRight, Check, Home } from 'lucide-react';

const MultiLevelCategoryDropdown = ({ categories, onCategorySelect, name }) => {
    const [mainCategories, setMainCategories] = useState([]);
    const [selectedMainCategory, setSelectedMainCategory] = useState('');
    const [subcategories, setSubcategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    useEffect(() => {
        const uniqueMainCategories = [...new Set(categories.map(category => category.mainCategory))];
        setMainCategories(uniqueMainCategories);
    }, [categories]);

    const handleMainCategoryClick = (mainCategory) => {
        setSelectedMainCategory(mainCategory);
        const filteredSubcategories = categories.filter(
            category => category.mainCategory === mainCategory
        );
        setSubcategories(filteredSubcategories);
    };

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
        setSelectedMainCategory(category.mainCategory);
        onCategorySelect({
            _id: category._id,
            categoryName: category.categoryName,
            mainCategory: category.mainCategory,
            slug: category.slug,
            categoryDetails: category.categoryDetails,
            image: category.image,
            SEOTitle: category.SEOTitle
        });
    };

    return (
        <div className="multi-level-category-dropdown w-100 border rounded">
            <input 
                type="hidden" 
                name={name} 
                value={selectedCategory?.slug || ''} 
                required 
            />
            <div className="d-flex">
                <div className="col-4 border-end">
                    {mainCategories.map((mainCategory) => (
                        <div
                            key={mainCategory}
                            className={`p-2 cursor-pointer d-flex justify-content-between align-items-center ${
                                selectedMainCategory === mainCategory ? 'bg-light' : ''
                            }`}
                            onClick={() => handleMainCategoryClick(mainCategory)}
                            style={{ cursor: 'pointer' }}
                        >
                            {mainCategory}
                            {selectedMainCategory === mainCategory && <ChevronRight size={16} />}
                        </div>
                    ))}
                </div>

                <div className="col-8 p-2">
                    {subcategories.length > 0 && (
                        <div className="row row-cols-2 g-2">
                            {subcategories.map((category) => (
                                <div key={category._id} className="col">
                                    <div
                                        className={`p-2 position-relative rounded ${
                                            selectedCategory?._id === category._id 
                                                ? 'bg-primary bg-opacity-10 border border-primary' 
                                                : 'hover-bg-light'
                                        }`}
                                        onClick={() => handleCategorySelect(category)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <div className="fw-semibold">{category.categoryName}</div>
                                        {selectedCategory?._id === category._id && (
                                            <div className="position-absolute top-0 end-0 text-success">
                                                <Check size={16} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            {selectedCategory && (
                <div className="border-top p-2">
                    <nav aria-label="Category breadcrumb">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item">
                                <span className="d-flex align-items-center">
                                    <Home size={16} className="me-2" />
                                    All Categories
                                </span>
                            </li>
                            <li className="breadcrumb-item">
                                <span className="d-flex align-items-center">
                                    <ChevronRight size={16} className="me-2" />
                                    {selectedCategory.mainCategory}
                                </span>
                            </li>
                            <li className="breadcrumb-item active d-flex align-items-center" aria-current="page">
                                <Check size={16} className="me-2" />
                                {selectedCategory.categoryName}
                            </li>
                        </ol>
                    </nav>
                </div>
            )}
        </div>
    );
};

export default MultiLevelCategoryDropdown;