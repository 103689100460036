import React, { useState, useEffect } from 'react';

const AuthorModal = ({ isOpen, onClose, author = null, mode = 'add', onSuccess }) => {
  const [formData, setFormData] = useState({
    authorName: '',
    authorEmail: '',
    authorImage: '',
    authorStatus: 'pending'
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (mode === 'edit' && author) {
      setFormData({
        authorName: author.authorName || '',
        authorEmail: author.authorEmail || '',
        authorImage: author.authorImage || '',
        authorStatus: author.authorStatus || 'pending'
      });
    } else {
      // Reset form for add mode
      setFormData({
        authorName: '',
        authorEmail: '',
        authorImage: '',
        authorStatus: 'pending'
      });
    }
  }, [mode, author]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let url = 'https://server.enjoywiki.com/flip-server/';
      let method = 'POST';

      if (mode === 'edit' && author?._id) {
        url += `author/${author._id}`;
        method = 'PUT';
      } else {
        url += 'add-author';
      }

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to save author');
      }

      onSuccess();
    } catch (error) {
      console.error('Error saving author:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{mode === 'edit' ? 'Edit Author' : 'Add New Author'}</h5>
          <button type="button" className="btn-close" onClick={onClose}>&times;</button>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="authorName" className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                id="authorName"
                name="authorName"
                value={formData.authorName}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="authorEmail" className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                id="authorEmail"
                name="authorEmail"
                value={formData.authorEmail}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="authorImage" className="form-label">Image URL</label>
              <input
                type="text"
                className="form-control"
                id="authorImage"
                name="authorImage"
                value={formData.authorImage}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="authorStatus" className="form-label">Status</label>
              <select
                className="form-select"
                id="authorStatus"
                name="authorStatus"
                value={formData.authorStatus}
                onChange={handleChange}
              >
                <option value="pending">Pending</option>
                <option value="Approved">Approved</option>
               
              </select>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={onClose}>
                Cancel
              </button>
              <button type="submit" className="btn btn-primary" disabled={loading}>
                {loading ? 'Saving...' : (mode === 'edit' ? 'Update' : 'Add')}
              </button>
            </div>
          </form>
        </div>
      </div>
      <style jsx>{`
        .modal-backdrop {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1050;
        }
        
        .modal-content {
          background-color: white;
          border-radius: 8px;
          width: 100%;
          max-width: 500px;
          position: relative;
        }
        
        .modal-header {
          padding: 1rem;
          border-bottom: 1px solid #dee2e6;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        
        .modal-title {
          margin: 0;
          font-size: 1.25rem;
        }
        
        .btn-close {
          background: none;
          border: none;
          font-size: 1.5rem;
          cursor: pointer;
        }
        
        .modal-body {
          padding: 1rem;
        }
        
        .modal-footer {
          padding: 1rem;
          border-top: 1px solid #dee2e6;.modal-footer {
          padding: 1rem;
          border-top: 1px solid #dee2e6;
          display: flex;
          justify-content: flex-end;
          gap: 0.5rem;
        }

        .form-label {
          font-weight: 500;
          color: #333;
          margin-bottom: 0.5rem;
        }

        .form-control,
        .form-select {
          border: 1px solid #dee2e6;
          border-radius: 4px;
          padding: 0.5rem;
          width: 100%;
          font-size: 1rem;
        }

        .form-control:focus,
        .form-select:focus {
          border-color: #86b7fe;
          outline: 0;
          box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
        }

        .btn {
          padding: 0.5rem 1rem;
          font-size: 1rem;
          border-radius: 4px;
          cursor: pointer;
          transition: all 0.2s ease;
        }

        .btn-primary {
          background-color: #0d6efd;
          border: 1px solid #0d6efd;
          color: white;
        }

        .btn-primary:hover {
          background-color: #0b5ed7;
          border-color: #0a58ca;
        }

        .btn-primary:disabled {
          background-color: #0d6efd;
          border-color: #0d6efd;
          opacity: 0.65;
          cursor: not-allowed;
        }

        .btn-secondary {
          background-color: #6c757d;
          border: 1px solid #6c757d;
          color: white;
        }

        .btn-secondary:hover {
          background-color: #5c636a;
          border-color: #565e64;
        }

        .mb-3 {
          margin-bottom: 1rem;
        }

        @keyframes modalFadeIn {
          from {
            opacity: 0;
            transform: translateY(-20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .modal-content {
          animation: modalFadeIn 0.3s ease-out;
        }
      `}</style>
    </div>
  );
};

export default AuthorModal;