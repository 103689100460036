import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import slugify from 'slugify';
import toast from 'react-hot-toast';
import auth from '../../firebase.init';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const AddBlogPost = () => {
    const [user] = useAuthState(auth);
    const navigate = useNavigate();

    // State management
    const [blogTitle, setBlogTitle] = useState('');
    const [blogTitleError, setBlogTitleError] = useState('');
    const [charCount, setCharCount] = useState(0);
    const [blogSlug, setBlogSlug] = useState('');
    const [blogContent, setBlogContent] = useState('');
    const [featuredImage, setFeaturedImage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [categories, setCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [userName, setUserName] = useState('');
    const [approvedAuthors, setApprovedAuthors] = useState([]);
    const [isAuthorApproved, setIsAuthorApproved] = useState(false);

    // Fetch categories on component mount
    useEffect(() => {
        fetch('https://server.enjoywiki.com/flip-server/blog-categories')
            .then((res) => res.json())
            .then((info) => setCategories(info.reverse()))
            .catch((error) => console.error('Error fetching categories:', error));
    }, []);

    // Quill editor configuration
    const quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
            ['clean'],
        ],
    };

    // Fetch approved authors and verify logged-in user
    useEffect(() => {
        fetch('https://server.enjoywiki.com/flip-server/authors')
            .then((res) => res.json())
            .then((data) => {
                setApprovedAuthors(data);
                const isApproved = data.some(
                    (author) => author.authorEmail === user?.email && author.authorStatus === 'Approved'
                );
                setIsAuthorApproved(isApproved);
            })
            .catch((error) => console.error('Error fetching authors:', error));
    }, [user]);

    // Fetch user data
    useEffect(() => {
        if (user?.email) {
            fetch(`https://server.enjoywiki.com/flip-server/users?userEmail=${user.email}`)
                .then((res) => res.json())
                .then((info) => {
                    const matchedUser = info.find((item) => item.UserEmail === user.email);
                    if (matchedUser) {
                        setUserName(matchedUser.userName);
                    } else {
                        toast.error('User not found!');
                    }
                })
                .catch((error) => {
                    console.error('Error fetching user data:', error);
                    toast.error('Error loading user data');
                });
        }
    }, [user]);

    // Constants for slug configuration
    const MAX_SLUG_LENGTH = 65;  // Optimal for SEO
    const RESTRICTED_CHARS = /[*+~.()'"!:@#$%^&{}[\]<>?\\|]/g;
    const CONSECUTIVE_HYPHENS = /-{2,}/g;
    const TRIM_HYPHENS = /^-+|-+$/g;

    const generateSEOSlug = (value) => {
        if (!value) return '';

        // Step 1: Basic slug generation with improved character handling
        let slug = slugify(value, {
            lower: true,          // Convert to lowercase
            strict: true,         // Strip special characters
            remove: RESTRICTED_CHARS,
            trim: true           // Remove leading/trailing spaces
        });

        // Step 2: Clean up the slug
        slug = slug
            // Replace consecutive hyphens with single hyphen
            .replace(CONSECUTIVE_HYPHENS, '-')
            // Remove leading and trailing hyphens
            .replace(TRIM_HYPHENS, '');

        // Step 3: Truncate to SEO-friendly length while preserving words
        if (slug.length > MAX_SLUG_LENGTH) {
            const truncated = slug.substr(0, MAX_SLUG_LENGTH);
            const lastHyphenPos = truncated.lastIndexOf('-');

            // Cut at the last complete word if possible
            if (lastHyphenPos > 0) {
                slug = truncated.substr(0, lastHyphenPos);
            } else {
                slug = truncated;
            }
        }

        return slug;
    };

    // Handle blog title change and slug generation
    const handleBlogTitleChange = (event) => {
        const value = event.target.value;
        setBlogTitle(value);
        setCharCount(value.length);

        if (value.length > 70) {
            setBlogTitleError('Blog title cannot exceed 70 characters.');
        } else {
            setBlogTitleError('');
        }

        const generatedSlug = generateSEOSlug(value);
        setBlogSlug(generatedSlug);
    };

    // Handle image upload
    const handleImageUpload = async (event, setImage) => {
        const file = event.target.files[0];
        if (!file) return;

        const maxSize = 2 * 1024 * 1024; // 2 MB
        if (file.size > maxSize) {
            toast.error('File size exceeds 2 MB. Please upload a smaller image.');
            return;
        }

        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await fetch('https://server.enjoywiki.com/image-server/upload', {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            if (data.file && data.file.url) {
                setImage(data.file.url);
                toast.success('Image uploaded successfully!');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            toast.error('Failed to upload image.');
        }
    };

    // Handle image removal
    const handleImageRemove = (setImage) => {
        setImage('');
        toast.success('Image removed successfully!');
    };

    // Form submission handler
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (blogTitleError || isSubmitting) {
            return;
        }
        setIsSubmitting(true);
        try {
            const selectedCategorySlug = event.target.category.value;
            const selectedCategory = categories.find((category) => category.slug === selectedCategorySlug);

            const blogData = {
                blogStatus: 'Approved',
                authorEmail: event.target.authorEmail.value,
                blogTitle,
                slug: blogSlug,
                category: selectedCategory?.categoryName,
                categorySlug: selectedCategorySlug,
                blogContent,
                featuredImage,
                authorName: userName,
                createdAt: new Date().toISOString(),
            };

            const response = await fetch('https://server.enjoywiki.com/flip-server/add-blog', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(blogData),
            });
            const result = await response.json();
            if (response.ok) {
                toast.success('Blog post added successfully!');
                // Reset form
                setBlogTitle('');
                setBlogSlug('');
                setBlogContent('');
                setFeaturedImage('');
                event.target.reset();
                navigate('/seller/blog');
            } else {
                throw new Error(result.message || 'Failed to add blog post');
            }
        } catch (error) {
            console.error('Error adding blog post:', error);
            toast.error(error.message || 'Failed to add blog post');
        } finally {
            setIsSubmitting(false);
        }
    };

    const filteredCategories = categories.filter((category) =>
        category.categoryName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (!isAuthorApproved) {
        return (
            <div className="d-flex align-items-center justify-content-center min-vh-100 bg-light">
                <div className="text-center p-5 border rounded shadow bg-white">
                    <h3 className="text-danger mb-4">Access Denied</h3>
                    <p className="text-muted mb-4">You are not authorized to add a blog post.</p>
                    <button
                        className="btn btn-primary px-4 py-2"
                        onClick={() => navigate('/dashboard')}
                    >
                        Go Back to Dashboard
                    </button>
                </div>
            </div>
        );
    }


    return (
        <main className="dashboard-main min-vh-100">
            <div className="d-flex flex-column gap-4 pb-110">
                <Helmet>
                    <title>Add New Blog Post | FlipBundle.com</title>
                    <meta
                        name="description"
                        content="Easily add a new blog post to your FlipBundle website. Upload your featured image, set post details, and start sharing your content with your audience."
                    />
                </Helmet>
                <div>
                    <h3 className="text-24 fw-bold text-dark-300 mb-2">Add Blog Post</h3>
                    <ul className="d-flex align-items-center gap-2">
                        <li className="text-dark-200 fs-6">Dashboard</li>
                        <li>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={5}
                                height={11}
                                viewBox="0 0 5 11"
                                fill="none"
                            >
                                <path
                                    d="M1 10L4 5.5L1 1"
                                    stroke="#5B5B5B"
                                    strokeWidth="1.2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </li>
                        <li className="text-lime-300 fs-6">Add Blog Post</li>
                    </ul>
                </div>
                {/* Content */}
                <div className="row justify-content-center">
                    <div className="col-xl-8">
                        <form onSubmit={handleSubmit} className="comment-form container add-product">
                            <input type="hidden" name="blogStatus" value="Approved" />
                            <input type="hidden" name="authorEmail" value={user?.email} />
                            <div className="columns gap20">
                                {/* Basic Info */}
                                <div className="gig-info-card mb-4">
                                    <div className="gig-info-header">
                                        <h4 className="text-18 fw-semibold text-dark-300">Add Blog Post Info</h4>
                                    </div>
                                    <div className="gig-info-body bg-white">
                                        <div className="row g-4">
                                            {/* Blog Title */}
                                            <div className="col-12">
                                                <div className="form-container">
                                                    <label className="form-label">
                                                        Blog Title (Eg: How to Buy Digital Products Online) <br />
                                                        <div className="mt-2">
                                                            <span className="mt-2">Character count: {charCount}/70</span>
                                                            {blogTitleError && (
                                                                <span className="text-danger ms-2">({blogTitleError})</span>
                                                            )}
                                                        </div>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control shadow-none"
                                                        placeholder="Blog Title"
                                                        value={blogTitle}
                                                        onChange={handleBlogTitleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-container">
                                                    <label className="form-label">Category</label>
                                                    <div className="position-relative">
                                                        <input
                                                            required
                                                            type="text"
                                                            className="form-control shadow-none"
                                                            placeholder="Search categories..."
                                                            value={searchTerm}
                                                            onChange={(e) => {
                                                                setSearchTerm(e.target.value);
                                                                setIsDropdownOpen(true);
                                                            }}
                                                            onClick={() => setIsDropdownOpen(true)}
                                                        />
                                                        {isDropdownOpen && filteredCategories.length > 0 && (
                                                            <div
                                                                className="position-absolute w-100 bg-white border rounded mt-1 shadow-sm"
                                                                style={{ maxHeight: '200px', overflowY: 'auto', zIndex: 1000 }}
                                                            >
                                                                {filteredCategories.map((category) => (
                                                                    <div
                                                                        key={category._id}
                                                                        className="p-2 cursor-pointer hover:bg-gray-100"
                                                                        onClick={() => {
                                                                            setSelectedCategory(category.slug);
                                                                            setSearchTerm(category.categoryName);
                                                                            setIsDropdownOpen(false);
                                                                        }}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        {category.categoryName}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                        <input
                                                            type="hidden"
                                                            name="category"
                                                            value={selectedCategory}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Blog Content */}
                                            <div className="col-12">
                                                <>
                                                    <label className="form-label">Blog Content</label>
                                                    <ReactQuill
                                                        value={blogContent}
                                                        onChange={setBlogContent}
                                                        modules={quillModules}
                                                        className="form-control text-area-sec"
                                                        placeholder="Blog Content"
                                                    />
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Images Section */}
                                <div className="gig-info-card mb-4">
                                    <div className="gig-info-header">
                                        <h4 className="text-18 fw-semibold text-dark-300">Upload Images</h4>
                                    </div>
                                    <div className="gig-info-body bg-white">
                                        {/* Featured Image */}
                                        <div className="mb-4">
                                            <label className="form-label">Featured Image (925x540)</label>
                                            <div className="d-flex align-items-center gap-3">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    onChange={(e) => handleImageUpload(e, setFeaturedImage)}
                                                    accept="image/*"
                                                />
                                                {featuredImage && (
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() => handleImageRemove(setFeaturedImage)}
                                                    >
                                                        Remove
                                                    </button>
                                                )}
                                            </div>
                                            {featuredImage && (
                                                <img
                                                    src={featuredImage}
                                                    alt="Featured"
                                                    className="mt-2"
                                                    style={{ height: '100px' }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* Submit Button */}
                                <div className="text-center">
                                    <button
                                        type="submit"
                                        className="btn btn-primary w-btn-secondary-lg"
                                        disabled={isSubmitting || !!blogTitleError}
                                    >
                                        {isSubmitting ? 'Publishing...' : 'Publish Now'}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={14}
                                            height={10}
                                            viewBox="0 0 14 10"
                                            fill="none"
                                            className="ms-2"
                                        >
                                            <path
                                                d="M9 9L13 5M13 5L9 1M13 5L1 5"
                                                stroke="white"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default AddBlogPost;