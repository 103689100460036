import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Pagination from '../../components/Shared/Pagination';
import PulseLoader from '../../components/Shared/Loading';

const BlogPage = () => {
    const [blogs, setBlogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                setIsLoading(true);
                const response = await fetch(`https://server.enjoywiki.com/flip-server/blogs`);
                const data = await response.json();
                setBlogs(data.reverse());
            } catch (error) {
                console.error('Error fetching blogs:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    const formatDate = (isoString) => {
        return new Date(isoString).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        });
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentBlogs = blogs.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    if (isLoading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <PulseLoader />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Blog - FlipBundle | Tech News, Tutorials & Digital Insights</title>
                <meta name="description" content="Explore FlipBundle's blog for the latest tech news, in-depth tutorials, digital marketing insights, and expert tips. Stay updated with our comprehensive guides and industry analysis." />
                <meta name="keywords" content="FlipBundle blog, tech news, digital marketing, tutorials, technology guides, industry insights" />
                <link rel="canonical" href="https://flipbundle.com/blog" />
                
                {/* Open Graph tags */}
                <meta property="og:title" content="FlipBundle Blog - Tech News & Digital Insights" />
                <meta property="og:description" content="Discover the latest tech news, tutorials, and digital insights on FlipBundle's blog. Expert analysis and comprehensive guides for staying ahead in the digital world." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://flipbundle.com/blog" />
                <meta property="og:site_name" content="FlipBundle" />

                {/* Twitter Card tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="FlipBundle Blog - Tech News & Digital Insights" />
                <meta name="twitter:description" content="Discover the latest tech news, tutorials, and digital insights on FlipBundle's blog." />
            </Helmet>

            <main className="py-110 bg-offWhite">
                <div className="container">
                    {currentBlogs.length > 0 ? (
                        <>
                            <header className="mb-5">
                                <h1 className="visually-hidden">FlipBundle Blog - Latest Articles</h1>
                            </header>
                            <div className="row row-gap-4 row-cols-lg-3 row-cols-md-2 row-cols-sm-1">
                                {currentBlogs.map((blog) => (
                                    <article key={blog._id} itemScope itemType="http://schema.org/BlogPosting">
                                        <div className="blog-card">
                                            <div className="position-relative">
                                                <a href={`/blog/${blog.slug}`} className="d-block">
                                                    <img
                                                        src={blog.featuredImage}
                                                        className="img-fluid blog-thumb w-100"
                                                        alt={`${blog.blogTitle} - FlipBundle`}
                                                        style={{
                                                            height: '250px',
                                                            objectFit: 'cover',
                                                            width: '100%'
                                                        }}
                                                        itemProp="image"
                                                    />
                                                </a>
                                                <time 
                                                    className="blog-date position-absolute"
                                                    itemProp="datePublished"
                                                    dateTime={blog.createdAt}
                                                >
                                                    {formatDate(blog.createdAt)}
                                                </time>
                                            </div>
                                            <div className="blog-meta">
                                                <div className="d-flex justify-content-between mb-3">
                                                    <p className="d-flex align-items-center gap-2 text-dark-200">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={12}
                                                            height={15}
                                                            viewBox="0 0 12 15"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M1.761 14.9996C1.55973 14.9336 1.35152 14.8896 1.16065 14.7978C0.397206 14.4272 -0.02963 13.6273 0.00160193 12.743C0.0397743 11.6936 0.275749 10.7103 0.765049 9.7966C1.42439 8.56373 2.36829 7.65741 3.59327 7.07767C3.67309 7.04098 3.7529 7.00428 3.85007 6.95658C2.68061 5.9512 2.17396 4.67062 2.43422 3.10017C2.58691 2.18285 3.03804 1.42698 3.72514 0.847238C5.24163 -0.42967 7.34458 -0.216852 8.60773 1.1738C9.36424 2.00673 9.70779 3.01211 9.61757 4.16426C9.52734 5.31642 9.01375 6.23374 8.14619 6.94924C8.33359 7.04098 8.50363 7.11436 8.6702 7.20609C10.1485 8.006 11.1618 9.24254 11.6997 10.9011C11.9253 11.5945 12.0328 12.3137 11.9912 13.0476C11.9357 14.0163 11.2243 14.8235 10.3151 14.9703C10.2908 14.974 10.2665 14.9886 10.2387 14.9996C7.41051 14.9996 4.58575 14.9996 1.761 14.9996ZM6.00507 13.8475C7.30293 13.8475 8.60079 13.8401 9.89518 13.8512C10.5684 13.8548 10.9571 13.3338 10.9015 12.7577C10.8807 12.5486 10.8773 12.3394 10.846 12.1303C10.6309 10.6185 9.92294 9.41133 8.72225 8.5784C7.17106 7.50331 5.50883 7.3602 3.84313 8.23349C2.05944 9.16916 1.15718 10.7506 1.09125 12.8568C1.08778 13.0072 1.12595 13.1723 1.18494 13.3044C1.36193 13.6934 1.68466 13.8438 2.08026 13.8438C3.392 13.8475 4.70027 13.8475 6.00507 13.8475ZM5.99119 6.53462C7.38969 6.54195 8.53833 5.33843 8.54527 3.85238C8.55221 2.37733 7.41745 1.16647 6.00507 1.15179C4.62046 1.13344 3.45794 2.35531 3.45099 3.8377C3.44405 5.31275 4.58922 6.52728 5.99119 6.53462Z"
                                                                fill="#22BE0D"
                                                            />
                                                        </svg>
                                                        <span itemProp="author">By {blog.authorName}</span>
                                                    </p>
                                                </div>
                                                <h2 className="blog-title fw-bold mb-3">
                                                    <a href={`/blog/${blog.slug}`} itemProp="headline">
                                                        {blog.blogTitle}
                                                    </a>
                                                </h2>
                                            </div>
                                        </div>
                                    </article>
                                ))}
                            </div>

                            {blogs.length > itemsPerPage && (
                                <nav 
                                    className="pagination-container mt-4 d-flex justify-content-center"
                                    aria-label="Blog pagination"
                                >
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={Math.ceil(blogs.length / itemsPerPage)}
                                        onPageChange={handlePageChange}
                                    />
                                </nav>
                            )}
                        </>
                    ) : (
                        <section className="bg-offWhite">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-12">
                                        <div className="p-lg-5 p-3 rounded-3 not-found-img d-flex flex-column flex-wrap align-items-center">
                                            <img
                                                src="https://workzone.mamunuiux.com/uploads/website-images/not-found-2024-05-17-11-50-01-3653.png"
                                                className="img-fluid"
                                                alt="No blog posts found - FlipBundle"
                                            />
                                        </div>
                                        <div className="text-center">
                                            <h2 className="section-title fw-semibold mb-3 mb-2">
                                                Sorry! No Posts Found
                                            </h2>
                                            <p className="mb-4">
                                                Check back soon for new content on FlipBundle.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </main>
        </>
    );
};

export default BlogPage;