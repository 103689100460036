import React from 'react';

const UserInfoDisplay = ({ email, users }) => {
    const user = users.find(u => u.UserEmail === email);

    return (
        <div className="d-flex align-items-center gap-2">
            <div className="order-img">
                {user?.profileURL && (
                    <img
                        src={user.profileURL}
                        alt={user.profileURL}
                        style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '8px' }}
                    />
                )}
            </div>
            <div className="user-info">
                <p className="mb-0 fw-medium">{user?.userName || 'Unknown User'}</p>
                <p className="mb-0 text-muted small">{email}</p>
            </div>
        </div>
    );
};

export default UserInfoDisplay;