// SupportContext.js
import React, { createContext, useContext, useState } from 'react';

const SupportContext = createContext();

export const SupportProvider = ({ children }) => {
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [tickets, setTickets] = useState([]);

  return (
    <SupportContext.Provider value={{ selectedTicket, setSelectedTicket, tickets, setTickets }}>
      {children}
    </SupportContext.Provider>
  );
};

export const useSupport = () => useContext(SupportContext);