import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import auth from '../../firebase.init';

const AdminUpdateProfile = () => {
    const [user] = useAuthState(auth);
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [countries, setCountries] = useState([]);
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState("Select Country");
    const defaultImageURL = "https://raw.githubusercontent.com/Shah-Limon/em-list/master/images-dash/profile.jpg";
    const [aboutMeText, setAboutMeText] = useState('');
    const [isAboutMeValid, setIsAboutMeValid] = useState(true);
    const MAX_CHARS = 350;

    useEffect(() => {
        if (user?.email) {
            fetch(`https://server.enjoywiki.com/flip-server/users?userEmail=${user.email}`)
                .then((res) => res.json())
                .then((info) => {
                    const matchedUser = info.find(item => item.UserEmail === user.email);
                    if (matchedUser) {
                        setUserData(matchedUser);
                        setSelectedCountry(matchedUser.country || "Select Country");
                        setAboutMeText(matchedUser.aboutMe || '');
                    } else {
                        toast.error("User not found!");
                    }
                })
                .catch(error => {
                    console.error("Error fetching user data:", error);
                    toast.error("Error loading user data");
                });
        }
    }, [user]);

    useEffect(() => {
        fetch("https://raw.githubusercontent.com/Shah-Limon/canva-related-new/main/country.json")
            .then((res) => res.json())
            .then((data) => {
                const sortedCountries = data.sort((a, b) => a.name.localeCompare(b.name));
                setCountries(sortedCountries);
            });
    }, []);

    const handleImageChange = (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) return;

        const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1 MB
        if (selectedFile.size > MAX_FILE_SIZE) {
            toast.error("File size exceeds 1 MB. Please upload a smaller image.");
            return;
        }

        setImageFile(selectedFile);
        const previewURL = URL.createObjectURL(selectedFile);
        setImagePreview(previewURL);
    };
    const handleAboutMeChange = (e) => {
        const text = e.target.value;
        setAboutMeText(text);
        setIsAboutMeValid(text.length <= MAX_CHARS);
    };

    const handleSubmit = async (event, id) => {
        event.preventDefault();
        if (!isAboutMeValid) {
            toast.error("Please reduce the About Me text to 350 characters or less.");
            return;
        }
        const userName = event.target.userName.value;
        const aboutMe = event.target.aboutMe.value;
        const address = event.target.address.value;
        const city = event.target.city.value;
        const country = event.target.country.value;
        const phone = event.target.phone.value;
        let profileURL = defaultImageURL;

        if (imageFile) {
            try {
                const formData = new FormData();
                formData.append("image", imageFile);

                const response = await fetch("https://server.enjoywiki.com/image-server/upload", {
                    method: "POST",
                    body: formData,
                });

                const data = await response.json();
                if (data.file && data.file.url) {
                    profileURL = data.file.url;
                    toast.success("Image uploaded successfully!");
                }
            } catch (error) {
                console.error("Error uploading image:", error);
                toast.error("Failed to upload image.");
                return;
            }
        } else if (event.target.profileURL.value) {
            profileURL = event.target.profileURL.value;
        }

        const edit = {
            userName,
            profileURL,
            aboutMe,
            address,
            city,
            country,
            phone
        };

        const url = `https://server.enjoywiki.com/flip-server/update-user-profile/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(edit),
        })
            .then((res) => res.json())
            .then((result) => {
                toast.success("Profile Updated Successfully!");
                navigate('/admin/update-profile', { replace: true });
            });
    };

    if (!userData) {
        return <div>Loading...</div>;
    }

    return (
        <main className="dashboard-main min-vh-100">
            <div className="d-flex flex-column gap-4">
                <Helmet>
                    <title>Update Profile | FlipBundle - Manage Your Admin Information</title>
                    <meta name="description" content="Update your profile on FlipBundle. Edit your personal details, contact information, and Admin bio to enhance your store presence." />
                </Helmet>

                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <h3 className="text-24 fw-bold text-dark-300 mb-2">Edit Profile</h3>
                        <ul className="d-flex align-items-center gap-2">
                            <li className="text-dark-200 fs-6">Dashboard</li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width={5} height={11} viewBox="0 0 5 11" fill="none">
                                    <path d="M1 10L4 5.5L1 1" stroke="#5B5B5B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </li>
                            <li className="text-lime-300 fs-6">Edit Profile</li>
                        </ul>
                    </div>
                </div>

                {/* Content */}
                <div>
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <form key={userData._id} onSubmit={(e) => handleSubmit(e, userData._id)}>
                                <div className="d-flex flex-column gap-4">
                                    {/* Basic Information */}
                                    <div className="profile-info-card">
                                        <div className="profile-info-header">
                                            <h4 className="text-18 fw-semibold text-dark-300">Basic Information</h4>
                                        </div>
                                        <div className="profile-info-body bg-white">
                                            <div className="row g-4">
                                                <div className="col-md-6">
                                                    <div className="form-container">
                                                        <label className="form-label">
                                                            Name<span className="text-lime-300">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control shadow-none"
                                                            name="userName"
                                                            defaultValue={userData.userName}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-container">
                                                        <label className="form-label">Phone</label>
                                                        <input
                                                            type="text"
                                                            className="form-control shadow-none"
                                                            name="phone"
                                                            defaultValue={userData.phone}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-container">
                                                        <label className="form-label">Email</label>
                                                        <input
                                                            disabled
                                                            type="text"
                                                            className="form-control shadow-none"

                                                            defaultValue={userData.UserEmail}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-container">
                                                        <label className="form-label">Address</label>
                                                        <input
                                                            type="text"
                                                            className="form-control shadow-none"
                                                            name="address"
                                                            defaultValue={userData.address}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-container">
                                                        <label className="form-label">City</label>
                                                        <input
                                                            type="text"
                                                            className="form-control shadow-none"
                                                            name="city"
                                                            defaultValue={userData.city}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-container">
                                                        <label className="form-label">Country</label>
                                                        <select
                                                            className="form-control shadow-none"
                                                            name="country"
                                                            value={selectedCountry}
                                                            onChange={(e) => setSelectedCountry(e.target.value)}
                                                        >
                                                            <option disabled>Select Country</option>
                                                            {countries.map((country) => (
                                                                <option key={country.code} value={country.name}>
                                                                    {country.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div>
                                                        <label className="form-label">About Me</label>
                                                        <div className="position-relative">
                                                            <textarea
                                                                rows={5}
                                                                className={`form-control shadow-none ${!isAboutMeValid ? 'border-red-500' : ''}`}
                                                                name="aboutMe"
                                                                value={aboutMeText}
                                                                onChange={handleAboutMeChange}
                                                            />
                                                            <div className="d-flex justify-content-between align-items-center mt-2">
                                                                <span className={`small ${!isAboutMeValid ? 'text-danger' : 'text-muted'}`}>
                                                                    {aboutMeText.length}/{MAX_CHARS} characters
                                                                </span>
                                                            </div>
                                                            {!isAboutMeValid && (
                                                                <div className="alert alert-danger mt-2" role="alert">
                                                                    Please limit your text to {MAX_CHARS} characters. You are currently {aboutMeText.length - MAX_CHARS} characters over the limit.
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Upload Profile Image */}
                                    <div className="profile-info-card">
                                        <div className="gig-info-header">
                                            <h4 className="text-18 fw-semibold text-dark-300">Upload Profile Image</h4>
                                        </div>
                                        <div className="gig-info-body bg-white">
                                            <p className="text-dark-200 mb-2">Profile Image</p>
                                            <div className="d-flex flex-wrap gap-3">
                                                <div>
                                                    <label htmlFor="profile-img" className="border text-center gig-file-upload">
                                                        <img
                                                            id="view_img"
                                                            className="gig-img-icon"
                                                            width={80}
                                                            height={80}
                                                            src={imagePreview || userData.profileURL || defaultImageURL}
                                                            alt="Profile"
                                                        />
                                                        <p className="text-dark-200">Choose File</p>
                                                        <input
                                                            className="d-none"
                                                            type="file"
                                                            name="image"
                                                            id="profile-img"
                                                            onChange={handleImageChange}
                                                            accept="image/*"
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name="profileURL"
                                                            defaultValue={userData.profileURL || defaultImageURL}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Submit Buttons */}
                                    <div className="d-flex align-items-center gap-3">
                                        <button type="submit" className="w-btn-secondary-lg">
                                            Save Now
                                            <svg xmlns="http://www.w3.org/2000/svg" width={14} height={10} viewBox="0 0 14 10" fill="none">
                                                <path d="M9 9L13 5M13 5L9 1M13 5L1 5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </button>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default AdminUpdateProfile;