import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
    return (
        <>
            <Helmet>
                <title>Oops! Something Went Wrong | FlipBundle</title>
                <meta name="description" content="An error occurred while processing your request on FlipBundle. Please try again later or contact support if the issue persists." />
            </Helmet>

            <section className="bg-offWhite py-80">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7 col-12">
                            <div className="p-5 rounded-3 not-found-img d-flex flex-column flex-wrap align-items-center">
                                <img
                                    src="https://server.enjoywiki.com/image-server/uploads/not_found_2024_05_17_11_50_01_3653-1736922615653-91603001.webp"
                                    alt="Oops! Page Not Found."
                                />
                            </div>
                            <div className="pt-5 text-center">
                                <h2 className="section-title mb-4">Oops! Page Not Found.</h2>
                                <div>
                                    <Link
                                        to="/"
                                        className="w-btn-secondary-lg d-inline-flex"
                                    >
                                        Back to Home Page
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
};

export default ErrorPage;