// import React from "react";

// const Pagination = ({ currentPage, totalPages, onPageChange }) => {
//     const handlePageChange = (page) => {
//         if (page >= 1 && page <= totalPages) {
//             onPageChange(page);
//         }
//     };

//     return (
//         <div className="row justify-content-end mt-4">
//             <div className="col-auto">
//                 <nav>
//                     <ul className="custom-pagination pagination">
//                         <li className={`custom-page-item page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                             <button
//                                 className="custom-page-link page-link"
//                                 onClick={() => handlePageChange(currentPage - 1)}
//                                 disabled={currentPage === 1}
//                             >
//                                 Previous
//                             </button>
//                         </li>
//                         {[...Array(totalPages)].map((_, i) => (
//                             <li
//                                 key={i + 1}
//                                 className={`custom-page-item page-item ${currentPage === i + 1 ? 'active' : ''}`}
//                             >
//                                 <button
//                                     className="custom-page-link page-link"
//                                     onClick={() => handlePageChange(i + 1)}
//                                 >
//                                     {i + 1}
//                                 </button>
//                             </li>
//                         ))}
//                         <li className={`custom-page-item page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
//                             <button
//                                 className="custom-page-link page-link"
//                                 onClick={() => handlePageChange(currentPage + 1)}
//                                 disabled={currentPage === totalPages}
//                             >
//                                 Next
//                             </button>
//                         </li>
//                     </ul>
//                 </nav>
//             </div>
//         </div>
//     );
// };

// export default Pagination;

import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    const getPageNumbers = () => {
        if (totalPages <= 3) {
            return Array.from({ length: totalPages }, (_, i) => i + 1);
        }

        if (currentPage <= 2) {
            return [1, 2, 3];
        }

        if (currentPage >= totalPages - 1) {
            return [totalPages - 2, totalPages - 1, totalPages];
        }

        return [currentPage - 1, currentPage, currentPage + 1];
    };

    return (
        <div className="row justify-content-end mt-4">
            <div className="col-auto">
                <nav>
                    <ul className="custom-pagination pagination">
                        <li className={`custom-page-item page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button
                                className="custom-page-link page-link"
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                        </li>
                        {getPageNumbers().map((pageNum, index) => (
                            <li
                                key={pageNum}
                                className={`custom-page-item page-item ${currentPage === pageNum ? 'active' : ''}`}
                            >
                                <button
                                    className="custom-page-link page-link"
                                    onClick={() => handlePageChange(pageNum)}
                                >
                                    {pageNum}
                                </button>
                                
                            </li>
                        ))}
                        <li className={`custom-page-item page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button
                                className="custom-page-link page-link"
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default Pagination;