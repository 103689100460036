import React from 'react';
import { Outlet } from 'react-router-dom';
import SidebarMenu from './SidebarMenu';
import SellerMobileMenu from './SellerMobileMenu';
import SellerDashboardHeader from './SellerDashboardHeader';
import { SupportProvider } from '../SupportMessage/SupportContext';


const MainDashBoardLayout = () => {
  return (
    <SupportProvider>
      <div className="d-xl-flex">
        <SidebarMenu />
        <SellerMobileMenu />
        <div className="flex-grow-1 align-items-center position-relative">
          <SellerDashboardHeader />
          <div className="main-content">
            <Outlet />
          </div>
        </div>
      </div>
    </SupportProvider>
  );
};
export default MainDashBoardLayout;