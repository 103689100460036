import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import auth from "../firebase.init";
import { Helmet } from "react-helmet";

const ResetPassword = () => {
    const [email, setEmail] = useState("");
    const [resetSent, setResetSent] = useState(false);
    const [error, setError] = useState("");

    const handleResetPassword = async () => {
        try {
            await sendPasswordResetEmail(auth, email);
            setResetSent(true);
            setError("");
        } catch (error) {
            setError(getErrorMessage(error));
        }
    };

    // Function to map Firebase error codes to custom messages
    const getErrorMessage = (error) => {
        if (!error) return "";
        switch (error.code) {
            case "auth/user-not-found":
                return "No user found with this email address.";
            case "auth/invalid-email":
                return "Please enter a valid email address.";
            default:
                return "An unexpected error occurred. Please try again later.";
        }
    };

    return (
        <>
            <Helmet>
                <title>Reset Password | FlipBundle - Recover Your Account</title>
                <meta name="description" content="Forgot your password? Reset it here and regain access to your FlipBundle account. Follow the instructions to securely reset your password." />
            </Helmet>

            <motion.section
                className="w-breadcrumb-area"
                style={{
                    backgroundImage:
                        "url(assets/img/breadcrumb-bg.svg)"
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-auto">
                            <motion.div
                                className="position-relative z-2"
                                initial={{ y: -20 }}
                                animate={{ y: 0 }}
                                transition={{ duration: 0.5, delay: 0.2 }}
                            >
                                <h2 className="section-title-light mb-2">Reset Password</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb w-breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Reset Password
                                        </li>
                                    </ol>
                                </nav>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </motion.section>

            <motion.section
                className="py-110 bg-offWhite"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
            >
                <div className="container">
                    <div className="bg-white rounded-3 p-3">
                        <div className="row g-4">
                            <div className="col-lg-6 p-3 p-lg-5">
                                <motion.div
                                    className="mb-40"
                                    initial={{ x: -20 }}
                                    animate={{ x: 0 }}
                                    transition={{ duration: 0.5, delay: 0.4 }}
                                >
                                    <h2 className="section-title mb-2">Reset Your Password</h2>
                                    <p className="section-desc">Enter your email to receive reset instructions</p>
                                </motion.div>

                                {resetSent ? (
                                    <motion.div
                                        className="alert alert-success"
                                        initial={{ scale: 0.8, opacity: 0 }}
                                        animate={{ scale: 1, opacity: 1 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        Password reset instructions have been sent to your email address.
                                        Please check your inbox and follow the instructions.
                                    </motion.div>
                                ) : (
                                    <div className="form-container">
                                        <div className="row gy-3">
                                            <div className="form-input col-lg-12">
                                                <label htmlFor="email" className="form-label">
                                                    Email <span className="text-lime-300">*</span>
                                                </label>
                                                <motion.input
                                                    whileFocus={{ scale: 1.01 }}
                                                    type="email"
                                                    id="email"
                                                    placeholder="Enter your email address"
                                                    className="form-control shadow-none"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        {error && (
                                            <motion.div
                                                className="text-danger mt-2"
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                transition={{ duration: 0.3 }}
                                            >
                                                {error}
                                            </motion.div>
                                        )}

                                        <motion.div
                                            className="d-grid mt-4"
                                            whileHover={{ scale: 1.02 }}
                                            whileTap={{ scale: 0.98 }}
                                        >
                                            <button
                                                className="w-btn-secondary-lg"
                                                onClick={handleResetPassword}
                                            >
                                                Send Reset Instructions
                                            </button>
                                        </motion.div>
                                    </div>
                                )}

                                <motion.div
                                    className="mt-3"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: 0.5 }}
                                >
                                    <p className="text-center form-text">
                                        Remember your password? <Link to="/login">Login</Link>
                                    </p>
                                </motion.div>
                            </div>
                            <div className="col-lg-6">
                                <motion.div
                                    className="login-img"
                                    initial={{ opacity: 0, x: 20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 0.5, delay: 0.6 }}
                                >
                                    <img
                                        src="https://img.freepik.com/free-vector/forgot-password-concept-illustration_114360-1123.jpg"
                                        className="img-fluid w-100"
                                        alt="Reset Password Illustration"
                                    />
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.section>
        </>
    );
};

export default ResetPassword;