import React from 'react';

const DraftProductOverlay = () => {
    const styles = {
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 10,
            background: 'linear-gradient(to bottom, rgba(17, 24, 39, 0.4), rgba(17, 24, 39, 0.6))',
            backdropFilter: 'blur(5px)',
            WebkitBackdropFilter: 'blur(8px)', // For Safari support
            borderRadius: '12px',
        },
        gridPattern: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 0H0V1.5M28.5 0H30V1.5M1.5 30H0V28.5M28.5 30H30V28.5' stroke='rgba(255,255,255,0.05)'/%3E%3C/svg%3E")`,
            backgroundSize: '30px 30px',
        },
        container: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
        },
        card: {
            position: 'relative',
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            padding: '32px',
            borderRadius: '12px',
            boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
            maxWidth: '400px',
            margin: '0 16px',
            border: '1px solid rgba(229, 231, 235, 1)',
        },
        badge: {
            position: 'absolute',
            top: '-16px',
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '8px 16px',
            backgroundColor: '#f59e0b',
            color: 'white',
            fontSize: '14px',
            fontWeight: 600,
            borderRadius: '9999px',
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
        },
        iconContainer: {
            width: '64px',
            height: '64px',
            backgroundColor: 'rgba(251, 191, 36, 0.1)',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '16px auto',
        },
        icon: {
            width: '32px',
            height: '32px',
            color: '#d97706',
        },
        title: {
            fontSize: '24px',
            fontWeight: 'bold',
            color: '#1f2937',
            textAlign: 'center',
            marginBottom: '12px',
        },
        description: {
            color: '#4b5563',
            textAlign: 'center',
            marginBottom: '24px',
            lineHeight: '1.5',
        },
        divider: {
            height: '1px',
            backgroundColor: '#e5e7eb',
            margin: '24px 0',
        },
        infoContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
        },
        infoItem: {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '14px',
            color: '#6b7280',
        },
        smallIcon: {
            width: '16px',
            height: '16px',
        },
    };

    return (
        <div style={styles.overlay}>
            <div style={styles.gridPattern} />
            <div style={styles.container}>
                <div style={styles.card}>
                    <div style={styles.badge}>Draft Mode</div>

                    <div style={styles.iconContainer}>
                        <svg
                            style={styles.icon}
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                            />
                        </svg>
                    </div>

                    <h2 style={styles.title}>Product Not Available</h2>

                    <p style={styles.description}>
                        This product is currently in draft status and is undergoing review.
                        Check back later for the published version.
                    </p>

                    <div style={styles.divider} />

                    <div style={styles.infoContainer}>
                        <div style={styles.infoItem}>
                            <svg style={styles.smallIcon} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <span>Product details will be visible once published</span>
                        </div>
                        <div style={styles.infoItem}>
                            <svg style={styles.smallIcon} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <span>Check back soon for updates</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DraftProductOverlay;