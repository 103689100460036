import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import "react-quill/dist/quill.snow.css";
import auth from "../../firebase.init";
import Pagination from "../../components/Shared/Pagination";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoadingAnimations from "../../components/Shared/LoadingAnimations";
import { Search, Calendar, SquarePen, Trash2, Mail } from "lucide-react";
import AdminProductStatusModal from "./AdminProductStatusModal";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import toast from "react-hot-toast";
import AdminEmailModal from "./AdminEmailModal";

const AdminAllProducts = () => {
    const [products, setProducts] = useState([]);
    const [user] = useAuthState(auth);
    const [categories, setCategories] = useState([]);
    const [orders, setOrders] = useState([]);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [productToDelete, setProductToDelete] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [selectedSeller, setSelectedSeller] = useState(null);

    // Function to open delete modal
    const handleDeleteClick = (product) => {
        setProductToDelete(product);
        setShowDeleteModal(true);
    };

    const handleEmailClick = (product, seller) => {
        setSelectedSeller(seller);
        setSelectedProduct(product);
        setShowEmailModal(true);
    };


    // Filter states
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [sellerSearchTerm, setSellerSearchTerm] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("");

    // Fetch products
    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/products`)
            .then((res) => res.json())
            .then((info) => setProducts(info.reverse()));
    }, [user?.email]);

    // Fetch categories
    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/categories`)
            .then((res) => res.json())
            .then((info) => setCategories(info.reverse()));
    }, [user]);

    // Fetch orders
    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/orders`)
            .then((res) => res.json())
            .then((info) => setOrders(info));
    }, []);

    // Fetch users
    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/users`)
            .then((res) => res.json())
            .then((info) => {
                setUsers(info);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching users:', error);
                setIsLoading(false);
            });
    }, []);

    // Filter function
    const filteredProducts = products.filter(product => {
        const matchesSearch = product.productName.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesCategory = !selectedCategory || product.category === selectedCategory;
        const matchesStatus = !selectedStatus || product.productStatus === selectedStatus;

        const seller = users.find(user =>
            user.UserEmail === (product.UserEmail || product.sellerEmail)
        );
        const matchesSeller = !sellerSearchTerm ||
            (seller && (
                seller.userName?.toLowerCase().includes(sellerSearchTerm.toLowerCase()) ||
                seller.UserEmail?.toLowerCase().includes(sellerSearchTerm.toLowerCase())
            ));

        const productDate = new Date(product.createdAt);
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;
        const matchesDateRange = (!start || productDate >= start) &&
            (!end || productDate <= end);

        return matchesSearch && matchesCategory && matchesSeller && matchesDateRange && matchesStatus;
    });

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentService = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    // Status change handlers
    const handleStatusChangeModal = (product) => {
        setSelectedProduct(product);
        setShowStatusModal(true);
    };

    const handleProductStatusUpdate = (newStatus) => {
        const updatedProducts = products.map(product =>
            product._id === selectedProduct._id
                ? { ...product, productStatus: newStatus }
                : product
        );
        setProducts(updatedProducts);
    };

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        return date.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        });
    };

    const handleDeleteProduct = async () => {
        if (!productToDelete) return;

        setIsDeleting(true);
        try {
            const response = await fetch(`https://server.enjoywiki.com/flip-server/delete-product/${productToDelete._id}`, {
                method: 'DELETE',
            });

            const data = await response.json();

            if (response.ok) {
                // Remove the deleted product from the state
                setProducts(products.filter(product => product._id !== productToDelete._id));
                setShowDeleteModal(false);
                toast("Product deleted successfully");
            } else {
                alert(data.message || "Failed to delete product");
            }
        } catch (error) {
            console.error("Error deleting product:", error);
            alert("An error occurred while deleting the product");
        } finally {
            setIsDeleting(false);
            setProductToDelete(null);
        }
    };

    return (
        <main className="dashboard-main min-vh-100">
            <div className="d-flex flex-column gap-4 pb-110 p-4">
                <Helmet>
                    <title>All Products Listings | FlipBundle - Manage Your Digital Product Listings</title>
                    <meta name="description" content="View and manage your digital product listings on FlipBundle. Easily update, promote, and track your products for increased sales." />
                </Helmet>

                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <h3 className="text-24 fw-bold text-dark-300 mb-2">Products List</h3>
                        <ul className="d-flex align-items-center gap-2">
                            <li className="text-dark-200 fs-6">Admin Dashboard</li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width={5} height={11} viewBox="0 0 5 11" fill="none">
                                    <path d="M1 10L4 5.5L1 1" stroke="#5B5B5B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </li>
                            <li className="text-lime-300 fs-6">Products</li>
                        </ul>
                    </div>
                </div>

                {/* Filter Section */}
                <div className="card p-4 mb-4">
                    <div className="row g-3">
                        <div className="col-md-2">
                            <select
                                className="form-select"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="">All Status</option>
                                <option value="Pending">Pending</option>
                                <option value="Draft">Draft</option>
                                <option value="Published">Published</option>
                                <option value="Rejected">Rejected</option>
                            </select>
                        </div>
                        <div className="col-md-3">
                            <div className="input-group">
                                <span className="input-group-text">
                                    <Search size={18} />
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search products name..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="col-md-2">
                            <select
                                className="form-select"
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                            >
                                <option value="">All Categories</option>
                                {categories.map((category) => (
                                    <option key={category._id} value={category.categoryName}>
                                        {category.categoryName}
                                    </option>
                                ))}
                            </select>
                        </div>



                        <div className="col-md-2">
                            <div className="input-group">
                                <span className="input-group-text">
                                    <Search size={18} />
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search seller..."
                                    value={sellerSearchTerm}
                                    onChange={(e) => setSellerSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="input-group">
                                <span className="input-group-text">
                                    <Calendar size={18} />
                                </span>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                                <input
                                    type="date"
                                    className="form-control"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Content */}
                <div className="gig-info-card">
                    <div className="gig-info-body">
                        <div className="overflow-x-auto">
                            <div className="w-100">
                                <table className="w-100 dashboard-table">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="ps-4">Product Name</th>
                                            <th scope="col" className="ps-4">Seller Info</th>
                                            <th scope="col">Sales</th>
                                            <th scope="col">View</th>
                                            <th scope="col">Price</th>
                                            <th scope="col" className="text-center">Actions</th>
                                            <th scope="col" className="text-center">Edit</th>
                                            <th scope="col" className="text-center">Delete</th>
                                            <th scope="col" className="text-center">Email</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products === null ? (
                                            <tr>
                                                <td colSpan="7" className="text-center p-4">
                                                    <LoadingAnimations
                                                        type="pulse"
                                                        color="#2563eb"
                                                        size="md"
                                                    />
                                                </td>
                                            </tr>
                                        ) : currentService.length === 0 ? (
                                            <tr>
                                                <td colSpan="9" className="text-center p-4">
                                                    No Products Found!
                                                </td>
                                            </tr>
                                        ) : (
                                            currentService.map((product) => (
                                                <tr key={product._id}>
                                                    <td>
                                                        <div className="d-flex gap-3 align-items-center project-name">
                                                            <div className="order-img">
                                                                {product.featuredImage && (
                                                                    <img
                                                                        src={product.featuredImage}
                                                                        alt={product.productName}
                                                                        style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '8px' }}
                                                                    />
                                                                )}
                                                            </div>
                                                            <div>
                                                                <a
                                                                    href={`/${product.slug}/${product.productId}`}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    className="project-link"
                                                                >
                                                                    {product.productName}
                                                                </a>
                                                                <ul className="d-flex gap-1 order-category mt-1">
                                                                    {product.createdAt && (
                                                                        <li className="text-dark-200">
                                                                            Added On - {formatDate(product.createdAt)}
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                                <ul className="d-flex gap-1 order-category mt-1">
                                                                    <li className="text-dark-200">
                                                                        Category: {product.category}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {isLoading ? (
                                                            <LoadingAnimations type="pulse" color="#2563eb" size="sm" />
                                                        ) : (
                                                            <div className="d-flex gap-3 align-items-center">
                                                                {(() => {
                                                                    const seller = users.find(user =>
                                                                        user.UserEmail === (product.UserEmail || product.sellerEmail)
                                                                    );
                                                                    return (
                                                                        <>
                                                                            <div className="order-img">
                                                                                {seller?.profileURL && (
                                                                                    <img
                                                                                        src={seller.profileURL}
                                                                                        alt={seller.userName}
                                                                                        style={{
                                                                                            width: '50px',
                                                                                            height: '50px',
                                                                                            objectFit: 'cover',
                                                                                            borderRadius: '8px'
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                            <div>
                                                                                <Link to={`/profile-seller/${seller?._id}`}>
                                                                                    <h6 className="mb-1">{seller?.userName || 'N/A'}</h6>
                                                                                </Link>
                                                                                <p className="text-dark-200 mb-0">{seller?.UserEmail || 'N/A'}</p>
                                                                            </div>
                                                                        </>
                                                                    );
                                                                })()}
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td className="text-dark-200">
                                                        {orders.filter(order =>
                                                            order.packageId === product._id &&
                                                            order.paymentStatus === 'Paid'
                                                        ).length}
                                                    </td>
                                                    <td className="text-dark-200">
                                                        <p>{product.viewCount > 0 ? `${product.viewCount} views` : "No view"}</p>
                                                    </td>
                                                    <td className="text-dark-200">
                                                        ${product.price} USD
                                                    </td>

                                                    <td className="text-center">
                                                        <button
                                                            className={`btn btn-sm ${product.productStatus === 'Draft' ? 'btn-warning' :
                                                                product.productStatus === 'Published' ? 'btn-info' :
                                                                    product.productStatus === 'Approved' ? 'btn-success' :
                                                                        'btn-secondary'
                                                                }`}
                                                            onClick={() => handleStatusChangeModal(product)}
                                                        >
                                                            {product.productStatus}
                                                        </button>
                                                    </td>
                                                    <td className="text-center">
                                                        <Link
                                                            to={`/admin/edit-product/${product._id}`}
                                                            className="btn btn-primary btn-sm"
                                                        >
                                                            <SquarePen size={15} /> Edit
                                                        </Link>
                                                    </td>
                                                    <td className="text-center">
                                                        <button
                                                            className="btn btn-danger btn-sm"
                                                            onClick={() => handleDeleteClick(product)}
                                                        >
                                                            <Trash2 size={15} /> Delete
                                                        </button>
                                                    </td>
                                                    <DeleteConfirmationModal
                                                        show={showDeleteModal}
                                                        handleClose={() => {
                                                            setShowDeleteModal(false);
                                                            setProductToDelete(null);
                                                        }}
                                                        handleConfirm={handleDeleteProduct}
                                                        isDeleting={isDeleting}
                                                        productName={productToDelete?.productName}
                                                    />
                                                    {/*Open modal and email sent option */}
                                                    <td className="text-center">
                                                        <button
                                                            className="btn btn-primary btn-sm"
                                                            onClick={() => {
                                                                const seller = users.find(user =>
                                                                    user.UserEmail === (product.UserEmail || product.sellerEmail)
                                                                );
                                                                handleEmailClick(product, seller);  // Make sure to pass both product and seller
                                                            }}
                                                        >
                                                            <Mail size={15} /> Message
                                                        </button>
                                                    </td>
                                                </tr>

                                            ))
                                        )}
                                    </tbody>
                                    <AdminEmailModal
                                        show={showEmailModal}
                                        handleClose={() => {
                                            setShowEmailModal(false);
                                            setSelectedSeller(null);
                                        }}
                                        recipientEmail={selectedSeller?.UserEmail}
                                        recipientName={selectedSeller?.userName}
                                        productName={selectedProduct?.productName}
                                    />
                                </table>
                            </div>
                        </div>

                        <AdminProductStatusModal
                            show={showStatusModal}
                            handleClose={() => setShowStatusModal(false)}
                            productId={selectedProduct?._id}
                            productName={selectedProduct?.productName}
                            currentStatus={selectedProduct?.productStatus}
                            rejection={selectedProduct?.rejectionReason}
                            onStatusUpdate={handleProductStatusUpdate}
                            sellerEmail={selectedProduct?.sellerEmail}
                        />
                        {/* Results Summary */}
                        <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
                            <div className="text-dark-200">
                                Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filteredProducts.length)} of {filteredProducts.length} products
                            </div>
                        </div>
                        {/* Pagination */}
                        {filteredProducts.length > itemsPerPage && (
                            <div className="pagination-container mt-4 d-flex justify-content-center">
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={Math.ceil(filteredProducts.length / itemsPerPage)}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default AdminAllProducts;