import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../../firebase.init';
import EmptyMessage from './EmptyMessage';
import { Link } from 'react-router-dom';
import { Calendar, Filter } from 'lucide-react';
import SellerSupportStyle from './SellerSupportStyle';

const SellerSupport = () => {
    const [user] = useAuthState(auth);
    const [tickets, setTickets] = useState([]);
    const [filteredTickets, setFilteredTickets] = useState([]);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const messagesEndRef = useRef(null);

    // Filter states
    const [statusFilter, setStatusFilter] = useState('open');
    const [priorityFilter, setPriorityFilter] = useState('all');
    const [dateRange, setDateRange] = useState({
        startDate: '',
        endDate: ''
    });

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        if (user?.email) {
            fetchTickets();
        }
    }, [user]);

    useEffect(() => {
        applyFilters();
    }, [tickets, statusFilter, priorityFilter, dateRange]);

    useEffect(() => {
        scrollToBottom();
    }, [selectedTicket]);

    const fetchTickets = async () => {
        try {
            const response = await axios.get('https://server.enjoywiki.com/flip-server/support-messages', {
                params: { userEmail: user.email }
            });
            setTickets(response.data);
            setFilteredTickets(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching tickets:', error);
            setLoading(false);
        }
    };

    const applyFilters = () => {
        let filtered = [...tickets];

        // Status filter
        if (statusFilter !== 'all') {
            filtered = filtered.filter(ticket =>
                ticket.ticketStatus.toLowerCase() === statusFilter.toLowerCase()
            );
        }

        // Priority filter
        if (priorityFilter !== 'all') {
            filtered = filtered.filter(ticket =>
                ticket.priority.toLowerCase() === priorityFilter.toLowerCase()
            );
        }

        // Date range filter
        if (dateRange.startDate && dateRange.endDate) {
            const start = new Date(dateRange.startDate);
            const end = new Date(dateRange.endDate);
            filtered = filtered.filter(ticket => {
                const ticketDate = new Date(ticket.createdAt);
                return ticketDate >= start && ticketDate <= end;
            });
        }

        setFilteredTickets(filtered);
    };

    const handleTicketClick = async (ticketId) => {
        try {
            const response = await axios.get(`https://server.enjoywiki.com/flip-server/support-message/${ticketId}`);
            setSelectedTicket(response.data);
        } catch (error) {
            console.error('Error fetching ticket details:', error);
        }
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim()) return;

        try {
            await axios.put(`https://server.enjoywiki.com/flip-server/support-message-status/${selectedTicket._id}`, {
                ticketStatus: 'in-progress',
                adminMessage: [...(selectedTicket.adminMessage || []), {
                    message: newMessage,
                    timestamp: new Date(),
                    sender: 'user'
                }]
            });

            handleTicketClick(selectedTicket._id);
            setNewMessage('');
            setTimeout(scrollToBottom, 100);
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const getStatusBadgeClass = (status) => {
        switch (status?.toLowerCase()) {
            case 'in-progress':
                return 'bg-warning';
            case 'resolved':
                return 'bg-success';
            case 'closed':
                return 'bg-secondary';
            default:
                return 'bg-info';
        }
    };



    const getAllMessages = (ticket) => {
        if (!ticket) return [];

        const initialMessage = {
            message: ticket.message,
            timestamp: ticket.createdAt,
            sender: 'user'
        };

        return [initialMessage, ...(ticket.adminMessage || [])];
    };

    if (loading) {
        return <div className="loading-spinner">Loading...</div>;
    }

    return (
        <main className="dashboard-main min-vh-100" style={{ background: '#f0f2f5' }}>
            <div className="d-flex flex-column gap-4 p-4">
                {/* Header */}
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <h3 className="h4 mb-2 fw-bold" style={{ color: '#1a1a1a' }}>Support Messages</h3>
                        <div className="d-flex align-items-center gap-2">
                            <span style={{ color: '#666' }}>Seller</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                            <span style={{ color: '#2563eb' }}>Messages</span>
                        </div>
                    </div>
                    <Link
                        to='/seller/create-support'
                        className="btn-create-ticket"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                            <line x1={12} y1={5} x2={12} y2={19} />
                            <line x1={5} y1={12} x2={19} y2={12} />
                        </svg>
                        Create Ticket
                    </Link>
                </div>

                {/* Filter Section */}
                <div className="filter-container bg-white rounded-4 shadow-sm p-3">
                    <div className="d-flex align-items-center gap-4">
                        <div className="filter-group">
                            <label className="filter-label">Status</label>
                            <select
                                className="filter-select"
                                value={statusFilter}
                                onChange={(e) => setStatusFilter(e.target.value)}
                            >
                                <option value="all">All Status</option>
                                <option value="open">Open</option>
                                <option value="in-progress">In Progress</option>
                                <option value="resolved">Resolved</option>
                                <option value="closed">Closed</option>
                            </select>
                        </div>

                        <div className="filter-group">
                            <label className="filter-label">Date Range</label>
                            <div className="d-flex gap-2">
                                <input
                                    type="date"
                                    className="filter-date"
                                    value={dateRange.startDate}
                                    onChange={(e) => setDateRange(prev => ({
                                        ...prev,
                                        startDate: e.target.value
                                    }))}
                                />
                                <input
                                    type="date"
                                    className="filter-date"
                                    value={dateRange.endDate}
                                    onChange={(e) => setDateRange(prev => ({
                                        ...prev,
                                        endDate: e.target.value
                                    }))}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Main Chat Container */}
                <div className="chat-container bg-white rounded-4 shadow-sm">
                    <div className="row g-0">
                        {/* Tickets Sidebar */}
                        <div className="col-lg-4 border-end">
                            <div className="tickets-header">
                                <h4 className="fw-semibold fs-6 mb-0">Support Tickets ({filteredTickets.length})</h4>
                            </div>
                            <div className="tickets-list">
                                {filteredTickets.map((ticket) => (
                                    <div
                                        key={ticket._id}
                                        onClick={() => handleTicketClick(ticket._id)}
                                        className={`ticket-item ${selectedTicket?._id === ticket._id ? 'active' : ''}`}
                                    >
                                        <div className="ticket-content">
                                            <h5 className="ticket-subject">{ticket.subject}</h5>
                                            <div className="ticket-info">
                                                <span className={`status-badge ${getStatusBadgeClass(ticket.ticketStatus)}`}>
                                                    {ticket.ticketStatus}
                                                </span>

                                                <span className="ticket-date">
                                                    {new Date(ticket.createdAt).toLocaleDateString()}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Chat Area */}
                        <div className="col-lg-8">
                            {selectedTicket ? (
                                <div className="chat-area">
                                    {/* Chat Header */}
                                    <div className="chat-header">
                                        <div>
                                            <h4 className="chat-title">{selectedTicket.subject}</h4>
                                            <div className="chat-status">
                                                <span className={`status-badge ${getStatusBadgeClass(selectedTicket.ticketStatus)}`}>
                                                    {selectedTicket.ticketStatus}
                                                </span>

                                            </div>
                                        </div>
                                    </div>

                                    {/* Messages Area */}
                                    <div className="messages-container">
                                        <div className="messages-list">
                                            {getAllMessages(selectedTicket).map((msg, index) => (
                                                <div key={index} className={`message ${msg.sender === 'user' ? 'message-outgoing' : 'message-incoming'}`}>
                                                    <div className="message-bubble">
                                                        <div className="message-content">{msg.message}</div>
                                                        <div className="message-time">
                                                            {new Date(msg.timestamp).toLocaleString()}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <div ref={messagesEndRef} />
                                        </div>
                                    </div>

                                    {/* Message Input */}
                                    <div className="message-input-container">
                                        <form onSubmit={handleSendMessage} className="message-form">
                                            <input
                                                type="text"
                                                className="message-input"
                                                placeholder="Type your message..."
                                                value={newMessage}
                                                onChange={(e) => setNewMessage(e.target.value)}
                                            />
                                            <button type="submit" className="send-button">
                                                Send
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            ) : (
                                <div className="empty-state">
                                    <EmptyMessage />
                                    <p className="text-muted">Select a ticket to view messages</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <SellerSupportStyle />
        </main>
    );
};

export default SellerSupport;