import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import StyleChat from './StyleChat';

const SupportMessages = () => {
    const [tickets, setTickets] = useState([]);
    const [filteredTickets, setFilteredTickets] = useState([]);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const messagesEndRef = useRef(null);

    // Filter states
    const [filters, setFilters] = useState({
        status: 'all',
        priority: 'all',
        startDate: '',
        endDate: '',
        userEmail: '',
    });

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        fetchTickets();
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [selectedTicket]);

    useEffect(() => {
        applyFilters();
    }, [tickets, filters]);

    const fetchTickets = async () => {
        try {
            const response = await axios.get('https://server.enjoywiki.com/flip-server/support-messages');
            const reversedTickets = response.data.reverse(); // Reverse the order of tickets
            setTickets(reversedTickets);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching tickets:', error);
            setLoading(false);
        }
    };


    const applyFilters = () => {
        let filtered = [...tickets];

        // Status filter
        if (filters.status !== 'all') {
            filtered = filtered.filter(ticket => ticket.ticketStatus === filters.status);
        }

        // Priority filter
        if (filters.priority !== 'all') {
            filtered = filtered.filter(ticket => ticket.priority === filters.priority);
        }

        // Date range filter
        if (filters.startDate && filters.endDate) {
            filtered = filtered.filter(ticket => {
                const ticketDate = new Date(ticket.createdAt);
                const start = new Date(filters.startDate);
                const end = new Date(filters.endDate);
                return ticketDate >= start && ticketDate <= end;
            });
        }

        // User email filter
        if (filters.userEmail) {
            filtered = filtered.filter(ticket =>
                ticket.ticketUserEmail.toLowerCase().includes(filters.userEmail.toLowerCase())
            );
        }

        setFilteredTickets(filtered);
    };

    const handleTicketSelect = async (ticketId) => {
        const ticket = tickets.find(t => t._id === ticketId);
        setSelectedTicket(ticket);
    };

    const handleStatusChange = async (newStatus) => {
        if (!selectedTicket) return;

        try {
            await axios.put(`https://server.enjoywiki.com/flip-server/support-message-status/${selectedTicket._id}`, {
                ticketStatus: newStatus,
                adminMessage: selectedTicket.adminMessage
            });

            const updatedTicket = { ...selectedTicket, ticketStatus: newStatus };
            setSelectedTicket(updatedTicket);
            setTickets(tickets.map(ticket =>
                ticket._id === selectedTicket._id ? updatedTicket : ticket
            ));
        } catch (error) {
            console.error('Error updating ticket status:', error);
        }
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim() || !selectedTicket) return;

        try {
            const updatedMessages = [...(selectedTicket.adminMessage || []), {
                message: newMessage,
                timestamp: new Date().toISOString(),
                sender: 'admin'
            }];

            await axios.put(`https://server.enjoywiki.com/flip-server/support-message-status/${selectedTicket._id}`, {
                ticketStatus: selectedTicket.ticketStatus,
                adminMessage: updatedMessages
            });

            setSelectedTicket({
                ...selectedTicket,
                adminMessage: updatedMessages
            });

            setTickets(tickets.map(ticket =>
                ticket._id === selectedTicket._id
                    ? { ...ticket, adminMessage: updatedMessages }
                    : ticket
            ));

            setNewMessage('');
            setTimeout(scrollToBottom, 100);
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const FilterSection = () => (
        <div className="p-3 border-bottom bg-white">
            <div className="row g-3">
                <div className="col-md-3">
                    <select
                        className="form-select"
                        value={filters.status}
                        onChange={(e) => setFilters({ ...filters, status: e.target.value })}
                    >
                        <option value="all">All Status</option>
                        <option value="open">Open</option>
                        <option value="in-progress">In Progress</option>
                        <option value="resolved">Resolved</option>
                        <option value="closed">Closed</option>
                    </select>
                </div>
                {/* <div className="col-md-2">
                    <select
                        className="form-select"
                        value={filters.priority}
                        onChange={(e) => setFilters({ ...filters, priority: e.target.value })}
                    >
                        <option value="all">All Priority</option>
                        <option value="high">High</option>
                        <option value="medium">Medium</option>
                        <option value="low">Low</option>
                    </select>
                </div> */}
                <div className="col-md-3">
                    <input
                        type="date"
                        className="form-control"
                        value={filters.startDate}
                        onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
                    />
                </div>
                <div className="col-md-2">
                    <input
                        type="date"
                        className="form-control"
                        value={filters.endDate}
                        onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
                    />
                </div>
                <div className="col-md-4">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search by user email..."
                        value={filters.userEmail}
                        onChange={(e) => setFilters({ ...filters, userEmail: e.target.value })}
                    />
                </div>
            </div>
        </div>
    );

    return (
        <main className="dashboard-main min-vh-100" style={{ background: '#f0f2f5' }}>
            <div className="d-flex flex-column gap-4 p-4">
                <div>
                    <h3 className="text-24 fw-bold mb-2" style={{ color: '#1a1a1a' }}>Support Messages</h3>
                    <div className="d-flex align-items-center gap-2">
                        <span style={{ color: '#666' }}>Admin</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                        <span style={{ color: '#2563eb' }}>Support Messages</span>
                    </div>
                </div>

                <div className="bg-white rounded-4 shadow-sm" style={{ overflow: 'hidden' }}>
                    <FilterSection />
                    <div className="row g-0">
                        {/* Tickets Sidebar */}
                        <div className="col-lg-4 border-end" style={{ maxHeight: '80vh' }}>
                            <div className="p-3 border-bottom" style={{ background: '#f8fafc' }}>
                                <h5 className="mb-0 fw-semibold">Support Tickets</h5>
                            </div>
                            <div className="overflow-auto" style={{ height: 'calc(80vh - 57px - 89px)' }}>
                                {filteredTickets.map((ticket) => (
                                    <div
                                        key={ticket._id}
                                        onClick={() => handleTicketSelect(ticket._id)}
                                        className={`border-bottom transition-all ${selectedTicket?._id === ticket._id ? 'bg-light' : ''
                                            }`}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <div className="p-3 hover-bg-light">
                                            <div className="d-flex justify-content-between align-items-start gap-3">
                                                <div>
                                                    <h6 className="mb-1 text-dark">{ticket.subject}</h6>
                                                    <div className="d-flex align-items-center gap-2 mb-2">
                                                        <span className={`badge ${ticket.ticketStatus === 'in-progress' ? 'bg-warning' :
                                                            ticket.ticketStatus === 'resolved' ? 'bg-success' :
                                                                ticket.ticketStatus === 'closed' ? 'bg-secondary' :
                                                                    'bg-info'
                                                            }`}>
                                                            {ticket.ticketStatus}
                                                        </span>
                                                        {ticket.priority && (
                                                            <span className={`badge ${ticket.priority === 'high' ? 'bg-danger' :
                                                                ticket.priority === 'medium' ? 'bg-warning' :
                                                                    'bg-info'
                                                                }`}>
                                                                {ticket.priority}
                                                            </span>
                                                        )}
                                                        <small className="text-muted">{ticket.ticketUserEmail}</small>
                                                    </div>
                                                </div>
                                                <small className="text-muted">
                                                    {new Date(ticket.createdAt).toLocaleDateString()}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Chat Area */}
                        <div className="col-lg-8 d-flex flex-column" style={{ maxHeight: '80vh' }}>
                            {selectedTicket ? (
                                <>
                                    {/* Chat Header with Admin Controls */}
                                    <div className="p-3 border-bottom" style={{ background: '#f8fafc' }}>
                                        <div className="d-flex justify-content-between align-items-start mb-2">
                                            <h5 className="mb-0 fw-semibold">{selectedTicket.subject}</h5>
                                            <div className="d-flex gap-2">
                                                <select
                                                    className="form-select form-select-sm"
                                                    value={selectedTicket.ticketStatus}
                                                    onChange={(e) => handleStatusChange(e.target.value)}
                                                    style={{ width: 'auto' }}
                                                >
                                                    <option value="open">Open</option>
                                                    <option value="in-progress">In Progress</option>
                                                    <option value="resolved">Resolved</option>
                                                    <option value="closed">Closed</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center gap-2">
                                            <span className={`badge ${selectedTicket.ticketStatus === 'in-progress' ? 'bg-warning' :
                                                selectedTicket.ticketStatus === 'resolved' ? 'bg-success' :
                                                    selectedTicket.ticketStatus === 'closed' ? 'bg-secondary' :
                                                        'bg-info'
                                                }`}>
                                                {selectedTicket.ticketStatus}
                                            </span>
                                            <span className="text-muted">•</span>
                                            <small className="text-muted">{selectedTicket.ticketUserEmail}</small>
                                        </div>
                                    </div>

                                    {/* Messages Area */}
                                    <div className="flex-grow-1 overflow-auto p-4" style={{ background: '#f8fafc' }}>
                                        <div className="d-flex flex-column gap-3">
                                            {/* Initial Message */}
                                            <div className="chat-message-user">
                                                <div className="message-bubble user-bubble">
                                                    <div className="message-content">
                                                        {selectedTicket.message}
                                                    </div>
                                                    <div className="message-time">
                                                        {new Date(selectedTicket.createdAt).toLocaleString()}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Conversation Messages */}
                                            {selectedTicket.adminMessage?.map((msg, index) => (
                                                <div key={index} className={`chat-message-${msg.sender === 'admin' ? 'admin' : 'user'}`}>
                                                    <div className={`message-bubble ${msg.sender === 'admin' ? 'admin-bubble' : 'user-bubble'}`}>
                                                        <div className="message-content">
                                                            {msg.message}
                                                        </div>
                                                        <div className="message-time">
                                                            {new Date(msg.timestamp).toLocaleString()}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <div ref={messagesEndRef} />
                                        </div>
                                    </div>

                                    {/* Message Input */}
                                    <div className="p-3 border-top bg-white">
                                        <form onSubmit={handleSendMessage} className="d-flex gap-2">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Type your message..."
                                                value={newMessage}
                                                onChange={(e) => setNewMessage(e.target.value)}
                                                disabled={selectedTicket.ticketStatus === 'closed'}
                                                style={{
                                                    borderRadius: '20px',
                                                    padding: '10px 20px',
                                                    border: '1px solid #e2e8f0'
                                                }}
                                            />
                                            <button
                                                type="submit"
                                                className="btn btn-primary px-4"
                                                disabled={selectedTicket.ticketStatus === 'closed'}
                                                style={{
                                                    borderRadius: '20px', background: '#2563eb',
                                                    border: 'none'
                                                }}
                                            >
                                                Send
                                            </button>
                                        </form>
                                    </div>
                                </>
                            ) : (
                                <div className="d-flex justify-content-center align-items-center h-100 text-muted">
                                    <div className="text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-chat-dots mb-3" viewBox="0 0 16 16">
                                            <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                            <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                                        </svg>
                                        <p>Select a ticket to view the conversation</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <StyleChat />
        </main>
    );
};

export default SupportMessages;