import React from "react";
import {
    Home,
    Book,
    Shield,
    UserCheck,
    UserPlus,
    AlertTriangle,
    DollarSign,
    FileCheck,
    Lock,
    FileText,
    Settings,
    Scale,
    Store,
    ShoppingCart,
    Mail,
    FileX,
    NotebookPen,
    MessageSquareDot
} from "lucide-react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const TermsAndCondition = () => {
    return (
        <>
            <Helmet>
                <title>Terms and Conditions | FlipBundle</title>
                <meta
                    name="description"
                    content="Read the terms and conditions of using FlipBundle. Our guidelines and policies ensure a safe and secure experience for all users on our digital product marketplace."
                />
            </Helmet>

            {/* Hero Section */}
            <div className="position-relative bg-primary text-white py-5 bg-offWhite">
                <div className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25"></div>
                <div className="container position-relative">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-3">
                                    <li className="breadcrumb-item d-flex align-items-center">
                                        <Home className="me-1" size={16} />
                                        <Link to="/" className="text-white text-decoration-none">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active text-white" aria-current="page">
                                        Terms & Conditions
                                    </li>
                                </ol>
                            </nav>
                            <h1 className="display-5 fw-bold mb-3 d-flex align-items-center">
                                <FileCheck className="me-3" size={45} />
                                Terms & Conditions
                            </h1>
                            <p className="fs-4 opacity-75">Welcome to FlipBundle.com</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <main className="py-5 mb-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            {/* Introduction Alert */}
                            <div className="alert alert-primary mb-4">
                                <p className="mb-0">
                                    By accessing and using FlipBundle.com ("Website"), you agree to comply with and be bound by the following terms and conditions.
                                    Please read them carefully before using our Website.
                                </p>
                            </div>

                            {/* Acceptance Section */}
                            <div className="card mb-4 shadow-sm border-0 bg-gradient">
                                <div className="card-body p-4">
                                    <div className="d-flex gap-3">
                                        <FileText className="text-primary flex-shrink-0" size={24} />
                                        <div>
                                            <h2 className="h3 mb-3">1. Acceptance of Terms</h2>
                                            <p className="text-muted mb-0">
                                                By registering, accessing, or using any services provided on FlipBundle.com,
                                                you agree to these Terms. If you do not agree, you must not use the Website.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Eligibility Section */}
                            <div className="card mb-4 shadow-sm border-0">
                                <div className="card-body p-4">
                                    <div className="d-flex gap-3">
                                        <UserCheck className="text-success flex-shrink-0" size={24} />
                                        <div>
                                            <h2 className="h3 mb-3">2. Eligibility</h2>
                                            <p className="text-muted mb-0">
                                                You must be at least 18 years old to use our services. By using our Website,
                                                you represent and warrant that you are of legal age to form a binding contract.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Account Registration */}
                            <div className="card mb-4 shadow-sm border-0">
                                <div className="card-body p-4">
                                    <div className="d-flex gap-3">
                                        <UserPlus className="text-info flex-shrink-0" size={24} />
                                        <div>
                                            <h2 className="h3 mb-3">3. Account Registration</h2>
                                            <p className="text-muted mb-0">
                                                To sell or purchase products, you must create an account. You are responsible
                                                for maintaining the confidentiality of your account information and for all
                                                activities under your account.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Seller Section */}
                            <div className="card mb-4 shadow-sm border-0">
                                <div className="card-body p-4">
                                    <div className="d-flex gap-3">
                                        <Store className="text-warning flex-shrink-0" size={24} />
                                        <div>
                                            <h2 className="h3 mb-3">Seller Conditions</h2>
                                            <div className="bg-light p-4 rounded-3">
                                                <ul className="list-unstyled mb-0">
                                                    <li className="mb-3 d-flex">
                                                        <DollarSign className="text-warning me-2 flex-shrink-0" size={20} />
                                                        <span>FlipBundle.com will take a 20% commission from the seller when an item is successfully sold.</span>
                                                    </li>
                                                    <li className="mb-3 d-flex">
                                                        <AlertTriangle className="text-warning me-2 flex-shrink-0" size={20} />
                                                        <span>If a seller accidentally opens a dispute, and it's resolved in favor of the buyer, we will refund the amount from the seller.</span>
                                                    </li>
                                                    <li className="mb-3 d-flex">
                                                        <FileCheck className="text-warning me-2 flex-shrink-0" size={20} />
                                                        <span>Sellers can sell bulk digital assets but cannot sell any illegal items.</span>
                                                    </li>
                                                    <li className="mb-3 d-flex">
                                                        <FileX className="text-warning me-2 flex-shrink-0" size={20} />
                                                        <span>Products must match the description provided by the seller. If there are differences, the product might not be listed.</span>
                                                    </li>
                                                    <li className="d-flex">
                                                        <NotebookPen className="text-warning me-2 flex-shrink-0" size={20} />
                                                        <span> All digital products will be reviewed by FlipBundle to ensure quality. Products that don’t meet our standards won’t be listed.</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Buyer Section */}
                            <div className="card mb-4 shadow-sm border-0">
                                <div className="card-body p-4">
                                    <div className="d-flex gap-3">
                                        <ShoppingCart className="text-success flex-shrink-0" size={24} />
                                        <div>
                                            <h2 className="h3 mb-3">Buyer Conditions</h2>
                                            <div className="bg-light p-4 rounded-3">
                                                <ul className="list-unstyled mb-0">
                                                    <li className="mb-3 d-flex align-items-start">
                                                        <FileCheck className="text-success me-2 flex-shrink-0" size={20} />
                                                        <span>All sales are final, and refunds are only provided under specific conditions as outlined in our Refund Policy.</span>
                                                    </li>
                                                    <li className="mb-3 d-flex align-items-start">
                                                        <AlertTriangle className="text-success me-2 flex-shrink-0" size={20} />
                                                        <span>Buyers should review product details carefully before making a purchase.</span>
                                                    </li>
                                                    <li className="d-flex align-items-start">
                                                        <MessageSquareDot className="text-success me-2 flex-shrink-0" size={20} />
                                                        <span>Products are delivered digitally and should be available immediately after a successful payment. If a buyer experiences an issue, they should contact the FlipBundle Support Team on the platform for assistance.</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Contact Section */}
                            <div className="card bg-primary text-white text-center">
                                <div className="card-body p-4">
                                    <h2 className="h3 mb-3">Contact Us</h2>
                                    <p className="mb-4">
                                        If you have any questions or concerns about these Terms, please don't hesitate to reach out.
                                    </p>
                                    <Link
                                        to="/contact"
                                        className="btn btn-light d-inline-flex align-items-center"
                                    >
                                        <Mail className="me-2" size={16} />
                                        Contact Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <style jsx>{`
                .card {
                    transition: all 0.3s ease;
                    border: none;
                }
                .card:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15) !important;
                }
                .bg-gradient {
                    background: linear-gradient(to right, #ffffff, #f8f9fa);
                }
            `}</style>
        </>
    );
};

export default TermsAndCondition;