import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../../firebase.init';
import toast from 'react-hot-toast';

const BuyerCreateSupportTicket = () => {
    const navigate = useNavigate();
    const [user] = useAuthState(auth);
    const [userData, setUserData] = useState(null);
    const [ticketData, setTicketData] = useState({
        subject: '',
        message: '',
        ticketUserEmail: user?.email,
        ticketStatus: 'open',
        createdAt: new Date(),
        ticketUserRole: '',
        ticketUserName: '',
        ticketUserProfileURL: '',
    });

    useEffect(() => {
        if (user?.email) {
            fetch(`https://server.enjoywiki.com/flip-server/users?userEmail=${user.email}`)
                .then((res) => res.json())
                .then((data) => {
                    if (data && data[0]) {
                        const userInfo = data[0];
                        setUserData(userInfo);
                        setTicketData(prevData => ({
                            ...prevData,
                            ticketUserRole: userInfo.userRole || '',
                            ticketUserName: userInfo.userName || '',
                            ticketUserProfileURL: userInfo.profileURL || '',
                        }));
                    }
                })
                .catch(error => {
                    console.error("Error fetching user data:", error);
                    toast.error("Error loading user data");
                });
        }
    }, [user]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const completeTicketData = {
                ...ticketData,
                ticketUserRole: userData?.userRole || ticketData.userRole,
                ticketUserName: userData?.userName || ticketData.userName,
                ticketUserProfileURL: userData?.profileURL || ticketData.profileURL,
            };

            await axios.post('https://server.enjoywiki.com/flip-server/new-support-message', completeTicketData);
            toast.success('Support ticket created successfully');
            navigate('/seller/support-messages');
        } catch (error) {
            console.error('Error creating ticket:', error);
            toast.error('Failed to create support ticket');
        }
    };

    return (
        <div className="dashboard-main">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="mb-4">Create Support Ticket</h3>
                            {userData && (
                                <div className="user-info mb-4">
                                    <div className="d-flex align-items-center gap-3">
                                        <img
                                            src={userData.profileURL}
                                            alt={userData.userName}
                                            className="rounded-circle"
                                            style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                        />
                                        <div>
                                            <h5 className="mb-1">{userData.userName}</h5>
                                            <span className="badge bg-primary">{userData.userRole}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label className="form-label">Subject</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={ticketData.subject}
                                        onChange={(e) => setTicketData({ ...ticketData, subject: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Message</label>
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        value={ticketData.message}
                                        onChange={(e) => setTicketData({ ...ticketData, message: e.target.value })}
                                        required
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary">Submit Ticket</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <style jsx>{`
                .user-info {
                    padding: 1rem;
                    background-color: #f8fafc;
                    border-radius: 0.5rem;
                    border: 1px solid #e5e7eb;
                }
                
                .dashboard-main {
                    padding: 1.5rem;
                    background-color: #f0f2f5;
                    min-height: 100vh;
                }
                
                .card {
                    border-radius: 1rem;
                    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
                }
                
                .card-body {
                    padding: 2rem;
                }
                
                .form-control {
                    border-radius: 0.5rem;
                    border: 1px solid #e5e7eb;
                    padding: 0.75rem 1rem;
                }
                
                .form-control:focus {
                    border-color: #2563eb;
                    box-shadow: 0 0 0 2px rgba(37,99,235,0.1);
                }
                
                .btn-primary {
                    background-color: #2563eb;
                    border: none;
                    padding: 0.75rem 1.5rem;
                    border-radius: 0.5rem;
                    font-weight: 500;
                    transition: background-color 0.2s;
                }
                
                .btn-primary:hover {
                    background-color: #1d4ed8;
                }
            `}</style>
        </div>
    );
};

export default BuyerCreateSupportTicket;