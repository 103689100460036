// import React, { useEffect, useState } from 'react';
// import auth from '../../firebase.init';
// import { useAuthState } from 'react-firebase-hooks/auth';
// import { Navigate, useLocation } from 'react-router-dom';
// import Loading from './Loading';

// const RequireAuth = ({ children }) => {
//   const [user, loading, error] = useAuthState(auth);
//   const location = useLocation();
//   const [profileComplete, setProfileComplete] = useState(false);
//   const [profileLoading, setProfileLoading] = useState(true);

//   useEffect(() => {
//     const checkProfileCompleteness = async () => {
//       if (user) {
//         try {
//           const response = await fetch(`https://server.enjoywiki.com/flip-server/users?userEmail=${user.email}`);
//           const data = await response.json();
//           const userProfile = data[0];

//           // Check if all required fields are filled
//           const isComplete = userProfile.userName && userProfile.address && userProfile.city && userProfile.country;
//           setProfileComplete(isComplete);
//         } catch (error) {
//           console.error('Error checking profile completeness:', error);
//         }
//         setProfileLoading(false);
//       }
//     };

//     checkProfileCompleteness();
//   }, [user]);

//   // Show loading if authentication or profile loading is still in progress
//   if (loading || (user && profileLoading)) {
//     return <Loading />;
//   }

//   // Redirect to login if user is not logged in
//   if (!user) {
//     return <Navigate to="/login" state={{ from: location }} replace />;
//   }

//   // Redirect to profile update if profile is incomplete
//   if (!profileComplete) {
//     return <Navigate to="/update-profile" state={{ from: location }} replace />;
//   }

//   // Render children if authenticated and profile is complete
//   return children;
// };

// export default RequireAuth;



// import React, { useEffect, useState } from 'react';
// import auth from '../../firebase.init';
// import { useAuthState } from 'react-firebase-hooks/auth';
// import { Navigate, useLocation } from 'react-router-dom';
// import Loading from './Loading';

// const RequireAuth = ({ children }) => {
//   const [user, loading, error] = useAuthState(auth);
//   const location = useLocation();
//   const [userProfile, setUserProfile] = useState(null);
//   const [profileLoading, setProfileLoading] = useState(true);

//   useEffect(() => {
//     const checkProfileCompleteness = async () => {
//       if (user) {
//         try {
//           const response = await fetch(`https://server.enjoywiki.com/flip-server/users?userEmail=${user.email}`);
//           const data = await response.json();
//           const fetchedUserProfile = data[0];
//           setUserProfile(fetchedUserProfile);
//           // Determine profile completeness based on user role
//           if (fetchedUserProfile.userRole === 'Buyer') {
//             // For buyers, check for minimal required fields
//             const isComplete = fetchedUserProfile.userName;
//             setProfileComplete(isComplete);
//           } else {
//             // For sellers, require more complete profile
//             const isComplete = fetchedUserProfile.userName && 
//                                fetchedUserProfile.address && 
//                                fetchedUserProfile.city && 
//                                fetchedUserProfile.country;
//             setProfileComplete(isComplete);
//           }
//         } catch (error) {
//           console.error('Error checking profile completeness:', error);
//         }
//         setProfileLoading(false);
//       }
//     };

//     checkProfileCompleteness();
//   }, [user]);

//   const [profileComplete, setProfileComplete] = useState(false);

//   // Show loading if authentication or profile loading is still in progress
//   if (loading || (user && profileLoading)) {
//     return <Loading />;
//   }

//   // Redirect to login if user is not logged in
//   if (!user) {
//     return <Navigate to="/login" state={{ from: location }} replace />;
//   }

//   // Check for pending product purchase after registration
//   const pendingProductId = localStorage.getItem('pendingProductPurchase');
//   const shouldRedirectToBuyPage = localStorage.getItem('redirectToBuyPage') === 'true';

//   // Special handling for buyers with pending product purchase
//   if (userProfile && userProfile.userRole === 'Buyer' && pendingProductId && shouldRedirectToBuyPage) {
//     localStorage.removeItem('pendingProductPurchase');
//     localStorage.removeItem('redirectToBuyPage');
//     return <Navigate to={`/buy/${pendingProductId}`} replace />;
//   }

//   // Redirect to profile update if profile is incomplete (with different requirements for buyers and sellers)
//   if (!profileComplete) {
//     return <Navigate to="/update-profile" state={{ from: location }} replace />;
//   }

//   // Render children if authenticated and profile is complete
//   return children;
// };

// export default RequireAuth;

import React, { useEffect, useState } from 'react';
import auth from '../../firebase.init';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate, useLocation } from 'react-router-dom';
import Loading from './Loading';

const RequireAuth = ({ children }) => {
  const [user, loading, error] = useAuthState(auth);
  const location = useLocation();
  const [userProfile, setUserProfile] = useState(null);
  const [profileLoading, setProfileLoading] = useState(true);
  const [profileComplete, setProfileComplete] = useState(false);

  // Store auth state in sessionStorage when user logs in
  useEffect(() => {
    if (user) {
      sessionStorage.setItem('isAuthenticated', 'true');
      sessionStorage.setItem('userEmail', user.email);
    }
  }, [user]);

  // Check and store profile data
  useEffect(() => {
    const checkProfileCompleteness = async () => {
      if (user) {
        try {
          // Get cached profile data from sessionStorage first
          const cachedProfile = sessionStorage.getItem('userProfile');
          if (cachedProfile) {
            const parsedProfile = JSON.parse(cachedProfile);
            setUserProfile(parsedProfile);
            checkProfileFields(parsedProfile);
          } else {
            // Fetch from API if no cached data
            const response = await fetch(`https://server.enjoywiki.com/flip-server/users?userEmail=${user.email}`);
            if (!response.ok) throw new Error('Failed to fetch user profile');
            
            const data = await response.json();
            const fetchedUserProfile = data[0];
            
            // Cache the profile data in sessionStorage
            sessionStorage.setItem('userProfile', JSON.stringify(fetchedUserProfile));
            setUserProfile(fetchedUserProfile);
            checkProfileFields(fetchedUserProfile);
          }
        } catch (error) {
          console.error('Error checking profile completeness:', error);
          // Store error state in sessionStorage
          sessionStorage.setItem('profileError', error.message);
        } finally {
          setProfileLoading(false);
        }
      }
    };

    checkProfileCompleteness();

    // Cleanup function to remove sensitive data when component unmounts
    return () => {
      if (!user) {
        sessionStorage.removeItem('userProfile');
        sessionStorage.removeItem('isAuthenticated');
        sessionStorage.removeItem('userEmail');
        sessionStorage.removeItem('profileError');
      }
    };
  }, [user]);

  // Helper function to check profile completeness
  const checkProfileFields = (profile) => {
    if (!profile) return false;

    if (profile.userRole === 'Buyer') {
      const isComplete = Boolean(profile.userName);
      setProfileComplete(isComplete);
      sessionStorage.setItem('profileComplete', isComplete);
    } else {
      const isComplete = Boolean(
        profile.userName &&
        profile.address &&
        profile.city &&
        profile.country
      );
      setProfileComplete(isComplete);
      sessionStorage.setItem('profileComplete', isComplete);
    }
  };

  // Handle pending product purchase
  useEffect(() => {
    const handlePendingPurchase = () => {
      const pendingProductId = sessionStorage.getItem('pendingProductPurchase');
      const shouldRedirectToBuyPage = sessionStorage.getItem('redirectToBuyPage') === 'true';

      if (userProfile?.userRole === 'Buyer' && pendingProductId && shouldRedirectToBuyPage) {
        sessionStorage.removeItem('pendingProductPurchase');
        sessionStorage.removeItem('redirectToBuyPage');
        return <Navigate to={`/buy/${pendingProductId}`} />;
      }
    };

    handlePendingPurchase();
  }, [userProfile]);

  if (loading || (user && profileLoading)) {
    return <Loading />;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!profileComplete) {
    return <Navigate to="/update-profile" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;