

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Rating from 'react-rating';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import toast from 'react-hot-toast';
import { Calendar, CreditCard, Package, Star, Tag } from 'lucide-react';
import { Helmet } from 'react-helmet';

const BuyerReview = () => {
    const { id } = useParams();
    const [seller, setSeller] = useState({});
    const [reviews, setReviews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [soldCount, setSoldCount] = useState(0);
    const [userRating, setUserRating] = useState(0);
    const navigate = useNavigate();
    const [order, setOrders] = useState([]);
    const [user] = useAuthState(auth);
    const [users, setUsers] = useState({});

    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/order/${id}`)
            .then((res) => res.json())
            .then((info) => setOrders(info));
    }, [id]);

    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/product-reviews`)
            .then((res) => res.json())
            .then((info) => setReviews(info));
    }, []);

    useEffect(() => {
        if (user?.email) {
            fetch(`https://server.enjoywiki.com/flip-server/users`)
                .then((res) => res.json())
                .then((info) => {
                    const loggedInUser = info.find(u => u.UserEmail === user?.email);
                    setUsers(loggedInUser);
                })
                .catch(error => {
                    console.error('Error fetching users:', error);
                });
        }
    }, [user]);

    const handleRatingChange = (rating) => {
        setUserRating(rating);
    };

    const handleAddReview = (event) => {
        event.preventDefault();

        const addReview = {
            productId: order.packageId,
            sellerId: order.sellerId,
            sellerEmail: order.sellerEmail,
            orderId: id,
            clientId: users._id,
            date: new Date().toLocaleDateString('en-US', {
                day: '2-digit',
                month: 'long',
                year: 'numeric'
            }),
            reviewerEmail: user?.email,
            reviewerName: users.userName,
            country: users.country,
            reviewerProfileURL: users.profileURL,
            review: event.target.review.value,
            rating: userRating
        };

        fetch(`https://server.enjoywiki.com/flip-server/add-review`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(addReview),
        })
            .then((res) => res.json())
            .then((result) => {
                updateProductReviewStatus(id);
            })
            .catch(error => {
                console.error('Error adding review:', error);
                toast.error('Failed to add review');
            });
    };

    const updateProductReviewStatus = () => {
        const order = {
            productReview: "yes"
        };
        fetch(`https://server.enjoywiki.com/flip-server/user-review/${id}`, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(order),
        })
            .then((res) => res.json())
            .then((result) => {
                navigate('/buyer/orders');
            })
            .catch(error => {
                console.error('Error updating product review status:', error);
            });
    };

    return (
        <>
            <Helmet>
                <title>Leave a Review | FlipBundle - Your Feedback Matters</title>
                <meta name="description" content="Share your thoughts on the products you’ve purchased from FlipBundle. Your honest review helps us improve and assists future buyers." />
            </Helmet>

            <section
                className="w-breadcrumb-area"
                style={{
                    background: "url('assets/img/common/breadcrumb-bg.png') no-repeat center center/cover"
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-auto">
                            <div
                                className="position-relative z-2"

                            >
                                <h2 className="section-title-light mb-2">Write a Review</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb w-breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Review
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* Review Form Section */}
            <section className="pb-110 bg-offWhite">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6">
                            <div className="contact-form">
                                <div className="mb-5">
                                    <h3 className="mb-4 mt-4 contact-form-title">Share Your Experience</h3>
                                    <p className="contact-form-desc">
                                        Your feedback helps us improve our services and assists other customers
                                        in making informed decisions.
                                    </p>
                                </div>

                                <form className="contact-form" onSubmit={handleAddReview}>
                                <h4 className="mb-4 mt-4 contact-form-title">Product Name: <br />{order.packageName}</h4>
                                    <div className="mb-3">
                                        Select Rating: <span className="text-lime-300">*</span>
                                        <div className="d-flex gap-1">
                                            {[1, 2, 3, 4, 5].map((rating) => (
                                                <button
                                                    key={rating}
                                                    type="button"
                                                    className={`btn ${userRating >= rating
                                                        ? 'btn-warning'
                                                        : 'btn-outline-warning'
                                                        }`}
                                                    onClick={() => handleRatingChange(rating)}
                                                >
                                                    <Star />
                                                </button>
                                            ))}
                                        </div>
                                    </div>





                                    {/* Hidden inputs */}
                                    <input type="hidden" name="productId" value={order.packageId || ''} />
                                    <input type="hidden" name="sellerId" value={order.sellerId || ''} />
                                    <input type="hidden" name="sellerEmail" value={order.sellerEmail || ''} />
                                    <input type="hidden" name="clientId" value={users._id || ''} />
                                    <input type="hidden" name="country" value={users.country || ''} />
                                    <input type="hidden" name="orderId" value={id || ''} />
                                    <input type="hidden" name="reviewerName" value={users.userName || ''} />
                                    <input type="hidden" name="reviewerEmail" value={user?.email || ''} />

                                    <div className="contact-form-input col-lg-12">
                                        <label htmlFor="review" className="form-label">
                                            Review <span className="text-lime-300">*</span>
                                        </label>
                                        <textarea
                                            name="review"
                                            id="review"
                                            placeholder="Share your experience..."
                                            className="form-textarea form-control shadow-none"
                                            rows={4}
                                            required
                                        ></textarea>
                                    </div>

                                    <div className="mt-4">
                                        {reviews.filter(review => review.orderId === order._id && review.reviewerEmail === user?.email).length === 0 ? (
                                            <button type="submit" className="w-btn-secondary-sm">
                                                Submit Review
                                            </button>
                                        ) : (
                                            <p className="w-btn-secondary-sm disabled">
                                                Already Reviewed
                                            </p>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="contact-map">
                                <div className="bg-white p-4 rounded-3 h-100">
                                    <h4 className="mb-4">Review Guidelines</h4>
                                    <ul className="list-unstyled">
                                        <li className="mb-3 d-flex align-items-start">
                                            <i className="fas fa-check-circle text-primary me-2 mt-1"></i>
                                            <span>Be specific about your experience with the product/service</span>
                                        </li>
                                        <li className="mb-3 d-flex align-items-start">
                                            <i className="fas fa-check-circle text-primary me-2 mt-1"></i>
                                            <span>Highlight what you liked and areas for improvement</span>
                                        </li>
                                        <li className="mb-3 d-flex align-items-start">
                                            <i className="fas fa-check-circle text-primary me-2 mt-1"></i>
                                            <span>Keep your review honest and constructive</span>
                                        </li>
                                        <li className="mb-3 d-flex align-items-start">
                                            <i className="fas fa-check-circle text-primary me-2 mt-1"></i>
                                            <span>Avoid including personal or sensitive information</span>
                                        </li>
                                    </ul>
                                    <img
                                        src="https://firebasestorage.googleapis.com/v0/b/mobile-app-d6c0d.appspot.com/o/images%2Ffeedback.jpg?alt=media&token=0b6d3f72-3952-44fe-b15f-8bee5b856864"
                                        alt="Review Guidelines"
                                        className="img-fluid rounded mt-4"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BuyerReview;