import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PaginationGlobal from '../Shared/PaginationGlobal';

const AllCategoryProducts = () => {
    const { slug } = useParams();
    const [categoryProducts, setCategoryProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [sellers, setSellers] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [category, setCategory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(12);
    const [loading, setLoading] = useState(true);

    // Filter states
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [priceFilter, setPriceFilter] = useState('');

    // Function to check if data is expired (older than 1 hour)
    const isDataExpired = (timestamp) => {
        if (!timestamp) return true;
        const now = new Date().getTime();
        const oneHour = 60 * 60 * 1000;
        return now - timestamp > oneHour;
    };

    // Function to shuffle array (Fisher-Yates algorithm)
    const shuffleArray = (array) => {
        const shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    // Handle filters
    useEffect(() => {
        let result = [...categoryProducts];

        // Search filter
        if (searchTerm) {
            result = result.filter(product =>
                product.productName.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        // Sort by name
        if (sortBy === 'a_to_z') {
            result.sort((a, b) => a.productName.localeCompare(b.productName));
        } else if (sortBy === 'z_to_a') {
            result.sort((a, b) => b.productName.localeCompare(a.productName));
        } else {
            // If no specific sort is selected, randomize the order
            result = shuffleArray(result);
        }

        // Price filter
        if (priceFilter === 'low_to_high') {
            result.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
        } else if (priceFilter === 'high_to_low') {
            result.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
        }

        setFilteredProducts(result);
        setCurrentPage(1);
    }, [categoryProducts, searchTerm, sortBy, priceFilter]);

    // Function to get the number of reviews for a specific product
    const getReviewCount = (productId) => {
        return reviews.filter((review) => review.productId === productId).length;
    };

    // Function to calculate average rating for a specific product
    const getAverageRating = (productId) => {
        const productReviews = reviews.filter((review) => review.productId === productId);
        const totalRating = productReviews.reduce((acc, review) => acc + review.rating, 0);
        return productReviews.length > 0 ? (totalRating / productReviews.length).toFixed(1) : 0;
    };

    // Pagination logic
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Get seller info
    const getSeller = (sellerEmail) => {
        return sellers.find(seller => seller.UserEmail === sellerEmail);
    };


    useEffect(() => {
        const fetchInitialData = async () => {
            setLoading(true);
            try {
                const [productsRes, sellersRes, reviewsRes, categoryRes] = await Promise.all([
                    fetch(`https://server.enjoywiki.com/flip-server/category?categorySlug=${slug}`),
                    fetch('https://server.enjoywiki.com/flip-server/users'),
                    fetch('https://server.enjoywiki.com/flip-server/product-reviews'),
                    fetch(`https://server.enjoywiki.com/flip-server/category/${slug}`)
                ]);

                const [productsData, sellersData, reviewsData, categoryData] = await Promise.all([
                    productsRes.json(),
                    sellersRes.json(),
                    reviewsRes.json(),
                    categoryRes.json()
                ]);

                // CHANGE: Filter products to only include Published status
                const publishedProducts = productsData.filter(product =>
                    product.productStatus === 'Published' &&
                    product.categorySlug === slug
                );

                // Randomize the filtered products array
                const randomizedProducts = shuffleArray(publishedProducts);

                setCategoryProducts(randomizedProducts);
                setFilteredProducts(randomizedProducts);
                setSellers(sellersData);
                setReviews(reviewsData);
                setCategory(categoryData);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchInitialData();
    }, [slug]);
    return (
        <>
            <Helmet>
                <title>{`${category.SEOTitle || 'Loading...'} | FlipBundle.com`}</title>
                <meta name="description" content="Welcome to FlipBundle.com, your premier destination for bulk digital products." />
            </Helmet>
            <section
                className="w-breadcrumb-area"
                style={{
                    backgroundImage:
                        "url(/assets/img/breadcrumb-bg.svg)",
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-auto">
                            <div
                                className="position-relative z-2 aos-init">
                                <h1 className="section-title-light mb-2">{category.categoryName}</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb w-breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link>Category</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {category.categoryName}
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blog-page-area pd-top-100 pd-bottom-100">
                <div className="container">
                    <p className='mt-5'>{category.categoryDetails}</p>

                    <div className="container-fluid py-4 bg-light rounded shadow-sm mb-4 mt-5">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-auto flex-grow-1 mb-3 mb-md-0">
                                <div className="input-group">
                                    <span className="input-group-text bg-white border-end-0">
                                        <i className="bi bi-search"></i>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control shadow-none border-start-0 ps-0"
                                        placeholder="Search products..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        aria-label="Search products"
                                    />
                                </div>
                            </div>

                            <div className="col-12 col-md-auto">
                                <div className="d-flex gap-3 flex-wrap">
                                    <div className="flex-grow-1">
                                        <select
                                            className="form-control shadow-none bg-white border"
                                            value={sortBy}
                                            onChange={(e) => setSortBy(e.target.value)}
                                            aria-label="Sort by name"
                                        >
                                            <option value="">Sort By Name</option>
                                            <option value="a_to_z">A to Z</option>
                                            <option value="z_to_a">Z to A</option>
                                        </select>
                                    </div>

                                    <div className="flex-grow-1">
                                        <select
                                            className="form-control shadow-none bg-white border"
                                            value={priceFilter}
                                            onChange={(e) => setPriceFilter(e.target.value)}
                                            aria-label="Sort by price"
                                        >
                                            <option value="">Price (Default)</option>
                                            <option value="low_to_high">Low to High</option>
                                            <option value="high_to_low">High to Low</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Products Grid */}
                    <div className="row mt-5">
                        <div className="col-lg-12 order-lg-last">
                            <div className="all-item-section all-item-area-2">
                                {loading ? (
                                    <div className="text-center py-5">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                ) : currentProducts.length > 0 ? (
                                    <div className="row">
                                        {currentProducts.map(product => {
                                            const seller = getSeller(product.sellerEmail);
                                            return (
                                                <div className="col-lg-3 col-md-6 mb-4" key={product._id}>
                                                    <div className="service-card bg-white">
                                                        <div className="position-relative">
                                                            <Link to={`/${product.slug}/${product.productId}`}>
                                                                <img
                                                                    src={product.featuredImage || "assets/img/services/1.png"}
                                                                    className="recently-view-card-img w-100"
                                                                    alt={product.productName}
                                                                    style={{ aspectRatio: '16 / 9', objectFit: 'cover' }}
                                                                />
                                                            </Link>
                                                        </div>
                                                        <div className="service-card-content">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div>
                                                                    <h3 className="service-card-price fw-bold">${product.price}</h3>
                                                                </div>
                                                                <div className="d-flex align-items-center gap-1">
                                                                    <span className="service-card-rating">
                                                                        {getAverageRating(product._id)} ({getReviewCount(product._id)} Reviews)
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <h3 className="service-card-title fw-semibold">
                                                                <Link to={`/${product.slug}/${product.productId}`}>
                                                                    {product.productName}
                                                                </Link>
                                                            </h3>
                                                            <div className="d-flex align-items-center service-card-author">
                                                                <img
                                                                    src={seller?.profileURL || "https://raw.githubusercontent.com/Shah-Limon/em-list/master/images-dash/profile.jpg"}
                                                                    className="service-card-author-img"
                                                                    alt=""
                                                                />
                                                                <Link to={`/profile-seller/${seller?._id}`} className="service-card-author-name">
                                                                    {seller?.userName}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div className="text-center py-5">
                                        <svg
                                            viewBox="0 0 240 200"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-40 h-40 mx-auto mb-4"
                                            style={{ maxWidth: '160px' }}
                                        >
                                            <rect x="40" y="30" width="160" height="140" fill="#f3f4f6" rx="8" />
                                            <path d="M60 60 H180" stroke="#d1d5db" strokeWidth="4" strokeLinecap="round" />
                                            <path d="M60 90 H180" stroke="#d1d5db" strokeWidth="4" strokeLinecap="round" />
                                            <path d="M60 120 H140" stroke="#d1d5db" strokeWidth="4" strokeLinecap="round" />
                                            <circle cx="160" cy="140" r="40" fill="none" stroke="#9ca3af" strokeWidth="4" />
                                            <line x1="188" y1="168" x2="200" y2="180" stroke="#9ca3af" strokeWidth="4" strokeLinecap="round" />
                                            <path d="M150 140 H170" stroke="#9ca3af" strokeWidth="4" strokeLinecap="round" />
                                        </svg>
                                        <h3 className="text-xl font-semibold text-gray-700 mb-2">No Products Found</h3>
                                        <p className="text-gray-500 mb-4">
                                            We couldn't find any products matching your search criteria.
                                        </p>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => {
                                                setSearchTerm('');
                                                setSortBy('');
                                                setPriceFilter('');
                                            }}
                                        >
                                            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                Go to Home
                                            </Link>
                                        </button>

                                    </div>
                                )}

                                {/* Pagination */}
                                {!loading && filteredProducts.length > productsPerPage && (
                                    <div className='text-center mb-5'>
                                        <PaginationGlobal
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={paginate}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AllCategoryProducts;