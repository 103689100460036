import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import toast from "react-hot-toast";
import auth from "../../../../firebase.init";
import LoadingSpinner from "../../../../components/Shared/Loading";
import { FileSliders } from "lucide-react";
import { Helmet } from "react-helmet";

const SellerAllOrders = () => {
    const [orders, setOrders] = useState([]);
    const [selectedDateFilter, setSelectedDateFilter] = useState("all");
    const [customStartDate, setCustomStartDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState("all");
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("all");
    const [loading, setLoading] = useState(true);
    const [user] = useAuthState(auth);
    const [activeTab, setActiveTab] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    // Function to fetch orders from server
    const fetchOrders = () => {
        fetch(`https://server.enjoywiki.com/flip-server/orders?sellerEmail=${user?.email}`)
            .then((res) => res.json())
            .then((info) => {
                setOrders(info.reverse());
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching orders:", error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchOrders();
    }, [user]);

    // Reset pagination when filters change
    useEffect(() => {
        setCurrentPage(1);
    }, [selectedDateFilter, customStartDate, customEndDate, selectedOrderStatus, selectedPaymentStatus, activeTab]);

    // Function to filter orders based on all criteria
    const filterOrders = () => {
        const currentDate = new Date();

        return orders.filter((order) => {
            const orderDate = new Date(order.orderDate);
            const isOrderWithinDateFilter =
                selectedDateFilter === "all" ||
                (selectedDateFilter === "thisMonth" &&
                    orderDate.getMonth() === currentDate.getMonth()) ||
                (selectedDateFilter === "last7Days" &&
                    orderDate >= new Date(currentDate.setDate(currentDate.getDate() - 7))) ||
                (selectedDateFilter === "previousMonth" &&
                    orderDate >= new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1) &&
                    orderDate <= new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)) ||
                (selectedDateFilter === "custom" &&
                    customStartDate &&
                    customEndDate &&
                    orderDate >= new Date(customStartDate) &&
                    orderDate <= new Date(customEndDate));

            const isOrderMatchingStatus =
                selectedOrderStatus === "all" ||
                order.orderStatus === selectedOrderStatus;

            const isOrderMatchingPaymentStatus =
                selectedPaymentStatus === "all" ||
                order.paymentStatus === selectedPaymentStatus;

            // Tab filtering
            if (activeTab === 'active') return order.paymentStatus === 'Paid';
            if (activeTab === 'pending') return order.paymentStatus === 'pending';
            if (activeTab === 'completed') return order.paymentStatus === 'Completed';
            if (activeTab === 'close') return order.paymentStatus === 'Cancelled';

            return isOrderWithinDateFilter && isOrderMatchingStatus && isOrderMatchingPaymentStatus;
        });
    };

    const filteredOrders = filterOrders();

    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentOrders = filteredOrders.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Note handling
    const handleEditNote = (event, id, index) => {
        event.preventDefault();
        const noteAddedBySeller = event.target.noteAddedBySeller.value;
        const sellerAddedNote = event.target.sellerAddedNote.value;

        const edit = {
            noteAddedBySeller,
            sellerAddedNote
        };

        fetch(`https://server.enjoywiki.com/flip-server/update-note-seller/${id}`, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(edit),
        })
            .then((res) => res.json())
            .then((result) => {
                toast.success('Note Updated Successfully!');
                fetchOrders();
            });
    };

    if (loading) return <LoadingSpinner />;
    return (
        <main className="dashboard-main min-vh-100">
            <Helmet>
                <title>Seller Orders | FlipBundle - Manage Your Digital Product Orders</title>
                <meta name="description" content="View and manage your orders as a seller on FlipBundle. Track customer purchases, process orders, and ensure timely delivery of your digital products." />
            </Helmet>
            <div className="d-flex flex-column gap-4">
                {/* Header */}
                <div className="d-flex flex-column flex-md-row gap-4 align-items-md-center justify-content-between">
                    <div>
                        <h3 className="text-24 fw-bold text-dark-300 mb-2">All Orders</h3>
                        <ul className="d-flex align-items-center gap-2">
                            <li className="text-dark-200 fs-6">Seller Dashboard</li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width={5} height={11} viewBox="0 0 5 11" fill="none">
                                    <path d="M1 10L4 5.5L1 1" stroke="#5B5B5B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </li>
                            <li className="text-lime-300 fs-6">All Orders</li>
                        </ul>
                    </div>
                </div>
                {/* Tabs */}
                <div>
                    <nav>
                        <div className="d-flex flex-wrap gap-3 align-items-center" role="tablist">
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'all' ? 'active' : ''}`}
                                onClick={() => setActiveTab('all')}
                            >
                                All({orders.length})
                            </button>
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'active' ? 'active' : ''}`}
                                onClick={() => setActiveTab('active')}
                            >
                                Paid({orders.filter(order => order.paymentStatus === 'Paid').length})
                            </button>
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'pending' ? 'active' : ''}`}
                                onClick={() => setActiveTab('pending')}
                            >
                                Pending({orders.filter(order => order.paymentStatus === 'pending').length})
                            </button>
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'close' ? 'active' : ''}`}
                                onClick={() => setActiveTab('close')}
                            >
                                Cancelled({orders.filter(order => order.paymentStatus === 'Cancelled').length})
                            </button>
                        </div>
                    </nav>
                    {/* Table Content */}
                    <div className="tab-content mt-4">
                        <div className="overflow-x-auto">
                            <table className="w-100 dashboard-table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="ps-4">Product Name</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Customer</th>
                                        <th scope="col">Order Date</th>
                                        <th scope="col">Notes</th>
                                        <th scope="col" className="text-center">Access/View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentOrders.map((order, index) => (
                                        <tr key={order._id}>
                                            <td>
                                                <div className="d-flex gap-3 align-items-center project-name">
                                                    <div className="order-img">
                                                        <img src={order.featuredImage || "/assets/img/dashboard/orders/o-1.png"} alt="" />
                                                    </div>
                                                    <div>
                                                        <Link to={`/${order.productSlug}/${order.productId}`} className="text-dark-200 project-link">
                                                            {order.packageName}
                                                        </Link>

                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-dark-200">${order.packagePrice}</td>
                                            <td>
                                                <span className={`status-badge ${order.paymentStatus.toLowerCase()}`}>
                                                    {order.paymentStatus}
                                                </span>
                                            </td>
                                            <td className="text-dark-200">{order.customerName}</td>
                                            <td className="text-dark-200">{order.orderDate}</td>
                                            <td>
                                                {order.paymentStatus === 'Paid' && (
                                                    <button
                                                        className="dashboard-action-btn"
                                                        data-bs-toggle="modal"
                                                        data-bs-target={`#noteModal${order._id}`}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} viewBox="0 0 48 48" fill="none">
                                                            <circle cx={24} cy={24} r={24} fill="#EDEBE7" />
                                                            <path d="M18 20L21.7812 22.5208C23.1248 23.4165 24.8752 23.4165 26.2188 22.5208L30 20M18 33H30C32.2091 33 34 31.2091 34 29V19C34 16.7909 32.2091 15 30 15H18C15.7909 15 14 16.7909 14 19V29C14 31.2091 15.7909 33 18 33Z"
                                                                stroke="#5B5B5B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </button>
                                                )}
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-end gap-2">
                                                    {order.paymentStatus === 'Paid' && (
                                                        <Link to={`/seller/update-order/${order._id}`} className="dashboard-action-btn">
                                                            <FileSliders />
                                                        </Link>
                                                    )}
                                                    <button
                                                        className="dashboard-action-btn"
                                                        data-bs-toggle="modal" data-bs-target={`#orderDetails${order._id}`}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} viewBox="0 0 48 48" fill="none">
                                                            <circle cx={24} cy={24} r={24} fill="#EDEBE7" />
                                                            <path d="M34.3187 21.6619C35.6716 23.0854 35.6716 25.248 34.3187 26.6714C32.0369 29.0721 28.1181 32.3333 23.6667 32.3333C19.2152 32.3333 15.2964 29.0721 13.0147 26.6714C11.6618 25.248 11.6618 23.0854 13.0147 21.6619C15.2964 19.2612 19.2152 16 23.6667 16C28.1181 16 32.0369 19.2612 34.3187 21.6619Z"
                                                                stroke="#5B5B5B" strokeWidth="1.5" />
                                                            <circle cx="23.667" cy="24.167" r="3.5" stroke="#5B5B5B" strokeWidth="1.5" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </td>

                                            {/* Note Modal */}
                                            <div className="modal fade" id={`noteModal${order._id}`} tabIndex="-1" aria-hidden="true">
                                                <div className="modal-dialog modal-lg modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">Add or Edit Note</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <form onSubmit={(event) => handleEditNote(event, order._id, index)}>
                                                                <input
                                                                    type="text"
                                                                    hidden
                                                                    name="noteAddedBySeller"
                                                                    value={user?.email}
                                                                    readOnly
                                                                />
                                                                <div className="mb-3">
                                                                    <label className="form-label">Note Description</label>
                                                                    <textarea
                                                                        className="form-control"
                                                                        name="sellerAddedNote"
                                                                        rows={5}
                                                                        defaultValue={order.sellerAddedNote}
                                                                    />
                                                                </div>
                                                                <div className="text-center">
                                                                    <button type="submit" className="btn btn-primary">
                                                                        Save Note
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Order Details Modal */}
                                            <div className="modal fade" id={`orderDetails${order._id}`} tabIndex="-1" aria-hidden="true">
                                                <div className="modal-dialog modal-lg modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">Order Details</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <table className="table table-bordered">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Order ID</td>
                                                                        <td>{order.orderId}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Order Date</td>
                                                                        <td>{order.orderDate}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Product Name</td>
                                                                        <td>{order.packageName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Price</td>
                                                                        <td>${order.packagePrice}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Payment Status</td>
                                                                        <td>{order.paymentStatus}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Customer Name</td>
                                                                        <td>{order.customerName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Customer Note</td>
                                                                        <td>{order.customerNote || 'No note provided'}</td>
                                                                    </tr>
                                                                    {order.accessLink && (
                                                                        <tr>
                                                                            <td>Access Link</td>
                                                                            <td>{order.accessLink}</td>
                                                                        </tr>
                                                                    )}
                                                                    {order.guideLine && (
                                                                        <tr>
                                                                            <td>Guide Line</td>
                                                                            <td>{order.guideLine}</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>

                                                            {order.paymentStatus === 'Paid' && (
                                                                <div className="mt-3">
                                                                    <h6>Platform Fee</h6>
                                                                    <p className="text-danger">-${order.packagePrice * 0.2} USD (20%)</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Empty State */}
                        {currentOrders.length === 0 && (
                            <div className="row justify-content-center">
                                <div className="col-7">
                                    <div className="bg-white p-5 rounded-3 text-center">
                                        <img
                                            src="https://workzone.mamunuiux.com/uploads/website-images/empty-2024-05-17-11-50-01-3653.png"
                                            className="img-fluid mb-4"
                                            alt="Empty orders"
                                        />
                                        <h3 className="text-24 fw-bold text-dark-300 mb-2">
                                            No Orders Found
                                        </h3>
                                        <p className="fs-6 text-dark-200">
                                            No orders match your current filter criteria
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Pagination */}
                        {filteredOrders.length > itemsPerPage && (
                            <div className="row justify-content-end mt-4">
                                <div className="col-auto">
                                    <nav>
                                        <ul className="custom-pagination pagination">
                                            <li className={`custom-page-item page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button
                                                    className="custom-page-link page-link"
                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                    disabled={currentPage === 1}
                                                >
                                                    Previous
                                                </button>
                                            </li>
                                            {[...Array(totalPages)].map((_, i) => (
                                                <li
                                                    key={i + 1}
                                                    className={`custom-page-item page-item ${currentPage === i + 1 ? 'active' : ''}`}
                                                >
                                                    <button
                                                        className="custom-page-link page-link"
                                                        onClick={() => handlePageChange(i + 1)}
                                                    >
                                                        {i + 1}
                                                    </button>
                                                </li>
                                            ))}
                                            <li className={`custom-page-item page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                <button
                                                    className="custom-page-link page-link"
                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                    disabled={currentPage === totalPages}
                                                >
                                                    Next
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default SellerAllOrders;