import React, { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { AlertCircle, CheckCircle2, CircleSlash } from 'lucide-react';

const ProductStatusModal = ({
    show,
    handleClose,
    productId,
    currentStatus,
    rejectionReason,
    onStatusUpdate
}) => {
    const [isLoading, setIsLoading] = useState(false);

    // Determine next possible status
    const getNextStatus = () => {
        switch (currentStatus) {
            case 'Draft':
                return 'Published';
            case 'Approved':
                return 'Inactive';
            default:
                return 'Draft';
        }
    };

    const handleStatusChange = async () => {
        setIsLoading(true);
        const nextStatus = getNextStatus();

        try {
            const response = await axios.put(`https://server.enjoywiki.com/flip-server/product-status/${productId}`, {
                productStatus: nextStatus
            });

            // Update UI
            onStatusUpdate(nextStatus);

            // Show success notification
            toast.success(`Product status updated to ${nextStatus}`);

            // Close modal
            handleClose();
        } catch (error) {
            console.error('Error updating product status:', error);
            toast.error('Failed to update product status');
        } finally {
            setIsLoading(false);
        }
    };

    const renderModalContent = () => {
        switch (currentStatus) {
            case 'Rejected':
                return (
                    <>
                        <div className="modal-header border-0">
                            <h5 className="modal-title text-danger">
                                <AlertCircle className="me-2" size={24} />
                                Product Rejected
                            </h5>
                            <button type="button" className="btn-close" onClick={handleClose}></button>
                        </div>
                        <div className="modal-body">
                            <div className="alert alert-danger">
                                <h6 className="fw-bold">Reason for Rejection:</h6>
                                <p className="mb-0">{rejectionReason || "No specific reason provided."}</p>
                            </div>
                            <p>Please review the rejection reason and make necessary changes before resubmitting.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                        </div>
                    </>
                );

            case 'Approved':
                return (
                    <>
                        <div className="modal-header border-0">
                            <h5 className="modal-title text-success">
                                <CheckCircle2 className="me-2" size={24} />
                                Product Approved
                            </h5>
                            <button type="button" className="btn-close" onClick={handleClose}></button>
                        </div>
                        <div className="modal-body">
                            <div className="alert alert-success">
                                <p className="mb-0">Your product has been approved and is now live on the marketplace!</p>
                            </div>
                            <p>You can now manage your product listing and track sales.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                            <button 
                                type="button" 
                                className="btn btn-danger"
                                onClick={handleStatusChange}
                                disabled={isLoading}
                            >
                                <CircleSlash className="me-1" size={16} />
                                {isLoading ? 'Deactivating...' : 'Deactivate Product'}
                            </button>
                        </div>
                    </>
                );

            default:
                return (
                    <>
                        <div className="modal-header">
                            <h5 className="modal-title">Update Product Status</h5>
                            <button type="button" className="btn-close" onClick={handleClose}></button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Are you sure you want to update the product status from
                                <span className="fw-bold text-primary"> {currentStatus}</span> to
                                <span className="fw-bold text-success"> {getNextStatus()}</span>?
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={handleClose}>Cancel</button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleStatusChange}
                                disabled={isLoading}
                            >
                                {isLoading ? 'Updating...' : 'Confirm Update'}
                            </button>
                        </div>
                    </>
                );
        }
    };

    if (!show) return null;

    return (
        <div
            className="modal fade show"
            tabIndex="-1"
            style={{
                display: 'block',
                backgroundColor: 'rgba(0,0,0,0.5)'
            }}
            onClick={handleClose}
        >
            <div
                className="modal-dialog modal-dialog-centered"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="modal-content">
                    {renderModalContent()}
                </div>
            </div>
        </div>
    );
};

export default ProductStatusModal;