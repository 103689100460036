import React from 'react';

const ProductFeatures = () => {
    return (
        <>
            <ul className="d-flex flex-column flex-md-row mt-4 me-2 gap-3">
                <div className="d-md-none w-100">
                    <div className="row">
                        <div className="col-6">
                            <li className="fs-6 d-flex align-items-center text-dark-200 mb-3">
                                <i className="fas fa-check-circle text-success"></i>
                                <p className="mb-0 mx-2">One-time payment</p>
                            </li>
                            <li className="fs-6 d-flex align-items-center text-dark-200 mb-3">
                                <i className="fas fa-check-circle text-success"></i>
                                <p className="mb-0 mx-2">Instant delivery</p>
                            </li>
                        </div>
                        <div className="col-6">
                            <li className="fs-6 d-flex align-items-center text-dark-200 mb-3">
                                <i className="fas fa-check-circle text-success"></i>
                                <p className="mb-0 mx-2">Lifetime access</p>
                            </li>
                            <li className="fs-6 d-flex align-items-center text-dark-200 mb-3">
                                <i className="fas fa-check-circle text-success"></i>
                                <p className="mb-0 mx-2">Access through a URL</p>
                            </li>
                        </div>
                    </div>
                </div>

                <li className="fs-6 d-none d-md-flex align-items-center text-dark-200">
                    <i className="fas fa-check-circle text-success"></i>
                    <p className="mb-0 mx-2">One-time payment</p>
                </li>
                <li className="fs-6 d-none d-md-flex align-items-center text-dark-200">
                    <i className="fas fa-check-circle text-success"></i>
                    <p className="mb-0 mx-2">Lifetime access</p>
                </li>
                <li className="fs-6 d-none d-md-flex align-items-center text-dark-200">
                    <i className="fas fa-check-circle text-success"></i>
                    <p className="mb-0 mx-2">Instant delivery</p>
                </li>
                <li className="fs-6 d-none d-md-flex align-items-center text-dark-200">
                    <i className="fas fa-check-circle text-success"></i>
                    <p className="mb-0 mx-2">Access through a URL</p>
                </li>
            </ul>

        </>
    );
};

export default ProductFeatures;