import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const MessageList = ({ messages, loading, searchTerm, selectedMessage, onMessageSelect, messageStatus }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const messagesPerPage = 5;

    // Get messages for current page
    const filteredMessages = messages.filter(msg => 
        (msg.messageStatus === messageStatus) &&
        (msg.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        msg.subject.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const indexOfLastMessage = currentPage * messagesPerPage;
    const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
    const currentMessages = filteredMessages.slice(indexOfFirstMessage, indexOfLastMessage);
    const totalPages = Math.ceil(filteredMessages.length / messagesPerPage);

    return (
        <div className="message-section mb-4">
            <h4 className="fw-normal fs-6 mb-2">
                {messageStatus} Messages ({filteredMessages.length})
            </h4>
            
            <div className="message-list">
                {loading ? (
                    <div className="text-center p-4">Loading messages...</div>
                ) : (
                    <>
                        {currentMessages.map((message) => (
                            <div
                                key={message._id}
                                onClick={() => onMessageSelect(message._id)}
                                className={`d-flex align-items-center p-3 rounded-3 mb-2 cursor-pointer ${
                                    selectedMessage?._id === message._id ? 'bg-primary text-white' : 'bg-white'
                                }`}
                                style={{ cursor: 'pointer' }}
                            >
                                <div className="flex-shrink-0">
                                    <div 
                                        className="rounded-circle bg-secondary text-white d-flex align-items-center justify-content-center"
                                        style={{ width: '40px', height: '40px' }}
                                    >
                                        {message.name.charAt(0)}
                                    </div>
                                </div>
                                <div className="ms-3 flex-grow-1">
                                    <h6 className="mb-1">{message.name}</h6>
                                    <p className="mb-0 small text-truncate">{message.subject.substring(0, 50)}...</p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <small className={selectedMessage?._id === message._id ? 'text-white-50' : 'text-muted'}>
                                            {message.message.substring(0, 50)}...
                                        </small>
                                        {message.messageStatus === 'Replied' && (
                                            <span className="badge bg-success ms-2">Replied</span>
                                        )}
                                        {message.messageStatus === 'UnRead' && (
                                            <span className="badge bg-danger ms-2">New</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}

                        {/* Pagination */}
                        {totalPages > 1 && (
                            <div className="d-flex justify-content-center align-items-center mt-3">
                                <button
                                    className="btn btn-sm btn-outline-secondary mx-1"
                                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                    disabled={currentPage === 1}
                                >
                                    <ChevronLeft size={16} />
                                </button>
                                <span className="mx-2">
                                    Page {currentPage} of {totalPages}
                                </span>
                                <button
                                    className="btn btn-sm btn-outline-secondary mx-1"
                                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                                    disabled={currentPage === totalPages}
                                >
                                    <ChevronRight size={16} />
                                </button>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default MessageList;