import React, { useState } from 'react';
import { AlertCircle, CheckCircle2, CircleSlash, AlertTriangle } from 'lucide-react';

const AdminProductStatusModal = ({
    show,
    handleClose,
    productId,
    currentStatus,
    onStatusUpdate,
    rejection,
    sellerEmail,
    productName
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(currentStatus);
    const [rejectionReason, setRejectionReason] = useState('');

    const statusOptions = ['Draft', 'Published', 'Rejected'];

    const getStatusColor = (status) => {
        switch (status) {
            case 'Draft': return 'bg-warning';
            case 'Published': return 'bg-info';
            case 'Rejected': return 'bg-danger';
            default: return 'bg-secondary';
        }
    };

    const handleStatusChange = async () => {
        setIsLoading(true);
        try {
            const payload = {
                productStatus: selectedStatus,
                sellerEmail,
                productName,
                ...(selectedStatus === 'Rejected' && { rejectionReason })
            };

            const response = await fetch(`https://server.enjoywiki.com/flip-server/admin-product-status/${productId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                throw new Error('Failed to update status');
            }

            onStatusUpdate(selectedStatus);
            handleClose();
        } catch (error) {
            console.error('Error updating status:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const isValidTransition = () => {
        if (selectedStatus === 'Rejected' && !rejectionReason.trim()) {
            return false;
        }
        return selectedStatus !== currentStatus;
    };

    return (
        <div className={`modal ${show ? 'show d-block' : ''}`}
            style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
            onClick={handleClose}>
            <div className="modal-dialog modal-dialog-centered"
                onClick={e => e.stopPropagation()}>
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <h5 className="modal-title d-flex align-items-center gap-2">
                            <AlertCircle size={20} />
                            Update Product Status
                        </h5>
                        <button type="button"
                            className="btn-close"
                            onClick={handleClose} />
                    </div>

                    <div className="modal-body">
                        <div className="mb-4">
                            <label className="form-label">Current Status</label>
                            <div className={`badge ${getStatusColor(currentStatus)} px-3 py-2`}>
                                {currentStatus}
                            </div>
                        </div>
                        {currentStatus === 'Rejected' && (
                            <div className='mb-4'>
                                <label className="form-label">
                                    Current Rejection Reason:
                                </label>
                                <p>{rejection}</p>
                            </div>
                        )}

                        <div className="mb-4">
                            <label className="form-label">New Status</label>
                            <select
                                className="form-select"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                {statusOptions.map((status) => (
                                    <option key={status} value={status}>
                                        {status}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {selectedStatus === 'Rejected' && (
                            <div className="mb-4">
                                <label className="form-label">
                                    Rejection Reason
                                    <span className="text-danger">*</span>
                                </label>
                                <textarea
                                    className="form-control"
                                    rows="3"
                                    value={rejectionReason}
                                    onChange={(e) => setRejectionReason(e.target.value)}
                                    placeholder="Please provide a reason for rejection..."
                                />
                                {selectedStatus === 'Rejected' && !rejectionReason.trim() && (
                                    <div className="text-danger mt-1 small">
                                        Rejection reason is required
                                    </div>
                                )}
                            </div>
                        )}

                        {selectedStatus === 'Inactive' && (
                            <div className="alert alert-warning d-flex align-items-center gap-2">
                                <AlertTriangle size={20} />
                                <span>
                                    Inactive products will be hidden from the marketplace
                                </span>
                            </div>
                        )}
                    </div>

                    <div className="modal-footer border-top">
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className={`btn ${selectedStatus === 'Rejected' ? 'btn-danger' : 'btn-primary'}`}
                            disabled={!isValidTransition() || isLoading}
                            onClick={handleStatusChange}
                        >
                            {isLoading ? (
                                <span className="spinner-border spinner-border-sm me-2" />
                            ) : selectedStatus === 'Rejected' ? (
                                <CircleSlash size={16} className="me-2" />
                            ) : (
                                <CheckCircle2 size={16} className="me-2" />
                            )}
                            {isLoading ? 'Updating...' : 'Confirm Update'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminProductStatusModal;