// import React from 'react';
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// const DashboardSplineChart = () => {
//   // Sample data - In production, you would fetch this from your API
//   const data = [
//     {
//       month: 'Jul',
//       totalSales: 249,
//       totalProfit: 49.8,
//       totalWithdrawn: 132
//     },
//     {
//       month: 'Aug',
//       totalSales: 199,
//       totalProfit: 39.8,
//       totalWithdrawn: 1520
//     },
//     {
//       month: 'Sep',
//       totalSales: 448,
//       totalProfit: 89.6,
//       totalWithdrawn: 10520
//     }
//   ];

//   return (
//     <div className="w-full h-96 bg-white p-4 rounded-lg shadow">
//       <h3 className="text-xl font-bold mb-4 text-gray-800">Financial Overview</h3>
//       <ResponsiveContainer width="100%" height="100%">
//         <LineChart
//           data={data}
//           margin={{
//             top: 5,
//             right: 30,
//             left: 20,
//             bottom: 5,
//           }}
//         >
//           <CartesianGrid strokeDasharray="3 3" />
//           <XAxis dataKey="month" />
//           <YAxis />
//           <Tooltip 
//             contentStyle={{
//               backgroundColor: 'white',
//               border: '1px solid #ccc',
//               borderRadius: '4px',
//               padding: '10px'
//             }}
//           />
//           <Legend />
//           <Line 
//             type="monotone" 
//             dataKey="totalSales" 
//             name="Total Sales"
//             stroke="#3b82f6" 
//             strokeWidth={2}
//             dot={{ r: 4 }}
//           />
//           <Line 
//             type="monotone" 
//             dataKey="totalProfit" 
//             name="Total Profit"
//             stroke="#10b981" 
//             strokeWidth={2}
//             dot={{ r: 4 }}
//           />
//           <Line 
//             type="monotone" 
//             dataKey="totalWithdrawn" 
//             name="Total Withdrawn"
//             stroke="#f59e0b" 
//             strokeWidth={2}
//             dot={{ r: 4 }}
//           />
//         </LineChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// export default DashboardSplineChart;
import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const DashboardSplineChart = ({ orders, withdrawals }) => {
  // Process the data for the chart
  const processData = () => {
    const monthlyData = {};

    // Process orders
    orders.forEach(order => {
      if (order.paymentStatus?.toLowerCase() === 'paid' || order.paymentStatus?.toLowerCase() === 'completed') {
        const month = order.orderDate.split('/')[1]; // Gets the month from DD/MM/YYYY
        const price = parseFloat(order.packagePrice) || 0;

        if (!monthlyData[month]) {
          monthlyData[month] = {
            month: month,
            totalSales: 0,
            totalProfit: 0,
            totalWithdrawn: 0
          };
        }

        monthlyData[month].totalSales += price;
        monthlyData[month].totalProfit += price * 0.2; // 20% profit calculation
      }
    });

    // Process withdrawals
    withdrawals.forEach(withdrawal => {
      if (withdrawal.withdrawalStatus?.toLowerCase() === 'paid' || withdrawal.withdrawalStatus?.toLowerCase() === 'completed') {
        const month = withdrawal.withdrawalDate.split('-')[1]; // Gets the month from DD-MM-YYYY
        const amount = parseFloat(withdrawal.withdrawalAmount) || 0;

        if (!monthlyData[month]) {
          monthlyData[month] = {
            month: month,
            totalSales: 0,
            totalProfit: 0,
            totalWithdrawn: 0
          };
        }

        monthlyData[month].totalWithdrawn += amount;
      }
    });

    // Convert to array and sort by month
    return Object.values(monthlyData).sort((a, b) => a.month - b.month);
  };

  const data = processData();

  // Function to format the month number to name
  const getMonthName = (monthNum) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[parseInt(monthNum) - 1];
  };

  return (
    <div className="bg-white p-4 rounded-4 mb-4">
      <h3 className="text-24 fw-bold text-dark-300 mb-4">Financial Overview</h3>
      <div style={{ width: '100%', height: 400 }}>
        <ResponsiveContainer>
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="month" 
              tickFormatter={getMonthName}
            />
            <YAxis />
            <Tooltip 
              formatter={(value) => `$${value.toFixed(2)}`}
              labelFormatter={(label) => getMonthName(label)}
            />
            <Legend />
            <Line 
              type="monotone" 
              dataKey="totalSales" 
              name="Total Sales"
              stroke="#3b82f6" 
              strokeWidth={2}
              dot={{ r: 4 }}
            />
            <Line 
              type="monotone" 
              dataKey="totalProfit" 
              name="Total Profit"
              stroke="#10b981" 
              strokeWidth={2}
              dot={{ r: 4 }}
            />
            <Line 
              type="monotone" 
              dataKey="totalWithdrawn" 
              name="Total Withdrawn"
              stroke="#f59e0b" 
              strokeWidth={2}
              dot={{ r: 4 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default DashboardSplineChart;