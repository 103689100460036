import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PaginationGlobal from '../Shared/PaginationGlobal';
import { Calendar, MapPin, Package, ShoppingCart } from 'lucide-react';

const SellerProfile = () => {
    const [seller, setSeller] = useState({});
    const [products, setProducts] = useState([]);
    const [orders, setOrders] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [activeTab, setActiveTab] = useState('products');
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const { id } = useParams();
    const [productsPage, setProductsPage] = useState(1);
    const [reviewsPage, setReviewsPage] = useState(1);

    const itemsPerPage = 9;

    useEffect(() => {
        // Fetch seller details
        fetch(`https://server.enjoywiki.com/flip-server/user/${id}`)
            .then((res) => {
                if (!res.ok) throw new Error("Network response was not ok");
                return res.json();
            })
            .then((info) => setSeller(info))
            .catch((error) => console.error("Error fetching seller data:", error));
    }, [id]);

    useEffect(() => {
        // Fetch products
        if (seller.UserEmail) {
            fetch(`https://server.enjoywiki.com/flip-server/products?sellerEmail=${seller.UserEmail}`)
                .then((res) => res.json())
                .then((info) => setProducts(info.reverse()))
                .catch((error) => console.error("Error fetching products:", error));
        }
    }, [seller]);

    useEffect(() => {
        // Fetch orders for the specific seller
        if (seller.UserEmail) {
            fetch(`https://server.enjoywiki.com/flip-server/orders?sellerEmail=${seller.UserEmail}`)
                .then((res) => res.json())
                .then((info) => {
                    // Filter orders to only include paid orders
                    const paidOrders = info.filter(order => order.paymentStatus === 'Paid');
                    setOrders(paidOrders);
                })
                .catch((error) => console.error("Error fetching seller orders:", error));
        }
    }, [seller]);
    useEffect(() => {
        // Fetch products
        if (seller.UserEmail) {
            fetch(`https://server.enjoywiki.com/flip-server/products?sellerEmail=${seller.UserEmail}`)
                .then((res) => res.json())
                .then((info) => {
                    // Filter for published products only
                    const publishedProducts = info.filter(product =>
                        product.productStatus === "Published"
                    );
                    setProducts(publishedProducts.reverse());
                })
                .catch((error) => console.error("Error fetching products:", error));
        }
    }, [seller]);

    useEffect(() => {
        // Fetch reviews
        fetch(`https://server.enjoywiki.com/flip-server/product-reviews`)
            .then((res) => res.json())
            .then((info) => setReviews(info.reverse()))
            .catch(error => console.error('Error fetching reviews:', error));
    }, []);

    // Helper functions
    const getReviewCount = (productId) => {
        return reviews.filter(review => review.productId === productId).length;
    };

    const getAverageRating = (productId) => {
        const productReviews = reviews.filter(review => review.productId === productId);
        if (productReviews.length === 0) return 0;
        const totalRating = productReviews.reduce((acc, review) => acc + review.rating, 0);
        return (totalRating / productReviews.length).toFixed(1);
    };

    // Filter and pagination logic
    const filteredProducts = products.filter(product =>
        product.productStatus === "Published" &&
        product.productName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredReviews = reviews.filter(review =>
        products.some(product =>
            product._id === review.productId &&
            product.productStatus === "Published"
        )
    );

    // Product Card Component
    const ProductCard = ({ product }) => (
        <article className="col-xl-4 col-md-6">
            <div className="service-card bg-white w-shadow">
                <div className="position-relative">

                    <Link to={`/${product.slug}/${product.productId}`}>
                        <img
                            src={product.featuredImage}
                            width={253}
                            height={200}
                            className="recently-view-card-img w-100"
                            alt={product.productName}
                        />
                    </Link>
                </div>
                <div className="service-card-content">
                    <div className="d-flex align-items-center justify-content-between">
                        <h3 className="service-card-price fw-bold">${product.price}</h3>
                        <div className="d-flex align-items-center gap-1">
                            <span className="service-card-rating">
                                {getAverageRating(product._id)} ({getReviewCount(product._id)} Reviews)
                            </span>
                        </div>
                    </div>
                    <h3 className="service-card-title fw-semibold">
                        <Link to={`/${product.slug}/${product.productId}`}>
                            {product.productName}
                        </Link>
                    </h3>
                    <div className="d-flex align-items-center service-card-author">
                        <img
                            src={seller.profileURL}
                            className="service-card-author-img"
                            alt={seller.userName}
                        />
                        <Link className="service-card-author-name">
                            {seller.userName}
                        </Link>
                    </div>
                </div>
            </div>
        </article>
    );


    // Review Card Component
    const ReviewCard = ({ review }) => {
        const product = products.find(p => p._id === review.productId);
        const timeAgo = (date) => {
            const seconds = Math.floor((new Date() - new Date(date)) / 1000);
            let interval = Math.floor(seconds / 31536000);

            if (interval > 1) return `${interval} years ago`;
            interval = Math.floor(seconds / 2592000);
            if (interval > 1) return `${interval} months ago`;
            interval = Math.floor(seconds / 86400);
            if (interval > 1) return `${interval} days ago`;
            interval = Math.floor(seconds / 3600);
            if (interval > 1) return `${interval} hours ago`;
            interval = Math.floor(seconds / 60);
            if (interval > 1) return `${interval} minutes ago`;
            return `${Math.floor(seconds)} seconds ago`;
        };

        const StarRating = () => (
            <svg xmlns="http://www.w3.org/2000/svg" width={144} height={20} viewBox="0 0 144 20" fill="none">
                {[...Array(5)].map((_, i) => (
                    <React.Fragment key={i}>
                        <path
                            d={`M${10.0311 + (i * 31)}  0C${9.60363 + (i * 31)} 0 ${9.17613 + (i * 31)} 0.2305 ${8.95488 + (i * 31)} 0.695408L${6.50617 + (i * 31)} 5.86799L${1.0275 + (i * 31)} 6.69623C${0.0450172 + (i * 31)} 6.84469 ${-0.348727 + (i * 31)} 8.10658 ${0.363762 + (i * 31)} 8.82933L${4.32745 + (i * 31)} 12.8533L${3.38997 + (i * 31)} 18.5377C${3.22122 + (i * 31)} 19.5574 ${4.25245 + (i * 31)} 20.3348 ${5.12994 + (i * 31)} 19.8543L${10.0311 + (i * 31)} 17.1742V0Z`}
                            fill={i < review.rating ? "#22BE0D" : "#D9D9D9"}
                        />
                        <path
                            d={`M${9.96888 + (i * 31)} 0C${10.3964 + (i * 31)} 0 ${10.8239 + (i * 31)} 0.2305 ${11.0451 + (i * 31)} 0.695408L${13.4938 + (i * 31)} 5.86799L${18.9725 + (i * 31)} 6.69623C${19.955 + (i * 31)} 6.84469 ${20.3487 + (i * 31)} 8.10658 ${19.6362 + (i * 31)} 8.82933L${15.6725 + (i * 31)} 12.8533L${16.61 + (i * 31)} 18.5377C${16.7788 + (i * 31)} 19.5574 ${15.7475 + (i * 31)} 20.3348 ${14.8701 + (i * 31)} 19.8543L${9.96888 + (i * 31)} 17.1742V0Z`}
                            fill={i < review.rating ? "#22BE0D" : "#D9D9D9"}
                        />
                    </React.Fragment>
                ))}
            </svg>
        );


        return (
            <>


                <div className="review-card bg-white mb-2">
                    <div>
                        <div className="d-flex justify-content-between mb-3">
                            <StarRating />
                            <div>
                                {/* <span className="text-dark-200 fs-6">{timeAgo(review.date)}</span> <br></br> */}
                                <span className="text-dark-200 fs-6">{review.date}</span>
                            </div>
                        </div>
                        <p className="text-muted mb-2">
                            Product: {product?.productName || 'Unknown Product'}
                        </p>
                        <p className="text-dark-200 fs-6">
                            Review: {review.review}
                        </p>
                        <div className="d-flex align-items-center buyer-info justify-content-between mt-4">
                            <div className="d-flex align-items-center gap-3">
                                <div>
                                    <img
                                        src={review.reviewerProfileURL || "https://raw.githubusercontent.com/Shah-Limon/em-list/master/images-dash/profile.jpg"}
                                        className="rounded-circle"
                                        width={64}
                                        alt=""
                                    />
                                </div>
                                <div>
                                    <h4 className="text-18 text-dark-300 fw-semibold">{review.reviewerName}</h4>
                                    <p className="text-dark-200 fs-6">{review.reviewerCountry}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </>
        );
    };

    return (
        <>
            <Helmet>
                <title>{`FlipBundle - Discover Digital Products by ${seller.userName || 'Our Sellers'} in ${seller.country || 'Various Locations'}`}</title>
                <meta name="description" content={`Find exclusive digital products by ${seller.userName || 'verified creators'} in ${seller.country || 'your area'}. Shop now on FlipBundle.com for unique digital goods.`} />
            </Helmet>
            <main>
                <section
                    className="w-breadcrumb-area"
                    style={{
                        background: "url('/assets/img/common/breadcrumb-bg.png') no-repeat center center/cover",
                    }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-auto">
                                <div
                                    className="position-relative z-2"

                                >
                                    <h2 className="section-title-light mb-2">Seller Details</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb w-breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page">
                                                Seller Details
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page">
                                                {seller.userName}
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-offWhite py-110">
                    <div className="container">
                        <div className="row">
                            {/* Sidebar */}
                            <div className="col-lg-3">
                                <aside className="freelancer-details-sidebar d-flex flex-column gap-4">
                                    <div className="freelancer-sidebar-card p-4 rounded-4 bg-white position-relative">
                                        <div className="job-type-badge position-absolute d-flex flex-column gap-2">
                                            <p className="job-type-badge-tertiary">Seller</p>

                                        </div>
                                        <div className="freelancer-sidebar-card-header d-flex flex-column justify-content-center align-items-center py-4">
                                            <img
                                                src={seller.profileURL || "https://raw.githubusercontent.com/Shah-Limon/em-list/master/images-dash/profile.jpg"} alt={seller.userName} width={130} height={130} style={{ objectFit: 'cover' }}
                                                className="freelancer-avatar rounded-circle mb-4"

                                            />
                                            <h3 className="fw-bold freelancer-name text-dark-300 mb-2">
                                                {seller.userName}
                                            </h3>
                                            <p className="text-dark-200 mb-1"> {seller.userRole}</p>

                                        </div>

                                        <ul className="py-4">
                                            <li className="py-1 d-flex justify-content-between align-items-center">
                                                <p className="text-dark-200 d-flex align-items-center">
                                                    <MapPin size={18} className="me-2" />
                                                    Location:
                                                </p>
                                                <p className="text-dark-300 fw-medium">{seller.city}, {seller.country}</p>
                                            </li>
                                            {seller.createdAt && (
                                                <li className="py-1 d-flex justify-content-between align-items-center">
                                                    <p className="text-dark-200 d-flex align-items-center">
                                                        <Calendar size={18} className="me-2" />
                                                        Member Since:
                                                    </p>
                                                    <p className="text-dark-300 fw-medium">
                                                        {new Date(seller.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}
                                                    </p>

                                                </li>
                                            )}
                                            <li className="py-1 d-flex justify-content-between align-items-center">
                                                <p className="text-dark-200 d-flex align-items-center">
                                                    <Package size={18} className="me-2" />
                                                    Listing:
                                                </p>
                                                <p className="text-dark-300 fw-medium">
                                                    {products.length > 0 ? (
                                                        <>
                                                            {products.length} Products
                                                        </>
                                                    ) : (
                                                        <span>Not listing Products yet.</span>
                                                    )}
                                                </p>
                                            </li>
                                            <li className="py-1 d-flex justify-content-between align-items-center">
                                                <p className="text-dark-200 d-flex align-items-center">
                                                    <ShoppingCart size={18} className="me-2" />
                                                    Sales:
                                                </p>
                                                <p className="text-dark-300 fw-medium">
                                                    {orders.length > 0 ? (
                                                        <>
                                                            {orders.length} Sales
                                                        </>
                                                    ) : (
                                                        <span>No Sales</span>
                                                    )}
                                                </p>
                                            </li>
                                        </ul>

                                    </div>
                                    <div className="freelancer-sidebar-card p-4 rounded-4 bg-white">
                                        <div className="freelancer-single-info border-bottom pb-4">
                                            <h4 className="freelancer-sidebar-title text-dark-300 fw-semibold">
                                                About
                                            </h4>
                                            <p className="text-dark-200 fs-6">
                                                {seller.aboutMe}
                                            </p>
                                        </div>
                                    </div>
                                </aside>
                            </div>

                            {/* Main Content */}
                            <div className="col-lg-9">
                                {/* Tabs */}
                                <div>
                                    <div className="bg-white d-flex gap-3 p-4 freelancer-tab mb-4">
                                        <button
                                            className={`tab-btn ${activeTab === 'products' ? 'active' : ''}`}
                                            onClick={() => {
                                                setActiveTab('products');
                                                setCurrentPage(1);
                                            }}
                                        >
                                            Products
                                        </button>
                                        {/* <button
                                            className={`tab-btn ${activeTab === 'reviews' ? 'active' : ''}`}
                                            onClick={() => {
                                                setActiveTab('reviews');
                                                setCurrentPage(1);
                                            }}
                                        >
                                            Reviews
                                        </button> */}
                                    </div>
                                </div>
                                {/* Content */}
                                {/* <div className="p-4 rounded-4">
                                    {activeTab === 'products' ? (
                                        <div className="row g-4">
                                            {getCurrentItems().map(product => (
                                                <ProductCard key={product._id} product={product} />
                                            ))}
                                        </div>
                                    ) : (
                                        <div>
                                            {getCurrentItems().map(review => (
                                                <ReviewCard key={review._id} review={review} />
                                            ))}
                                        </div>
                                    )}

                                  
                                    {totalPages > 1 && (
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={(page) => setCurrentPage(page)}
                                        />
                                    )}
                                </div> */}
                                <div className="p-4 rounded-4">
                                    {/* Products Section */}
                                    <div className="mb-5">

                                        <div className="row g-4">
                                            {products.length > 0 ? (
                                                filteredProducts
                                                    .slice((productsPage - 1) * itemsPerPage, productsPage * itemsPerPage)
                                                    .map(product => (
                                                        <ProductCard key={product._id} product={product} />
                                                    ))
                                            ) : (
                                                <div className="col-12">
                                                    <div className="bg-white rounded-4 p-5 text-center">
                                                        <div className="mb-4">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="#6c757d" viewBox="0 0 16 16">
                                                                <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
                                                            </svg>
                                                        </div>
                                                        <h4 className="fw-semibold mb-2">No Products Available</h4>
                                                        <p className="text-muted">This seller hasn't added any products to their store yet.</p>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                        {/* Products Pagination */}
                                        {Math.ceil(filteredProducts.length / itemsPerPage) > 1 && (
                                            <PaginationGlobal
                                                currentPage={productsPage}
                                                totalPages={Math.ceil(filteredProducts.length / itemsPerPage)}
                                                onPageChange={(page) => setProductsPage(page)}
                                            />
                                        )}
                                    </div>

                                    {/* Reviews Section */}
                                    <div><div class="bg-white d-flex gap-3 p-4 freelancer-tab mb-4"><button class="tab-btn active">Reviews</button></div></div>
                                    <div className="mt-5">
                                        {/* <h3 className="text-dark-300 fw-semibold mb-4">Reviews</h3> */}
                                        <div>
                                            {filteredReviews.length > 0 ? (
                                                filteredReviews
                                                    .slice((reviewsPage - 1) * itemsPerPage, reviewsPage * itemsPerPage)
                                                    .map(review => (
                                                        <ReviewCard key={review._id} review={review} />
                                                    ))
                                            ) : (
                                                <div className="bg-white rounded-4 p-5 text-center">
                                                    <div className="mb-4">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="#6c757d" viewBox="0 0 16 16">
                                                            <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                                                        </svg>
                                                    </div>
                                                    <h4 className="fw-semibold mb-2">No Reviews Yet</h4>
                                                    <p className="text-muted">This seller hasn't received any product reviews yet.</p>
                                                </div>
                                            )}
                                        </div>
                                        {/* Reviews Pagination */}
                                        {Math.ceil(filteredReviews.length / itemsPerPage) > 1 && (
                                            <PaginationGlobal
                                                currentPage={reviewsPage}
                                                totalPages={Math.ceil(filteredReviews.length / itemsPerPage)}
                                                onPageChange={(page) => setReviewsPage(page)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default SellerProfile;