// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation, Autoplay } from 'swiper/modules';
// import 'swiper/css';
// import 'swiper/css/navigation';

// const TopSeller = () => {
//     const [products, setProducts] = useState([]);
//     const [sellers, setSellers] = useState([]);
//     const [orders, setOrders] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [topSellers, setTopSellers] = useState([]);

//     // Function to check if data is expired (older than 1 hour)
//     const isDataExpired = (timestamp) => {
//         if (!timestamp) return true;
//         const now = new Date().getTime();
//         const oneHour = 60 * 60 * 1000;
//         return now - timestamp > oneHour;
//     };

//     // Function to store data with timestamp
//     const storeData = (key, data) => {
//         const dataWithTimestamp = {
//             data: data,
//             timestamp: new Date().getTime()
//         };
//         localStorage.setItem(key, JSON.stringify(dataWithTimestamp));
//     };

//     // Function to get data and check expiration
//     const getStoredData = (key) => {
//         try {
//             const storedItem = localStorage.getItem(key);
//             if (!storedItem) return null;

//             const { data, timestamp } = JSON.parse(storedItem);
//             if (isDataExpired(timestamp)) {
//                 localStorage.removeItem(key);
//                 return null;
//             }
//             return data;
//         } catch (error) {
//             console.error(`Error reading ${key} from localStorage:`, error);
//             localStorage.removeItem(key);
//             return null;
//         }
//     };

//     // Calculate sales count for each seller
//     const calculateTopSellers = (sellers, orders, products) => {
//         // Create a map to store seller sales data
//         const sellerSalesMap = new Map();

//         // Initialize all sellers with 0 sales
//         sellers.forEach(seller => {
//             sellerSalesMap.set(seller.UserEmail, {
//                 ...seller,
//                 salesCount: 0,
//                 totalEarnings: 0
//             });
//         });

//         // Count Paid sales 
//         orders.forEach(order => {
//             if (order.paymentStatus === 'Paid') {
//                 const seller = sellerSalesMap.get(order.sellerEmail);
//                 if (seller) {
//                     seller.salesCount += 1;
//                     seller.totalEarnings += parseFloat(order.packagePrice);
//                 }
//             }
//         });

//         // Convert map to array, sort by sales count, filter sellers, and limit to top 20
//         const sortedSellers = Array.from(sellerSalesMap.values())
//             .filter(seller => seller.userRole === 'Seller')
//             .sort((a, b) => b.salesCount - a.salesCount)
//             .slice(0, 10); // Limit to top 20 sellers

//         return sortedSellers;
//     };

//     useEffect(() => {
//         const fetchInitialData = async () => {
//             setLoading(true);

//             try {
//                 // First, try to get all cached data
//                 const cachedProducts = getStoredData('products');
//                 const cachedSellers = getStoredData('sellers');
//                 const cachedOrders = getStoredData('orders');

//                 let needsFresh = false;
//                 let products = cachedProducts;
//                 let sellers = cachedSellers;
//                 let orders = cachedOrders;

//                 // If any cache is missing, fetch all fresh data
//                 if (!products || !sellers || !orders) {
//                     needsFresh = true;
//                 }

//                 if (needsFresh) {
//                     // Fetch all data in parallel
//                     const [productsRes, sellersRes, ordersRes] = await Promise.all([
//                         fetch('https://server.enjoywiki.com/flip-server/products'),
//                         fetch('https://server.enjoywiki.com/flip-server/users'),
//                         fetch('https://server.enjoywiki.com/flip-server/orders')
//                     ]);

//                     const [productsData, sellersData, ordersData] = await Promise.all([
//                         productsRes.json(),
//                         sellersRes.json(),
//                         ordersRes.json()
//                     ]);

//                     products = productsData;
//                     sellers = sellersData;
//                     orders = ordersData;

//                     // Store fresh data
//                     storeData('products', products);
//                     storeData('sellers', sellers);
//                     storeData('orders', orders);
//                 }

//                 // Calculate top sellers
//                 const topSellersList = calculateTopSellers(sellers, orders, products);
                
//                 // Update state
//                 setProducts(products);
//                 setSellers(sellers);
//                 setOrders(orders);
//                 setTopSellers(topSellersList);

//             } catch (error) {
//                 console.error('Error fetching data:', error);
//                 // On error, try to use any available cached data
//                 const cachedProducts = getStoredData('products') || [];
//                 const cachedSellers = getStoredData('sellers') || [];
//                 const cachedOrders = getStoredData('orders') || [];

//                 const topSellersList = calculateTopSellers(cachedSellers, cachedOrders, cachedProducts);
                
//                 setProducts(cachedProducts);
//                 setSellers(cachedSellers);
//                 setOrders(cachedOrders);
//                 setTopSellers(topSellersList);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchInitialData();
//     }, []);

//     if (loading) {
//         return (
//             <div className="d-flex justify-content-center align-items-center" style={{ height: '30vh' }}>
//                 <div className="spinner-border" role="status">
//                     <span className="visually-hidden">Loading...</span>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <section className="top-sellers-area bg-offWhite py-110">
//             <div className="container">
//                 <div className="row justify-content-between align-items-end mb-40">
//                     <div className="col-auto">
//                         <h2 className="fw-bold section-title">Top Sellers</h2>
//                         <p className="section-desc">Our Top 10 Most Successful Sellers</p>
//                     </div>
//                     <div className="col-auto mt-3 mt-md-0">
//                         <div className="d-flex gap-3">
//                             <button className="topSellerPrev swiper-prev" tabIndex={0} aria-label="Previous slide">
//                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
//                                     <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
//                                 </svg>
//                             </button>
//                             <button className="topSellerNext swiper-next" tabIndex={0} aria-label="Next slide">
//                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
//                                     <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
//                                 </svg>
//                             </button>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="position-relative">
//                     <Swiper
//                         modules={[Navigation, Autoplay]}
//                         spaceBetween={24}
//                         slidesPerView={5}
//                         navigation={{
//                             prevEl: '.topSellerPrev',
//                             nextEl: '.topSellerNext',
//                         }}
//                         autoplay={{
//                             delay: 3000,
//                             disableOnInteraction: false,
//                             pauseOnMouseEnter: true
//                         }}
//                         speed={800}
//                         loop={true}
//                         breakpoints={{
//                             320: {
//                                 slidesPerView: 1,
//                                 spaceBetween: 20
//                             },
//                             576: {
//                                 slidesPerView: 2,
//                                 spaceBetween: 20
//                             },
//                             768: {
//                                 slidesPerView: 2,
//                                 spaceBetween: 24
//                             },
//                             992: {
//                                 slidesPerView: 3,
//                                 spaceBetween: 24
//                             },
//                             1200: {
//                                 slidesPerView: 4,
//                                 spaceBetween: 24
//                             },
//                             1400: {
//                                 slidesPerView: 5,
//                                 spaceBetween: 24
//                             }
//                         }}
//                         className="swiper topSellers"
//                     >
//                         {topSellers.map((seller, index) => (
//                             <SwiperSlide key={seller._id}>
//                                 <article className="aos-init" data-aos-duration={1000} data-aos-easing="linear">
//                                     <div className="bg-white top-seller-card position-relative">
//                                         {index === 0 && (
//                                             <div className="job-type-badge position-absolute d-flex flex-column gap-2">
//                                                 <p className="job-type-badge-tertiary">Top Seller</p>
//                                             </div>
//                                         )}
//                                         <div className="d-flex flex-column justify-content-center align-items-center">
//                                             <div className="seller-profile-img mb-4">
//                                                 <img 
//                                                     src={seller.profileURL || "https://raw.githubusercontent.com/Shah-Limon/em-list/master/images-dash/profile.jpg"} 
//                                                     alt={seller.userName} 
//                                                     className="rounded-circle"
//                                                     style={{ width: '100px', height: '100px', objectFit: 'cover' }}
//                                                 />
//                                             </div>
//                                             <h3 className="top-seller-name fw-bold">{seller.userName}</h3>
//                                             <p className="top-seller-title">{seller.country}</p>
//                                             <div className="top-seller-rating mb-4">
//                                                 <p className="d-flex align-items-center top-seller-rating">
//                                                     <span className="top-seller-review">({seller.salesCount} Sales)</span>
//                                                 </p>
//                                             </div>
//                                             <Link to={`/profile-seller/${seller._id}`} className="w-btn-primary-lg">
//                                                 View Profile
//                                                 <svg xmlns="http://www.w3.org/2000/svg" width={14} height={10} viewBox="0 0 14 10" fill="none">
//                                                     <path d="M9 9L13 5M13 5L9 1M13 5L1 5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
//                                                 </svg>
//                                             </Link>
//                                         </div>
//                                     </div>
//                                 </article>
//                             </SwiperSlide>
//                         ))}
//                     </Swiper>
//                 </div>
//             </div>
//         </section>
//     );
// };

// export default TopSeller;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

const TopSeller = () => {
    const [topSellers, setTopSellers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTopSellers = async () => {
            try {
                const response = await fetch('https://server.enjoywiki.com/flip-server/api/top-sellers');
                if (!response.ok) {
                    throw new Error('Failed to fetch top sellers');
                }
                const data = await response.json();
                setTopSellers(data);
            } catch (err) {
                console.error('Error:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTopSellers();
    }, []);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '30vh' }}>
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="alert alert-danger" role="alert">
                Error loading top sellers: {error}
            </div>
        );
    }

    return (
        <section className="top-sellers-area bg-offWhite py-110">
            <div className="container">
                <div className="row justify-content-between align-items-end mb-40">
                    <div className="col-auto">
                        <h2 className="fw-bold section-title">Top Sellers</h2>
                        <p className="section-desc">Our Top 10 Most Successful Sellers</p>
                    </div>
                    <div className="col-auto mt-3 mt-md-0">
                        <div className="d-flex gap-3">
                            <button className="topSellerPrev swiper-prev" tabIndex={0} aria-label="Previous slide">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                </svg>
                            </button>
                            <button className="topSellerNext swiper-next" tabIndex={0} aria-label="Next slide">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="position-relative">
                    <Swiper
                        modules={[Navigation, Autoplay]}
                        spaceBetween={24}
                        slidesPerView={5}
                        navigation={{
                            prevEl: '.topSellerPrev',
                            nextEl: '.topSellerNext',
                        }}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true
                        }}
                        speed={800}
                        loop={true}
                        breakpoints={{
                            320: { slidesPerView: 1, spaceBetween: 20 },
                            576: { slidesPerView: 2, spaceBetween: 20 },
                            768: { slidesPerView: 2, spaceBetween: 24 },
                            992: { slidesPerView: 3, spaceBetween: 24 },
                            1200: { slidesPerView: 4, spaceBetween: 24 },
                            1400: { slidesPerView: 5, spaceBetween: 24 }
                        }}
                        className="swiper topSellers"
                    >
                        {topSellers.map((seller, index) => (
                            <SwiperSlide key={seller._id}>
                                <article className="aos-init" data-aos-duration={1000} data-aos-easing="linear">
                                    <div className="bg-white top-seller-card position-relative">
                                        {index === 0 && (
                                            <div className="job-type-badge position-absolute d-flex flex-column gap-2">
                                                <p className="job-type-badge-tertiary">Top Seller</p>
                                            </div>
                                        )}
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <div className="seller-profile-img mb-4">
                                                <img 
                                                    src={seller.profileURL || "https://raw.githubusercontent.com/Shah-Limon/em-list/master/images-dash/profile.jpg"} 
                                                    alt={seller.userName} 
                                                    className="rounded-circle"
                                                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                                />
                                            </div>
                                            <h3 className="top-seller-name fw-bold">{seller.userName}</h3>
                                            <p className="top-seller-title">{seller.country}</p>
                                            <div className="top-seller-rating mb-4">
                                                <p className="d-flex align-items-center top-seller-rating">
                                                    <span className="top-seller-review">({seller.salesCount} Sales)</span>
                                                </p>
                                            </div>
                                            <Link to={`/profile-seller/${seller._id}`} className="w-btn-primary-lg">
                                                View Profile
                                                <svg xmlns="http://www.w3.org/2000/svg" width={14} height={10} viewBox="0 0 14 10" fill="none">
                                                    <path d="M9 9L13 5M13 5L9 1M13 5L1 5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </Link>
                                        </div>
                                    </div>
                                </article>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default TopSeller;