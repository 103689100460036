import React from 'react';

const YouTubeEmbed = ({ youtubeLink }) => {
  // Function to convert YouTube URL to embed URL
  const getEmbedUrl = (url) => {
    if (!url) return null;
    
    // Match different YouTube URL patterns
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    
    // Return embed URL if valid, null if not
    return match && match[2].length === 11 
      ? `https://www.youtube.com/embed/${match[2]}?rel=0&modestbranding=1`
      : null;
  };

  const embedUrl = getEmbedUrl(youtubeLink);

  if (!embedUrl) return null;

  return (
    <div style={{
      width: '100%',
      maxWidth: '100%',
      margin: '20px auto',
      padding: '20px'
    }}>
      <div style={{
        position: 'relative',
        width: '100%',
        paddingTop: '56.25%', // 16:9 Aspect Ratio
        backgroundColor: '#f0f0f0',
        borderRadius: '8px',
        overflow: 'hidden'
      }}>
        <iframe
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: '100%',
            height: '100%',
            border: 'none',
            borderRadius: '8px'
          }}
          src={embedUrl}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  );
};

export default YouTubeEmbed;