import React from 'react';

const EmptyMessage = () => {
    const styles = {
        container: {
            height: '100%',
            minHeight: '400px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '2rem',
            background: '#fafafa'
        },
        content: {
            textAlign: 'center',
            transition: 'transform 0.3s ease',
            padding: '2rem',
            borderRadius: '12px',
            background: 'white',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
        },
        svg: {
            width: '237px',
            height: '189px',
            maxWidth: '100%',
            marginBottom: '1.5rem',
            transition: 'transform 0.3s ease',
        },
        title: {
            fontSize: '1.5rem',
            fontWeight: '600',
            color: '#2d3748',
            marginBottom: '0.75rem',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif'
        },
        description: {
            fontSize: '1.125rem',
            color: '#718096',
            marginBottom: '0',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif'
        }
    };

    return (
        <div id="chatInnerBody" style={styles.container}>
            <div style={styles.content} 
                 onMouseEnter={(e) => {
                     e.currentTarget.style.transform = 'translateY(-5px)';
                 }}
                 onMouseLeave={(e) => {
                     e.currentTarget.style.transform = 'translateY(0)';
                 }}>
                <svg 
                    style={styles.svg}
                    viewBox="0 0 237 189" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg"
                    onMouseEnter={(e) => {
                        e.currentTarget.style.transform = 'rotate(2deg)';
                    }}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.transform = 'rotate(0)';
                    }}>
                    <g clipPath="url(#clip0_2787_16733)">
                        <path d="M146.596 100.405C146.596 117.677 140.642 133.557 130.662 146.078C129.545 147.503 128.328 148.894 127.11 150.218C113.78 164.639 94.6996 173.699 73.5552 173.699C66.9244 173.699 60.5303 172.816 54.4407 171.154L19.7978 188.086L23.7222 153.916C19.0535 149.505 14.9262 144.517 11.5431 139.054C4.54004 127.822 0.51416 114.589 0.51416 100.405C0.51416 97.962 0.615653 95.5528 0.85247 93.1775C3.62661 64.7084 22.6396 41.0238 48.4865 31.5567C55.8955 28.8421 63.8796 27.2812 72.202 27.1455C72.6418 27.1455 73.0816 27.1455 73.5552 27.1455C76.3632 27.1455 79.1373 27.3152 81.8776 27.6206C90.4369 28.5707 98.5225 31.0477 105.931 34.7124C106.202 34.8481 106.473 34.9838 106.743 35.1535C129.918 47.0298 145.886 70.918 146.562 98.6067C146.596 99.1835 146.596 99.7943 146.596 100.405Z" fill="#22BE0D"/>
                        <path opacity="0.2" d="M146.597 100.406C146.597 117.677 140.643 133.558 130.663 146.079C103.733 143.669 79.4766 116.592 79.4766 87.2741C79.4766 66.847 90.8099 46.4876 106.744 35.1543C129.919 47.0306 145.887 70.9188 146.563 98.6075C146.597 99.1843 146.597 99.7951 146.597 100.406Z" fill="black"/>
                        <path opacity="0.1" d="M48.4865 31.5571C21.2187 59.4494 11.8475 105.258 11.5092 139.054C4.54005 127.823 0.51416 114.589 0.51416 100.406C0.51416 68.7807 20.5083 41.8046 48.4865 31.5571Z" fill="white"/>
                        <path opacity="0.1" d="M81.8777 27.587C73.8259 55.3435 30.8268 85.645 0.852539 93.1779C3.62668 64.7088 22.6397 41.0241 48.4865 31.5571C55.8955 28.8425 63.8796 27.2816 72.202 27.1459C72.6419 27.1459 73.0816 27.1459 73.5553 27.1459C76.3633 27.112 79.1374 27.2816 81.8777 27.587Z" fill="white"/>
                        <path d="M236.046 73.2596C236.046 80.9622 234.862 88.3933 232.663 95.3833C231.512 99.082 230.058 102.611 228.332 106.004C224.442 113.809 219.164 120.832 212.872 126.771L216.796 160.974L182.187 143.974C176.097 145.637 169.669 146.519 163.039 146.519C122.712 146.519 89.9976 113.707 89.9976 73.2596C89.9976 43.8404 107.285 18.4591 132.252 6.78644C133.2 6.34532 134.181 5.9042 135.162 5.49701C143.721 1.96807 153.16 0 163.005 0C203.365 0 236.046 32.8124 236.046 73.2596Z" fill="#13544E"/>
                        <path opacity="0.1" d="M236.046 73.2596C236.046 80.9622 234.862 88.3933 232.663 95.3834C231.512 99.082 230.058 102.611 228.332 106.004C187.938 87.6468 147.206 42.8903 135.128 5.49701C143.721 1.96807 153.16 0 163.005 0C203.365 0 236.046 32.8124 236.046 73.2596Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_2787_16733">
                            <rect width="235.531" height="188.086" fill="white" transform="translate(0.51416)"/>
                        </clipPath>
                    </defs>
                </svg>
                <h2 style={styles.title}>Select  Messages to get started</h2>
           
            </div>
        </div>
    );
};

export default EmptyMessage;