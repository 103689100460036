import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { FileSliders } from "lucide-react";
import { Helmet } from "react-helmet";
import auth from "../../firebase.init";
import LoadingAnimations from "../../components/Shared/LoadingAnimations";
import Pagination from "../../components/Shared/Pagination";
import UserInfoDisplay from "./UserInfoDisplay";
import OrderFilters from "./OrderFilters";
import DisputeManagement from "./DisputeManagement";

const AdminAllOrders = () => {
    const [orders, setOrders] = useState([]);
    const [selectedDateFilter, setSelectedDateFilter] = useState("all");
    const [customStartDate, setCustomStartDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState("all");
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("all");
    const [loading, setLoading] = useState(true);
    const [user] = useAuthState(auth);
    const [activeTab, setActiveTab] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [users, setUsers] = useState([]);
    const [searchText, setSearchText] = useState({
        productName: '',
        orderId: '',
        userSearch: ''
    });
    const [dateRange, setDateRange] = useState({
        startDate: '',
        endDate: ''
    });

    // Function to fetch orders from server
    const fetchOrders = () => {
        fetch(`https://server.enjoywiki.com/flip-server/orders`)
            .then((res) => res.json())
            .then((info) => {
                setOrders(info.reverse());
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching orders:", error);
                setLoading(false);
            });
    };

    // Fetch users data
    useEffect(() => {
        fetch('https://server.enjoywiki.com/flip-server/users')
            .then((res) => res.json())
            .then((info) => {
                setUsers(info);
            })
            .catch((error) => {
                console.error('Error fetching users:', error);
            });
    }, []);

    useEffect(() => {
        fetchOrders();
    }, [user]);
    // Reset pagination when filters change
    useEffect(() => {
        setCurrentPage(1);
    }, [selectedDateFilter, customStartDate, customEndDate, selectedOrderStatus, selectedPaymentStatus, activeTab]);

    const filterOrders = () => {
        return orders.filter((order) => {
            // Product name filter
            const matchesProductName = order.packageName
                .toLowerCase()
                .includes(searchText.productName.toLowerCase());

            // Order ID filter
            const matchesOrderId = order.orderId
                .toLowerCase()
                .includes(searchText.orderId.toLowerCase());

            // User search filter (seller or buyer)
            const matchesUser = searchText.userSearch === '' ||
                order.sellerEmail?.toLowerCase().includes(searchText.userSearch.toLowerCase()) ||
                order.customerEmail?.toLowerCase().includes(searchText.userSearch.toLowerCase()) ||
                order.customerName?.toLowerCase().includes(searchText.userSearch.toLowerCase());

            // Date range filter
            const parseDateString = (dateStr) => {
                if (!dateStr) return null;
                const [day, month, year] = dateStr.split('/');
                return new Date(year, parseInt(month) - 1, day); // month is 0-based in Date constructor
            };

            const orderDate = parseDateString(order.orderDate);
            const startDate = dateRange.startDate ? parseDateString(dateRange.startDate) : null;
            const endDate = dateRange.endDate ? parseDateString(dateRange.endDate) : null;

            const isWithinDateRange =
                (!startDate || orderDate >= startDate) &&
                (!endDate || orderDate <= endDate);

            // Tab filtering (if you're using tabs)
            const matchesTab =
                activeTab === 'all' ||
                (activeTab === 'active' && order.paymentStatus === 'Paid') ||
                (activeTab === 'pending' && order.paymentStatus === 'pending') ||
                (activeTab === 'completed' && order.paymentStatus === 'Completed') ||
                (activeTab === 'close' && order.paymentStatus === 'Cancelled') ||
                (activeTab === 'Refunded' && order.paymentStatus === 'Refunded');

            return matchesProductName && matchesOrderId && matchesUser &&
                isWithinDateRange && matchesTab;
        });
    };
    const filteredOrders = filterOrders();

    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentOrders = filteredOrders.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    if (loading) return <LoadingAnimations type="pulse" />;
    return (
        <main className="dashboard-main min-vh-100">
            <Helmet>
                <title>Seller Orders | FlipBundle - Manage Your Digital Product Orders</title>
                <meta name="description" content="View and manage your orders as a seller on FlipBundle. Track customer purchases, process orders, and ensure timely delivery of your digital products." />
            </Helmet>
            <div className="d-flex flex-column gap-4">
                {/* Header */}
                <div className="d-flex flex-column flex-md-row gap-4 align-items-md-center justify-content-between">
                    <div>
                        <h3 className="text-24 fw-bold text-dark-300 mb-2">All Orders</h3>
                        <ul className="d-flex align-items-center gap-2">
                            <li className="text-dark-200 fs-6">Admin Dashboard</li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width={5} height={11} viewBox="0 0 5 11" fill="none">
                                    <path d="M1 10L4 5.5L1 1" stroke="#5B5B5B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </li>
                            <li className="text-lime-300 fs-6">All Orders</li>
                        </ul>
                    </div>
                </div>
                {/* Tabs */}
                <div>
                    <nav>
                        <div className="d-flex flex-wrap gap-3 align-items-center" role="tablist">
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'all' ? 'active' : ''}`}
                                onClick={() => setActiveTab('all')}
                            >
                                All({orders.length})
                            </button>
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'active' ? 'active' : ''}`}
                                onClick={() => setActiveTab('active')}
                            >
                                Paid({orders.filter(order => order.paymentStatus === 'Paid').length})
                            </button>
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'pending' ? 'active' : ''}`}
                                onClick={() => setActiveTab('pending')}
                            >
                                Pending({orders.filter(order => order.paymentStatus === 'pending').length})
                            </button>
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'close' ? 'active' : ''}`}
                                onClick={() => setActiveTab('close')}
                            >
                                Cancelled({orders.filter(order => order.paymentStatus === 'Cancelled').length})
                            </button>
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'Refunded' ? 'active' : ''}`}
                                onClick={() => setActiveTab('Refunded')}
                            >
                                Refunded({orders.filter(order => order.paymentStatus === 'Refunded').length})
                            </button>
                        </div>
                    </nav>
                    <OrderFilters
                        searchText={searchText}
                        setSearchText={setSearchText}
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        onFilter={() => setCurrentPage(1)} // Reset to first page when filtering
                    />
                    {/* Table Content */}
                    <div className="tab-content mt-4">
                        <div className="overflow-x-auto">
                            <table className="w-100 dashboard-table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="ps-4">Product Name</th>
                                        <th scope="col">Order ID</th>
                                        <th scope="col">Seller Info</th>
                                        <th scope="col">Customer Info</th>
                                        <th scope="col">Status</th>
                                        <th scope="col" className="text-center">Access/View</th>
                                        <th scope="col" className="text-center">Refund</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentOrders.map((order, index) => (
                                        <tr key={order._id}>

                                            <td>
                                                <div className="d-flex gap-3 align-items-center project-name">
                                                    <div className="order-img">
                                                        <img src={order.featuredImage || "/assets/img/dashboard/orders/o-1.png"} alt="" />
                                                    </div>
                                                    <div>
                                                        <Link to={`/${order.productSlug}/${order.productId}`} className="text-dark-200 project-link">
                                                            {order.packageName}
                                                        </Link>
                                                        <div>
                                                            <p className="text-dark-200 mb-0">Amount - ${order?.packagePrice || 'N/A'} USD</p>
                                                            <p className="text-dark-200 mb-0">Order Date - {order?.orderDate || 'N/A'}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-dark-200">
                                                {order.orderId}
                                            </td>
                                            <td className="text-dark-200">
                                                <UserInfoDisplay
                                                    email={order.sellerEmail}
                                                    users={users}
                                                />
                                            </td>
                                            <td className="text-dark-200">
                                                <UserInfoDisplay
                                                    email={order.customerEmail}
                                                    users={users}
                                                />
                                            </td>
                                            <td>
                                                <span className={`status-badge ${order.paymentStatus.toLowerCase()}`}>
                                                    {order.paymentStatus}
                                                </span>
                                            </td>

                                            <td>
                                                <div className="d-flex justify-content-end gap-2">
                                                    {order.paymentStatus === 'Paid' && (
                                                        <Link to={`/admin/update-order/${order._id}`} className="dashboard-action-btn">
                                                            <FileSliders />
                                                        </Link>
                                                    )}
                                                    <button
                                                        className="dashboard-action-btn"
                                                        data-bs-toggle="modal" data-bs-target={`#orderDetails${order._id}`}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} viewBox="0 0 48 48" fill="none">
                                                            <circle cx={24} cy={24} r={24} fill="#EDEBE7" />
                                                            <path d="M34.3187 21.6619C35.6716 23.0854 35.6716 25.248 34.3187 26.6714C32.0369 29.0721 28.1181 32.3333 23.6667 32.3333C19.2152 32.3333 15.2964 29.0721 13.0147 26.6714C11.6618 25.248 11.6618 23.0854 13.0147 21.6619C15.2964 19.2612 19.2152 16 23.6667 16C28.1181 16 32.0369 19.2612 34.3187 21.6619Z"
                                                                stroke="#5B5B5B" strokeWidth="1.5" />
                                                            <circle cx="23.667" cy="24.167" r="3.5" stroke="#5B5B5B" strokeWidth="1.5" />
                                                        </svg>
                                                    </button>


                                                </div>
                                            </td>
                                            <td>
                                                {(order.paymentStatus === 'Paid' || order.paymentStatus === 'Refunded') && (
                                                    <button
                                                        className="btn btn-link p-0 position-relative"
                                                        data-bs-toggle="modal"
                                                        data-bs-target={`#disputeModal${order._id}`}
                                                        style={{
                                                            color: '#dc3545',
                                                            transition: 'all 0.2s ease',
                                                        }}
                                                        title="Process Refund"
                                                    >
                                                        <img
                                                            src="/assets/img/common/cashback.png"
                                                            className="img-fluid rounded-top"
                                                            alt=""
                                                        />
                                                    </button>
                                                )}

                                                <div
                                                    className="modal fade"
                                                    id={`disputeModal${order._id}`}
                                                    tabIndex="-1"
                                                    aria-hidden="true"
                                                >
                                                    <div className="modal-dialog modal-lg modal-dialog-centered">
                                                        <div
                                                            className="modal-content"
                                                            style={{
                                                                borderRadius: '12px',
                                                                border: 'none',
                                                                boxShadow: '0 10px 25px rgba(0,0,0,0.1)'
                                                            }}
                                                        >
                                                            <div
                                                                className="modal-header"
                                                                style={{
                                                                    borderBottom: '1px solid #e9ecef',
                                                                    padding: '1rem 1.5rem'
                                                                }}
                                                            >
                                                                <h5
                                                                    className="modal-title"
                                                                    style={{
                                                                        fontSize: '1.25rem',
                                                                        fontWeight: '600',
                                                                        color: '#344054'
                                                                    }}
                                                                >
                                                                    Process Refund
                                                                </h5>
                                                                <button
                                                                    type="button"
                                                                    className="btn-close"
                                                                    data-bs-dismiss="modal"
                                                                    aria-label="Close"
                                                                    style={{
                                                                        transition: 'opacity 0.2s ease'
                                                                    }}
                                                                ></button>
                                                            </div>
                                                            <div
                                                                className="modal-body"
                                                                style={{
                                                                    padding: '1.5rem'
                                                                }}
                                                            >
                                                                <DisputeManagement
                                                                    order={order}
                                                                    onStatusUpdate={() => {
                                                                        document.querySelector(`#disputeModal${order._id} .btn-close`).click();
                                                                        fetchOrders();
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>


                                            {/* Order Details Modal */}
                                            <div className="modal fade" id={`orderDetails${order._id}`} tabIndex="-1" aria-hidden="true">
                                                <div className="modal-dialog modal-lg modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">Order Details</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <table className="table table-bordered">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Order ID</td>
                                                                        <td>{order.orderId}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Order Date</td>
                                                                        <td>{order.orderDate}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Product Name</td>
                                                                        <td>{order.packageName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Price</td>
                                                                        <td>${order.packagePrice}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Payment Status</td>
                                                                        <td>{order.paymentStatus}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Customer Name</td>
                                                                        <td>{order.customerName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Customer Note</td>
                                                                        <td>{order.customerNote || 'No note provided'}</td>
                                                                    </tr>
                                                                    {order.accessLink && (
                                                                        <tr>
                                                                            <td>Access Link</td>
                                                                            <td>{order.accessLink}</td>
                                                                        </tr>
                                                                    )}
                                                                    {order.guideLine && (
                                                                        <tr>
                                                                            <td>Guide Line</td>
                                                                            <td>{order.guideLine}</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                            {order.paymentStatus === 'Paid' && (
                                                                <div className="mt-3">
                                                                    <h6>Platform Fee</h6>
                                                                    <p className="text-danger">-${order.packagePrice * 0.2} USD (20%)</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Empty State */}
                        {currentOrders.length === 0 && (
                            <div className="row justify-content-center">
                                <div className="col-7">
                                    <div className="bg-white p-5 rounded-3 text-center">
                                        <img
                                            src="https://workzone.mamunuiux.com/uploads/website-images/empty-2024-05-17-11-50-01-3653.png"
                                            className="img-fluid mb-4"
                                            alt="Empty orders"
                                        />
                                        <h3 className="text-24 fw-bold text-dark-300 mb-2">
                                            No Orders Found
                                        </h3>
                                        <p className="fs-6 text-dark-200">
                                            No orders match your current filter criteria
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* Pagination */}
                        {filteredOrders.length > itemsPerPage && (
                            <div className="pagination-container mt-4 d-flex justify-content-center">
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={Math.ceil(orders.length / itemsPerPage)}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default AdminAllOrders;