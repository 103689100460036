import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import slugify from 'slugify';
import toast from 'react-hot-toast';
import auth from '../../firebase.init';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const EditBlogPost = () => {
    const { id } = useParams();
    const [user] = useAuthState(auth);
    const navigate = useNavigate();

    // State management
    const [blog, setBlog] = useState(null);
    const [blogTitle, setBlogTitle] = useState('');
    const [blogTitleError, setBlogTitleError] = useState('');
    const [charCount, setCharCount] = useState(0);
    const [blogSlug, setBlogSlug] = useState('');
    const [blogContent, setBlogContent] = useState('');
    const [featuredImage, setFeaturedImage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [categories, setCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
            ['clean'],
        ],
    };

    // Fetch blog data
    useEffect(() => {
        const fetchBlog = async () => {
            try {
                setLoading(true);
                const response = await fetch(`https://server.enjoywiki.com/flip-server/blog/${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch blog post');
                }
                const data = await response.json();

                setBlog(data);
                setBlogTitle(data.blogTitle);
                setBlogSlug(data.slug);
                setBlogContent(data.blogContent);
                setFeaturedImage(data.featuredImage);
                setSelectedCategory(data.categorySlug);
                setSearchTerm(data.category);
                setCharCount(data.blogTitle.length);
                setError(null);
            } catch (err) {
                setError('Failed to load blog post');
                toast.error('Failed to load blog post');
            } finally {
                setLoading(false);
            }
        };

        if (id) {
            fetchBlog();
        }
    }, [id]);

    // Fetch categories
    useEffect(() => {
        fetch('https://server.enjoywiki.com/flip-server/blog-categories')
            .then(res => res.json())
            .then(data => setCategories(data.reverse()))
            .catch(error => {
                console.error('Error fetching categories:', error);
                toast.error('Failed to load categories');
            });
    }, []);

    const handleBlogTitleChange = (event) => {
        const value = event.target.value;
        setBlogTitle(value);
        setCharCount(value.length);

        if (value.length > 70) {
            setBlogTitleError('Blog title cannot exceed 70 characters');
        } else {
            setBlogTitleError('');
        }

        setBlogSlug(slugify(value, { lower: true, remove: /[*+~.()'"!:@]/g }));
    };

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        if (file.size > 2 * 1024 * 1024) {
            toast.error('File size exceeds 2 MB');
            return;
        }

        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await fetch('https://server.enjoywiki.com/image-server/upload', {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            if (data.file?.url) {
                setFeaturedImage(data.file.url);
                toast.success('Image uploaded successfully');
            }
        } catch (error) {
            toast.error('Failed to upload image');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (blogTitleError || isSubmitting) return;

        setIsSubmitting(true);
        try {
            const selectedCategoryObj = categories.find(cat => cat.slug === selectedCategory);

            const updatedBlog = {
                blogStatus: blog.blogStatus,
                authorEmail: blog.authorEmail,
                blogTitle,
                slug: blogSlug,
                category: selectedCategoryObj?.categoryName || blog.category,
                categorySlug: selectedCategory || blog.categorySlug,
                blogContent,
                featuredImage,
                authorName: blog.authorName
            };

            const response = await fetch(`https://server.enjoywiki.com/flip-server/blog/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedBlog)
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Failed to update blog post');
            }

            toast.success('Blog post updated successfully');
            navigate('/seller/blog');

        } catch (error) {
            console.error('Update error:', error);
            toast.error(error.message || 'Failed to update blog post');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen flex flex-col items-center justify-center">
                <h2 className="text-xl text-red-600 mb-4">{error}</h2>
                <button
                    className="btn btn-primary"
                    onClick={() => navigate('/blogs')}
                >
                    Return to Blogs
                </button>
            </div>
        );
    }

    return (
        <main className="dashboard-main min-vh-100">
            <div className="d-flex flex-column gap-4 pb-110">
                <Helmet>
                    <title>Edit Blog Post | Your Site</title>
                </Helmet>

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <form onSubmit={handleSubmit} className="bg-white p-4 rounded shadow">
                                <h2 className="text-24 fw-bold mb-4">Edit Blog Post</h2>

                                {/* Title Input */}
                                <div className="mb-4">
                                    <label className="form-label">
                                        Blog Title
                                        <span className="text-sm text-gray-500 ms-2">
                                            ({charCount}/70 characters)
                                        </span>
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${blogTitleError ? 'is-invalid' : ''}`}
                                        value={blogTitle}
                                        onChange={handleBlogTitleChange}
                                        required
                                    />
                                    {blogTitleError && (
                                        <div className="invalid-feedback">{blogTitleError}</div>
                                    )}
                                </div>

                                {/* Category Selector */}
                                <div className="mb-4">
                                    <label className="form-label">Category</label>
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={searchTerm}
                                            onChange={(e) => {
                                                setSearchTerm(e.target.value);
                                                setIsDropdownOpen(true);
                                            }}
                                            onClick={() => setIsDropdownOpen(true)}
                                        />
                                        {isDropdownOpen && (
                                            <div className="position-absolute w-100 bg-white border rounded mt-1 shadow-sm" style={{ maxHeight: '200px', overflowY: 'auto', zIndex: 1000 }}>
                                                {categories
                                                    .filter(cat => cat.categoryName.toLowerCase().includes(searchTerm.toLowerCase()))
                                                    .map(category => (
                                                        <div
                                                            key={category._id}
                                                            className="p-2 cursor-pointer hover:bg-light"
                                                            onClick={() => {
                                                                setSelectedCategory(category.slug);
                                                                setSearchTerm(category.categoryName);
                                                                setIsDropdownOpen(false);
                                                            }}
                                                        >
                                                            {category.categoryName}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Blog Content Editor */}
                                <div className="mb-4">
                                    <label className="form-label">Blog Content</label>
                                    <ReactQuill
                                        value={blogContent}
                                        required
                                        onChange={setBlogContent}
                                        modules={quillModules}
                                        className="bg-white"
                                    />
                                </div>

                                {/* Featured Image */}
                                <div className="mb-4">
                                    <label className="form-label">Featured Image</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        onChange={handleImageUpload}
                                        accept="image/*"
                                    />
                                    {featuredImage && (
                                        <div className="mt-2">
                                            <img
                                                src={featuredImage}
                                                alt="Preview"
                                                className="img-fluid"
                                                style={{ maxHeight: '200px' }}
                                            />
                                        </div>
                                    )}
                                </div>

                                {/* Submit Button */}
                                <div className="text-center">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={isSubmitting || !!blogTitleError}
                                    >
                                        {isSubmitting ? 'Updating...' : 'Update Blog Post'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default EditBlogPost;