// DeleteConfirmationModal.js
import React from 'react';
import { X, AlertCircle } from 'lucide-react';

const DeleteConfirmationModal = ({ show, handleClose, handleConfirm, isDeleting, productName }) => {
    if (!show) return null;

    return (
        <>
            {/* Modal Backdrop */}
            <div 
                className="modal-backdrop fade show" 
                style={{ 
                    backgroundColor: 'rgba(33, 37, 41, 0.5)',
                    backdropFilter: 'blur(4px)'
                }} 
            />

            {/* Modal */}
            <div 
                className="modal fade show" 
                style={{ display: 'block' }}
                aria-modal="true"
                role="dialog"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content shadow-lg border-0">
                        {/* Header */}
                        <div className="modal-header border-bottom border-light">
                            <div className="d-flex align-items-center gap-2">
                                <AlertCircle className="text-danger" size={24} />
                                <h5 className="modal-title mb-0">Delete Confirmation</h5>
                            </div>
                            <button 
                                type="button" 
                                className="btn-close" 
                                onClick={handleClose}
                                disabled={isDeleting}
                                aria-label="Close"
                            />
                        </div>

                        {/* Body */}
                        <div className="modal-body p-4">
                            <p className="text-dark mb-0">
                                Are you sure you want to delete <strong className="text-danger">{productName}</strong>? 
                                <br />
                                <small className="text-muted">This action cannot be undone.</small>
                            </p>
                        </div>

                        {/* Footer */}
                        <div className="modal-footer border-top border-light bg-light bg-opacity-50">
                            <button 
                                type="button" 
                                className="btn btn-light" 
                                onClick={handleClose}
                                disabled={isDeleting}
                            >
                                Cancel
                            </button>
                            <button 
                                type="button" 
                                className="btn btn-danger" 
                                onClick={handleConfirm}
                                disabled={isDeleting}
                            >
                                {isDeleting ? (
                                    <div className="d-flex align-items-center gap-2">
                                        <span 
                                            className="spinner-border spinner-border-sm" 
                                            role="status" 
                                            aria-hidden="true"
                                        />
                                        <span>Deleting...</span>
                                    </div>
                                ) : (
                                    <div className="d-flex align-items-center gap-2">
                                        <X size={18} />
                                        <span>Delete Product</span>
                                    </div>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DeleteConfirmationModal;