import React from 'react';
import { Search } from 'lucide-react';

const OrderFilters = ({ 
  searchText,
  setSearchText,
  dateRange,
  setDateRange,
  onFilter
}) => {
  // Function to convert date from DD/MM/YYYY to YYYY-MM-DD for input field
  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  };

  // Function to convert date from YYYY-MM-DD to DD/MM/YYYY for state
  const formatDateForState = (dateString) => {
    if (!dateString) return '';
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="bg-white shadow-sm rounded-3 p-4 mb-4 mt-4" style={{ border: '1px solid #E5E7EB' }}>
      <div className="row g-4">
        {/* Product Name Search */}
        <div className="col-12 col-md-6 col-lg-3">
          <div className="form-group">
            <label className="form-label fw-medium mb-2" style={{ color: '#374151', fontSize: '0.875rem' }}>
              Product Name
            </label>
            <div className="position-relative">
              <input
                type="text"
                className="form-control ps-5"
                placeholder="Search products..."
                value={searchText.productName}
                onChange={(e) => setSearchText({
                  ...searchText,
                  productName: e.target.value
                })}
                style={{
                  height: '42px',
                  borderRadius: '0.5rem',
                  border: '1px solid #E5E7EB',
                  fontSize: '0.875rem'
                }}
              />
              <Search className="position-absolute" style={{ left: '12px', top: '10px', color: '#9CA3AF' }} />
            </div>
          </div>
        </div>

        {/* Order ID Search */}
        <div className="col-12 col-md-6 col-lg-3">
          <div className="form-group">
            <label className="form-label fw-medium mb-2" style={{ color: '#374151', fontSize: '0.875rem' }}>
              Order ID
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter order ID..."
              value={searchText.orderId}
              onChange={(e) => setSearchText({
                ...searchText,
                orderId: e.target.value
              })}
              style={{
                height: '42px',
                borderRadius: '0.5rem',
                border: '1px solid #E5E7EB',
                fontSize: '0.875rem'
              }}
            />
          </div>
        </div>

        {/* User Search */}
        <div className="col-12 col-md-6 col-lg-3">
          <div className="form-group">
            <label className="form-label fw-medium mb-2" style={{ color: '#374151', fontSize: '0.875rem' }}>
              Seller/Buyer Name or Email
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Search users..."
              value={searchText.userSearch}
              onChange={(e) => setSearchText({
                ...searchText,
                userSearch: e.target.value
              })}
              style={{
                height: '42px',
                borderRadius: '0.5rem',
                border: '1px solid #E5E7EB',
                fontSize: '0.875rem'
              }}
            />
          </div>
        </div>

        {/* Date Range */}
        <div className="col-12 col-md-6 col-lg-3">
          <div className="form-group">
            <label className="form-label fw-medium mb-2" style={{ color: '#374151', fontSize: '0.875rem' }}>
              Date Range
            </label>
            <div className="d-flex gap-2">
              <input
                type="date"
                className="form-control"
                value={formatDateForInput(dateRange.startDate)}
                onChange={(e) => setDateRange({
                  ...dateRange,
                  startDate: formatDateForState(e.target.value)
                })}
                style={{
                  height: '42px',
                  borderRadius: '0.5rem',
                  border: '1px solid #E5E7EB',
                  fontSize: '0.875rem'
                }}
              />
              <input
                type="date"
                className="form-control"
                value={formatDateForInput(dateRange.endDate)}
                onChange={(e) => setDateRange({
                  ...dateRange,
                  endDate: formatDateForState(e.target.value)
                })}
                style={{
                  height: '42px',
                  borderRadius: '0.5rem',
                  border: '1px solid #E5E7EB',
                  fontSize: '0.875rem'
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end gap-2 mt-4">
        <button
          className="btn"
          onClick={() => {
            setSearchText({
              productName: '',
              orderId: '',
              userSearch: ''
            });
            setDateRange({
              startDate: '',
              endDate: ''
            });
          }}
          style={{
            padding: '8px 16px',
            backgroundColor: '#F3F4F6',
            color: '#4B5563',
            border: 'none',
            borderRadius: '0.5rem',
            fontSize: '0.875rem',
            fontWeight: '500'
          }}
        >
          Clear Filters
        </button>
        <button
          className="btn"
          onClick={onFilter}
          style={{
            padding: '8px 16px',
            backgroundColor: '#2563EB',
            color: '#FFFFFF',
            border: 'none',
            borderRadius: '0.5rem',
            fontSize: '0.875rem',
            fontWeight: '500'
          }}
        >
          Apply Filters
        </button>
      </div>
    </div>
  );
};

export default OrderFilters;