import React from 'react';

const LoadingAnimations = ({ type = 'pulse', color = '#2563eb', size = 'md' }) => {
  // Size mappings
  const sizes = {
    sm: 'h-8 w-8',
    md: 'h-12 w-12',
    lg: 'h-16 w-16'
  };

  const animations = {
    // Pulse Circle Animation
    pulse: (
      <div className={`relative ${sizes[size]}`}>
        <div className="absolute inset-0 rounded-full bg-blue-500 opacity-75 animate-ping"
          style={{ backgroundColor: color }}
        />
        <div className="relative rounded-full bg-blue-500 h-full w-full"
          style={{ backgroundColor: color }}
        />
      </div>
    ),

    // Spinning Ring Animation
    ring: (
      <div className={`${sizes[size]} border-4 border-t-blue-500 rounded-full animate-spin`}
        style={{ borderTopColor: color }}
      />
    ),

    // Dots Animation
    dots: (
      <div className="flex space-x-2">
        {[0, 1, 2].map((index) => (
          <div
            key={index}
            className={`${size === 'sm' ? 'h-2 w-2' : size === 'md' ? 'h-3 w-3' : 'h-4 w-4'} 
                       rounded-full bg-blue-500 animate-bounce`}
            style={{
              backgroundColor: color,
              animationDelay: `${index * 0.15}s`
            }}
          />
        ))}
      </div>
    ),

    // Gradient Spinner
    gradient: (
      <div className={`relative ${sizes[size]}`}>
        <div className={`absolute w-full h-full rounded-full animate-spin`}
          style={{
            background: `conic-gradient(from 0deg, ${color} 0%, transparent 65%)`
          }}
        />
      </div>
    )
  };

  // Overlay container
  return (
    <div className="fixed inset-0 bg-white bg-opacity-75 flex items-center justify-center z-50">
      {animations[type]}
    </div>
  );
};

export default LoadingAnimations;


// usages:

// <LoadingAnimations
//   type="pulse"
//   color="#2563eb"
//   size="md"
// />