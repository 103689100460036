import React from 'react';
import { Outlet } from 'react-router-dom';
import AdminSidebarMenu from './AdminSidebarMenu';
import AdminMobileMenu from './AdminMobileMenu';
import AdminDashboardHeader from './AdminDashboardHeader';


const AdminMainDashBoardLayout = () => {
    return (
        <div className="d-xl-flex">
            {/* Sidebar */}
            <AdminSidebarMenu />
            {/* Mobile Menu */}
            <AdminMobileMenu />
            {/* Mobile End */}
            {/* Right */}
            <div className="flex-grow-1 align-items-center position-relative">
                {/* Header */}
                <AdminDashboardHeader />
                {/* Main Content - Outlet for Nested Routes */}
                <div className="main-content">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default AdminMainDashBoardLayout;
