import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const StatisticsSplineChartNew = ({ products, orders, sellers }) => {
  console.log('Products:', products);
  console.log('Orders:', orders);
  console.log('Sellers:', sellers);

  const processData = () => {
    const defaultData = Array.from({ length: 12 }, (_, i) => ({
      name: i + 1,
      totalProducts: 0,
      totalOrders: 0,
      totalSellers: 0
    }));

    if (!products?.length && !orders?.length && !sellers?.length) {
      console.log('No data available, using default data');
      return defaultData;
    }

    const monthlyData = [...defaultData];

    if (orders?.length) {
      orders.forEach(order => {
        if (order?.orderDate) {
          const [day, month] = order.orderDate.split('/');
          const monthIndex = parseInt(month) - 1;
          if (monthIndex >= 0 && monthIndex < 12) {
            monthlyData[monthIndex].totalOrders++;
          }
        }
      });
    }

    if (products?.length) {
      const productsPerMonth = Math.ceil(products.length / 12);
      products.forEach((_, index) => {
        const monthIndex = Math.floor(index / productsPerMonth);
        if (monthIndex < 12) {
          monthlyData[monthIndex].totalProducts++;
        }
      });
    }

    if (sellers?.length) {
      sellers.forEach(seller => {
        if (seller?.createdAt) {
          const loginDate = new Date(seller.createdAt);
          const monthIndex = loginDate.getMonth();
          if (monthIndex >= 0 && monthIndex < 12) {
            monthlyData[monthIndex].totalSellers++;
          }
        }
      });
    }

    console.log('Processed data:', monthlyData);
    return monthlyData;
  };

  const data = processData();

  const getMonthName = (month) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[month - 1];
  };

  const cardStyle = {
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: '24px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'
  };

  const titleStyle = {
    fontSize: '1.25rem',
    fontWeight: '600',
    color: '#2d3748',
    marginBottom: '1.5rem'
  };

  const chartContainerStyle = {
    width: '100%',
    height: '400px'
  };

  return (
    <div className="col-12">
      <div className="card" style={cardStyle}>
        <h3 style={titleStyle}>Monthly Statistics Overview</h3>
        <div style={chartContainerStyle}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="name"
                tickFormatter={getMonthName}
              />
              <YAxis />
              <Tooltip
                labelFormatter={getMonthName}
                contentStyle={{
                  backgroundColor: '#fff',
                  border: '1px solid #dee2e6',
                  borderRadius: '0.375rem',
                  padding: '0.5rem'
                }}
              />
              <Legend />
              <Line 
                type="monotone" 
                dataKey="totalProducts" 
                name="Products Added"
                stroke="#0d6efd"
                strokeWidth={2}
                dot
              />
              <Line 
                type="monotone" 
                dataKey="totalOrders" 
                name="Place Orders"
                stroke="#198754"
                strokeWidth={2}
                dot
              />
              <Line 
                type="monotone" 
                dataKey="totalSellers" 
                name="Sellers Joined"
                stroke="#6f42c1"
                strokeWidth={2}
                dot
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default StatisticsSplineChartNew;