import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import "react-quill/dist/quill.snow.css";
import auth from "../../firebase.init";
import Pagination from "../../components/Shared/Pagination";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoadingAnimations from "../../components/Shared/LoadingAnimations";
import { Search, SquarePen, Trash2, Calendar } from "lucide-react";
import toast from "react-hot-toast";

const AdminWithdraw = () => {
    const [withdraw, setWithdraw] = useState(null);
    const [users, setUsers] = useState([]);
    const [user] = useAuthState(auth);
    const [currentDate] = useState(formatDate(new Date()));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedWithdrawal, setSelectedWithdrawal] = useState(null);
    const [newStatus, setNewStatus] = useState("Pending");
    const [activeTab, setActiveTab] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');

    // New filter states
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [withdrawalIdFilter, setWithdrawalIdFilter] = useState('');

    // New modal fields
    const [adminPaypalEmail, setAdminPaypalEmail] = useState('');
    const [paymentDate, setPaymentDate] = useState('');
    const [transactionId, setTransactionId] = useState('');

    // Fetch withdraw data
    useEffect(() => {
        if (user?.email) {
            fetch(`https://server.enjoywiki.com/flip-server/all-withdraw`)
                .then((res) => res.json())
                .then((info) => {
                    const withdrawalsWithStatus = info.map(withdrawal => ({
                        ...withdrawal,
                        withdrawalStatus: withdrawal.withdrawalStatus || "Pending"
                    }));
                    setWithdraw(withdrawalsWithStatus.reverse());
                })
                .catch((error) => {
                    console.error("Error fetching withdrawals:", error);
                    toast.error("Failed to fetch withdrawals. Please try again later.");
                });
        }
    }, [user?.email]);

    // Fetch users data
    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/users`)
            .then((res) => res.json())
            .then((info) => {
                setUsers(info);
            })
            .catch((error) => {
                console.error('Error fetching users:', error);
                toast.error('Failed to fetch users data');
            });
    }, []);

    // Helper function to get status class safely
    const getStatusClass = (status) => {
        if (!status) return "";
        return status.toLowerCase();
    };

    // Get user info for a withdrawal
    const getUserInfo = (withdrawalEmail) => {
        return users.find(user => user.UserEmail === withdrawalEmail) || null;
    };

    // Filter withdrawals based on all criteria
    const getFilteredWithdrawals = () => {
        if (!withdraw) return [];

        let filtered = withdraw;

        // Filter by status tab
        switch (activeTab) {
            case 'completed':
                filtered = filtered.filter(w => w.withdrawalStatus === 'Completed');
                break;
            case 'processing':
                filtered = filtered.filter(w => w.withdrawalStatus === 'Processing');
                break;
            case 'pending':
                filtered = filtered.filter(w => w.withdrawalStatus === 'Pending');
                break;
            case 'cancelled':
                filtered = filtered.filter(w => w.withdrawalStatus === 'Cancelled');
                break;
        }

        // Filter by date range
        if (startDate && endDate) {
            filtered = filtered.filter(w => {
                const withdrawalDate = new Date(w.withdrawalDate.split('-').reverse().join('-'));
                const start = new Date(startDate);
                const end = new Date(endDate);
                return withdrawalDate >= start && withdrawalDate <= end;
            });
        }

        // Filter by withdrawal ID
        if (withdrawalIdFilter) {
            filtered = filtered.filter(w =>
                w.withdrawal_id.toLowerCase().includes(withdrawalIdFilter.toLowerCase())
            );
        }

        // Filter by search term
        if (searchTerm.trim()) {
            const searchLower = searchTerm.toLowerCase();
            filtered = filtered.filter(withdrawal => {
                const userInfo = getUserInfo(withdrawal.withdrawalUserEmail);
                return (
                    withdrawal.withdrawalUserEmail?.toLowerCase().includes(searchLower) ||
                    withdrawal.paypalEmail?.toLowerCase().includes(searchLower) ||
                    userInfo?.userName?.toLowerCase().includes(searchLower)
                );
            });
        }

        return filtered;
    };

    // Helper Functions
    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    // Status Update Handler
    const handleStatusUpdate = async () => {
        if (!selectedWithdrawal || !newStatus) {
            toast.error("Please select a status");
            return;
        }

        setIsSubmitting(true);
        try {
            const response = await fetch(`https://server.enjoywiki.com/flip-server/withdraw-status/${selectedWithdrawal._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    status: newStatus,
                    adminPaypalEmail,
                    paymentDate,
                    transactionId
                }),
            });

            if (response.ok) {
                const updatedWithdraw = withdraw.map(w =>
                    w._id === selectedWithdrawal._id
                        ? {
                            ...w,
                            withdrawalStatus: newStatus,
                            adminPaypalEmail,
                            paymentDate,
                            transactionId
                        }
                        : w
                );
                setWithdraw(updatedWithdraw);
                toast.success('Status updated successfully');
                setIsModalOpen(false);
            } else {
                throw new Error('Failed to update status');
            }
        } catch (error) {
            console.error('Error updating status:', error);
            toast.error('Failed to update status. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    // Pagination setup
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const filteredWithdrawals = getFilteredWithdrawals();
    const currentService = filteredWithdrawals.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    // Reset pagination when filters change
    useEffect(() => {
        setCurrentPage(1);
    }, [searchTerm, startDate, endDate, withdrawalIdFilter]);

    return (
        <main className="dashboard-main min-vh-100">
            <div className="d-flex flex-column gap-4 pb-110 p-4">
                <Helmet>
                    <title>Withdrawals | FlipBundle - Manage Your Digital Product Listings</title>
                </Helmet>

                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <h3 className="text-24 fw-bold text-dark-300 mb-2">Withdrawn Lists</h3>
                        <ul className="d-flex align-items-center gap-2">
                            <li className="text-dark-200 fs-6">Admin Dashboard</li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width={5} height={11} viewBox="0 0 5 11" fill="none">
                                    <path d="M1 10L4 5.5L1 1" stroke="#5B5B5B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </li>
                            <li className="text-lime-300 fs-6">All withdrawn</li>
                        </ul>
                    </div>
                </div>

                <div>
                    <nav>
                        <div className="d-flex flex-wrap gap-3 align-items-center" role="tablist">
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'all' ? 'active' : ''}`}
                                onClick={() => setActiveTab('all')}
                            >
                                All({withdraw?.length || 0})
                            </button>
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'completed' ? 'active' : ''}`}
                                onClick={() => setActiveTab('completed')}
                            >
                                Completed({withdraw?.filter(w => w.withdrawalStatus === 'Completed').length || 0})
                            </button>
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'processing' ? 'active' : ''}`}
                                onClick={() => setActiveTab('processing')}
                            >
                                Processing({withdraw?.filter(w => w.withdrawalStatus === 'Processing').length || 0})
                            </button>
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'pending' ? 'active' : ''}`}
                                onClick={() => setActiveTab('pending')}
                            >
                                Pending({withdraw?.filter(w => w.withdrawalStatus === 'Pending').length || 0})
                            </button>
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'cancelled' ? 'active' : ''}`}
                                onClick={() => setActiveTab('cancelled')}
                            >
                                Cancelled({withdraw?.filter(w => w.withdrawalStatus === 'Cancelled').length || 0})
                            </button>
                        </div>
                        <div className="card p-3 mb-3 mt-3">
                            <div className="row g-3">
                                <div className="col-md-3">
                                    <label className="form-label">Start Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">End Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">Withdrawal ID</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Withdrawal ID"
                                        value={withdrawalIdFilter}
                                        onChange={(e) => setWithdrawalIdFilter(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-3 d-flex align-items-end">
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => {
                                            setStartDate('');
                                            setEndDate('');
                                            setWithdrawalIdFilter('');
                                        }}
                                    >
                                        Clear Filters
                                    </button>
                                </div>
                            </div>
                        </div>
                    </nav>
                    {/* Search Input */}
                    <div className="position-relative mt-3">
                        <input
                            type="text"
                            className="form-control ps-5"
                            placeholder="Search by name or email..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <Search className="position-absolute start-2 top-50 translate-middle-y text-muted" size={20} />
                    </div>

                    {/* Content */}
                    <div className="tab-content mt-4">
                        <div className="gig-info-card">
                            <div className="gig-info-body">
                                <div className="overflow-x-auto">
                                    <div className="w-100">
                                        <table className="w-100 dashboard-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="ps-4">User Info</th>
                                                    <th scope="col">Withdrawal ID</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Paypal Email</th>
                                                    <th scope="col" className="text-start">Status</th>
                                                    <th scope="col" className="text-start">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {withdraw === null ? (
                                                    <tr>
                                                        <td colSpan="7" className="text-center p-4">
                                                            <LoadingAnimations
                                                                type="pulse"
                                                                color="#2563eb"
                                                                size="md"
                                                            />
                                                        </td>
                                                    </tr>
                                                ) : currentService.length === 0 ? (
                                                    <tr>
                                                        <td colSpan="7" className="text-center p-4">
                                                            {searchTerm ? "No withdrawals found matching your search." : "No withdrawals found for the selected filter"}
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    currentService.map((withdrawal) => {
                                                        const userInfo = getUserInfo(withdrawal.withdrawalUserEmail);
                                                        return (
                                                            <tr key={withdrawal?._id || Math.random()}>
                                                                <td>
                                                                    <div className="d-flex gap-3 align-items-center project-name">
                                                                        <div className="order-img">
                                                                            {userInfo?.profileURL && (
                                                                                <img
                                                                                    src={userInfo.profileURL}
                                                                                    alt={userInfo.userName}
                                                                                    style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '8px' }}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                        <div>
                                                                            {userInfo?.userName || 'N/A'}
                                                                            <ul className="d-flex gap-1 order-category mt-1">
                                                                                <li className="text-dark-200">{withdrawal.withdrawalUserEmail}</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="ps-4 fw-semibold">
                                                                        {withdrawal?.withdrawal_id || 'N/A'}
                                                                    </div>
                                                                </td>
                                                                <td className="text-dark-200">
                                                                    {withdrawal?.withdrawalDate || 'N/A'}
                                                                </td>
                                                                <td className="text-dark-200">
                                                                    ${withdrawal?.withdrawalAmount?.toFixed(2) || '0.00'} USD
                                                                </td>
                                                                <td className="text-dark-200">
                                                                    {withdrawal?.paypalEmail || 'N/A'}
                                                                </td>
                                                                <td>
                                                                    <span className={`status-badge ${getStatusClass(withdrawal?.withdrawalStatus)}`}>
                                                                        {withdrawal?.withdrawalStatus || 'Pending'}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <button
                                                                        className="btn btn-primary btn-sm"
                                                                        onClick={() => {
                                                                            setSelectedWithdrawal(withdrawal);
                                                                            setNewStatus(withdrawal?.withdrawalStatus || 'Pending');
                                                                            setAdminPaypalEmail(withdrawal?.adminPaypalEmail || '');
                                                                            setPaymentDate(withdrawal?.paymentDate || '');
                                                                            setTransactionId(withdrawal?.transactionId || '');
                                                                            setIsModalOpen(true);
                                                                        }}
                                                                    >
                                                                        Update Status
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                {/* Empty State */}
                                {currentService.length === 0 && (
                                    <div className="row justify-content-center">
                                        <div className="col-7">
                                            <div className="bg-white p-5 rounded-3 text-center">
                                                <img
                                                    src="https://workzone.mamunuiux.com/uploads/website-images/empty-2024-05-17-11-50-01-3653.png"
                                                    className="img-fluid mb-4"
                                                    alt="Empty withdrawals"
                                                />
                                                <h3 className="text-24 fw-bold text-dark-300 mb-2">
                                                    No Withdrawals Found
                                                </h3>
                                                <p className="fs-6 text-dark-200">
                                                    {searchTerm
                                                        ? "No withdrawals match your search criteria"
                                                        : "No withdrawals match your current filter criteria"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Pagination */}
                                {filteredWithdrawals.length > itemsPerPage && (
                                    <div className="pagination-container mt-4 d-flex justify-content-center">
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={Math.ceil(filteredWithdrawals.length / itemsPerPage)}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Enhanced Status Update Modal */}
                <div className={`modal fade ${isModalOpen ? 'show' : ''}`}
                    style={{ display: isModalOpen ? 'block' : 'none' }}
                    tabIndex="-1"
                    role="dialog"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Update Withdrawal Status</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => setIsModalOpen(false)}
                                ></button>
                            </div>
                            <div className="modal-body">
                                {selectedWithdrawal && (
                                    <div>
                                        <div className="alert alert-info mb-3">
                                            <p className="mb-1"><strong>Withdrawal ID:</strong> {selectedWithdrawal.withdrawal_id}</p>
                                            <p className="mb-1"><strong>Amount:</strong> ${selectedWithdrawal.withdrawalAmount?.toFixed(2)} USD</p>
                                            <p className="mb-1"><strong>User Email:</strong> {selectedWithdrawal.withdrawalUserEmail}</p>
                                            {
                                                selectedWithdrawal.adminPaypalEmail &&
                                                <p className="mb-1"><strong>Admin PayPal Email:</strong> {selectedWithdrawal.adminPaypalEmail}</p>
                                            }
                                            {
                                                selectedWithdrawal.paymentDate &&
                                                <p className="mb-1"><strong>Payment Date:</strong> {selectedWithdrawal.paymentDate}</p>
                                            }
                                            {
                                                selectedWithdrawal.transactionId &&
                                                <p className="mb-0"><strong>Transaction Id:</strong> {selectedWithdrawal.transactionId}</p>
                                            }


                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Status</label>
                                            <select
                                                className="form-select"
                                                value={newStatus}
                                                onChange={(e) => setNewStatus(e.target.value)}
                                            >
                                                <option value="Pending">Pending</option>
                                                <option value="Processing">Processing</option>
                                                <option value="Completed">Completed</option>
                                                <option value="Cancelled">Cancelled</option>
                                            </select>
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Admin PayPal Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                value={adminPaypalEmail}
                                                onChange={(e) => setAdminPaypalEmail(e.target.value)}
                                                defaultValue={selectedWithdrawal.adminPaypalEmail || ''}
                                                placeholder="Enter admin PayPal email"
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Payment Date</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={paymentDate}
                                                onChange={(e) => setPaymentDate(e.target.value)}
                                                defaultValue={selectedWithdrawal.paymentDate || ''}
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Transaction ID</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={transactionId}
                                                onChange={(e) => setTransactionId(e.target.value)}
                                                defaultValue={selectedWithdrawal.transactionId || ''}
                                                placeholder="Enter transaction ID"
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setIsModalOpen(false)}
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleStatusUpdate}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Updating...' : 'Update Status'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal Backdrop */}
                {isModalOpen && (
                    <div
                        className="modal-backdrop fade show"
                        onClick={() => setIsModalOpen(false)}
                    ></div>
                )}
            </div>
        </main>
    );
};

export default AdminWithdraw;