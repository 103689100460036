import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import "react-quill/dist/quill.snow.css";
import auth from "../../firebase.init";
import Pagination from "../../components/Shared/Pagination";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoadingAnimations from "../../components/Shared/LoadingAnimations";
import { Mail, Search, SquarePen, X } from "lucide-react";
import AdminUserEmail from "./AdminUserEmail";

const AdminAllUsers = () => {
    const [users, setUsers] = useState([]);
    const [user] = useAuthState(auth);
    const [orders, setOrders] = useState([]);
    const [activeTab, setActiveTab] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [editingUser, setEditingUser] = useState(null);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [selectedSeller, setSelectedSeller] = useState(null);

    // Fetch users
    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/users`)
            .then((res) => res.json())
            .then((info) => setUsers(info.reverse()));
    }, [user?.email]);

    // Fetch orders
    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/orders`)
            .then((res) => res.json())
            .then((info) => setOrders(info));
    }, []);

    const handleEditClick = (user) => {
        setEditingUser(user);
        setShowModal(true);
    };

    const handleUpdateUser = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://server.enjoywiki.com/flip-server/update-user/${editingUser._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editingUser),
            });

            if (response.ok) {
                setUsers(users.map(u =>
                    u._id === editingUser._id ? editingUser : u
                ));
                setShowModal(false);
                setEditingUser(null);
            }
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    // Filter users based on active tab, search term, and date range
    const getFilteredUsers = () => {
        let filtered = users;

        // Filter by role
        switch (activeTab) {
            case 'admin':
                filtered = filtered.filter(user => user.userRole === 'Admin');
                break;
            case 'seller':
                filtered = filtered.filter(user => user.userRole === 'Seller');
                break;
            case 'buyer':
                filtered = filtered.filter(user => user.userRole === 'Buyer');
                break;
            default:
                break;
        }

        // Filter by date range
        if (startDate && endDate) {
            filtered = filtered.filter(user => {
                const createdDate = new Date(user.createdAt);
                const start = new Date(startDate);
                const end = new Date(endDate);
                // Set end date to end of day for inclusive range
                end.setHours(23, 59, 59, 999);
                return createdDate >= start && createdDate <= end;
            });
        }

        // Filter by search term
        if (searchTerm.trim()) {
            const searchLower = searchTerm.toLowerCase();
            filtered = filtered.filter(user =>
                (user.userName?.toLowerCase().includes(searchLower)) ||
                (user.UserEmail?.toLowerCase().includes(searchLower))
            );
        }

        return filtered;
    };

    const filteredUsers = getFilteredUsers();

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentService = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    // Reset pagination when filters change
    useEffect(() => {
        setCurrentPage(1);
    }, [searchTerm, startDate, endDate]);

    const handleEmailClick = (user) => {
        setSelectedSeller(user);
        setShowEmailModal(true);
    };

    return (
        <main className="dashboard-main min-vh-100">
            <div className="d-flex flex-column gap-4 pb-110 p-4">
                <Helmet>
                    <title>Users List | FlipBundle - Manage Your Digital Product Listings</title>
                    <meta name="description" content="View and manage your digital product listings on FlipBundle. Easily update, promote, and track your products for increased sales." />
                </Helmet>

                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <h3 className="text-24 fw-bold text-dark-300 mb-2">Users List</h3>
                        <ul className="d-flex align-items-center gap-2">
                            <li className="text-dark-200 fs-6">Admin Dashboard</li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width={5} height={11} viewBox="0 0 5 11" fill="none">
                                    <path d="M1 10L4 5.5L1 1" stroke="#5B5B5B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </li>
                            <li className="text-lime-300 fs-6">All Users</li>
                        </ul>
                    </div>
                </div>

                {/* Filter Tabs */}
                <div>
                    <nav>
                        <div className="d-flex flex-wrap gap-3 align-items-center" role="tablist">
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'all' ? 'active' : ''}`}
                                onClick={() => setActiveTab('all')}
                            >
                                All({users.length})
                            </button>
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'admin' ? 'active' : ''}`}
                                onClick={() => setActiveTab('admin')}
                            >
                                Admin({users.filter(user => user.userRole === 'Admin').length})
                            </button>
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'seller' ? 'active' : ''}`}
                                onClick={() => setActiveTab('seller')}
                            >
                                Sellers({users.filter(user => user.userRole === 'Seller').length})
                            </button>
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'buyer' ? 'active' : ''}`}
                                onClick={() => setActiveTab('buyer')}
                            >
                                Buyers({users.filter(user => user.userRole === 'Buyer').length})
                            </button>
                        </div>
                    </nav>

                    {/* Date Range Filter */}
                    <div
                        className="row mt-4 p-3"
                        style={{
                            backgroundColor: "#f8f9fa",
                            borderRadius: "8px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        {/* Search Area */}
                        <div className="col-12 col-md-6 mb-3">
                            <div className="position-relative">
                                <label htmlFor="searchInput" className="form-label small text-muted">Search</label>
                                <input
                                    type="text"
                                    id="searchInput"
                                    className="form-control ps-5"
                                    placeholder="Search by name or email..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    style={{
                                        borderRadius: "6px",
                                        border: "1px solid #ced4da",
                                    }}
                                />

                            </div>
                        </div>

                        {/* Date Options */}
                        <div className="col-12 col-md-6 d-flex gap-3">
                            <div className="position-relative w-100">
                                <label htmlFor="startDate" className="form-label small text-muted">Start Date</label>
                                <input
                                    type="date"
                                    id="startDate"
                                    className="form-control"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    style={{
                                        borderRadius: "6px",
                                        border: "1px solid #ced4da",
                                    }}
                                />
                            </div>
                            <div className="position-relative w-100">
                                <label htmlFor="endDate" className="form-label small text-muted">End Date</label>
                                <input
                                    type="date"
                                    id="endDate"
                                    className="form-control"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    style={{
                                        borderRadius: "6px",
                                        border: "1px solid #ced4da",
                                    }}
                                />
                            </div>
                        </div>
                    </div>



                    {/* Content */}
                    <div className="gig-info-card mt-4">
                        <div className="gig-info-body">
                            <div className="overflow-x-auto">
                                <div className="w-100">
                                    <table className="w-100 dashboard-table">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="ps-4">User Info</th>
                                                <th scope="col" className="ps-4">User Log</th>
                                                <th scope="col">Total Sales</th>
                                                <th scope="col">Balance (USD)</th>
                                                <th scope="col">User Role</th>
                                                <th scope="col" className="text-center">Actions</th>
                                                <th scope="col" className="text-center">Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users === null ? (
                                                <tr>
                                                    <td colSpan="6" className="text-center p-4">
                                                        <LoadingAnimations
                                                            type="pulse"
                                                            color="#2563eb"
                                                            size="md"
                                                        />
                                                    </td>
                                                </tr>
                                            ) : currentService.length === 0 ? (
                                                <tr>
                                                    <td colSpan="6" className="text-center p-4">
                                                        {searchTerm ? "No users found matching your search." : "No Users Found!"}
                                                    </td>
                                                </tr>
                                            ) : (
                                                currentService.map((user) => (
                                                    <tr key={user._id}>
                                                        <td>
                                                            <div className="d-flex gap-3 align-items-center project-name">
                                                                <div className="order-img">
                                                                    {user.profileURL && (
                                                                        <img
                                                                            src={user.profileURL}
                                                                            alt={user.userName}
                                                                            style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '8px' }}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    {user.userRole === 'Seller' ? <Link to={`/profile-seller/${user._id}`}>{user.userName}</Link> : user.userName}
                                                                    <ul className="d-flex gap-1 order-category mt-1">
                                                                        {user.UserEmail && <li className="text-dark-200">{user.UserEmail}</li>}
                                                                    </ul>
                                                                    <ul className="d-flex gap-1 order-category mt-1">
                                                                        {user.country && <li className="text-dark-200">Country - {user.country}</li>}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="text-dark-200">
                                                            {user.createdAt ? (
                                                                <>
                                                                    Created:{" "}
                                                                    {new Date(user.createdAt).toLocaleDateString("en-US", {
                                                                        year: "numeric",
                                                                        month: "long",
                                                                        day: "numeric",
                                                                    })}, {new Date(user.createdAt).toLocaleTimeString("en-US", {
                                                                        hour: "2-digit",
                                                                        minute: "2-digit",
                                                                        hour12: true,
                                                                    })}
                                                                </>
                                                            ) : (
                                                                "Created Date Not Available"
                                                            )}
                                                            <ul className="d-flex gap-1 order-category mt-1">
                                                                {user.lastLoginDate ? (
                                                                    <>
                                                                        Last Login:{" "}
                                                                        {new Date(user.lastLoginDate).toLocaleDateString("en-US", {
                                                                            year: "numeric",
                                                                            month: "long",
                                                                            day: "numeric",
                                                                        })}, {new Date(user.lastLoginDate).toLocaleTimeString("en-US", {
                                                                            hour: "2-digit",
                                                                            minute: "2-digit",
                                                                            hour12: true,
                                                                        })}
                                                                    </>
                                                                ) : (
                                                                    "Last Login Date Not Available"
                                                                )}
                                                            </ul>
                                                        </td>
                                                        <td className="text-dark-200">
                                                            {orders.filter(order =>
                                                                order.sellerEmail === user.UserEmail &&
                                                                order.paymentStatus === 'Paid'
                                                            ).length}
                                                        </td>
                                                        <td className="text-dark-200">
                                                            ${Number(user.currentBalance || 0).toFixed(2)}
                                                        </td>
                                                        <td className="text-start">
                                                            <button
                                                                className={`btn btn-sm ${user.userRole === 'Admin' ? 'btn-warning' :
                                                                    user.userRole === 'Seller' ? 'btn-info' :
                                                                        user.userRole === 'Buyer' ? 'btn-success' :
                                                                            'btn-secondary'
                                                                    }`}
                                                            >
                                                                {user.userRole}
                                                            </button>
                                                        </td>
                                                        <td className="text-center">
                                                            <button
                                                                onClick={() => handleEditClick(user)}
                                                                className="btn btn-primary btn-sm"
                                                            >
                                                                <SquarePen size={15} />
                                                                {' '}Edit
                                                            </button>
                                                        </td>
                                                        <td className="text-center">
                                                            <button
                                                                className="btn btn-primary btn-sm"
                                                                onClick={() => {

                                                                    handleEmailClick(user);
                                                                }}
                                                            >
                                                                <Mail size={15} /> Message
                                                            </button>

                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                    <AdminUserEmail
                                        show={showEmailModal}
                                        handleClose={() => {
                                            setShowEmailModal(false);
                                            setSelectedSeller(null);
                                        }}
                                        recipientEmail={selectedSeller?.UserEmail}
                                        recipientName={selectedSeller?.userName}
                                    />
                                </div>
                            </div>
                            {/* Pagination */}
                            {filteredUsers.length > itemsPerPage && (
                                <div className="pagination-container mt-4 d-flex justify-content-center">
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={Math.ceil(filteredUsers.length / itemsPerPage)}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            )}</div>
                    </div>
                </div>
            </div>

            {/* Edit User Modal */}
            {showModal && (
                <div className="modal show d-block" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit User Information</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => {
                                        setShowModal(false);
                                        setEditingUser(null);
                                    }}
                                ></button>
                            </div>
                            <form onSubmit={handleUpdateUser}>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <label className="form-label">Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={editingUser?.userName || ''}
                                            onChange={(e) => setEditingUser({
                                                ...editingUser,
                                                userName: e.target.value
                                            })}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Email</label>
                                        <input
                                            disabled
                                            type="email"
                                            className="form-control"
                                            value={editingUser?.UserEmail || ''}
                                            onChange={(e) => setEditingUser({
                                                ...editingUser,
                                                UserEmail: e.target.value
                                            })}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Profile URL</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={editingUser?.profileURL || ''}
                                            onChange={(e) => setEditingUser({
                                                ...editingUser,
                                                profileURL: e.target.value
                                            })}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">User Role</label>
                                        <select
                                            className="form-select"
                                            value={editingUser?.userRole || ''}
                                            onChange={(e) => setEditingUser({
                                                ...editingUser,
                                                userRole: e.target.value
                                            })}
                                        >
                                            <option value="Admin">Admin</option>
                                            <option value="Seller">Seller</option>
                                            <option value="Buyer">Buyer</option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Address</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={editingUser?.address || ''}
                                            onChange={(e) => setEditingUser({
                                                ...editingUser,
                                                address: e.target.value
                                            })}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">City</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={editingUser?.city || ''}
                                            onChange={(e) => setEditingUser({
                                                ...editingUser,
                                                city: e.target.value
                                            })}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Country</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={editingUser?.country || ''}
                                            onChange={(e) => setEditingUser({
                                                ...editingUser,
                                                country: e.target.value
                                            })}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Current Balance</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={editingUser?.currentBalance || 0}
                                            onChange={(e) => setEditingUser({
                                                ...editingUser,
                                                currentBalance: parseFloat(e.target.value)
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={() => {
                                            setShowModal(false);
                                            setEditingUser(null);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        Save Changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal Backdrop */}
            {showModal && <div className="modal-backdrop show"></div>}
        </main>
    );
};

export default AdminAllUsers;