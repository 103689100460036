import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import "react-quill/dist/quill.snow.css";
import auth from "../../firebase.init";
import Pagination from "../../components/Shared/Pagination";
import { Helmet } from "react-helmet";
import LoadingAnimations from "../../components/Shared/LoadingAnimations";
import { SquarePen, Trash2 } from "lucide-react";
import { toast } from 'react-hot-toast';

const CategoryModal = ({ isOpen, onClose, onSubmit, editCategory = null, uniqueMainCategories }) => {
    const [formData, setFormData] = useState({
        categoryName: '',
        SEOTitle: '',
        categoryDetails: '',
        mainCategory: '',
        adminEmail: '',
        image: '',
    });
    const [user] = useAuthState(auth);

    // Update form data when editCategory changes
    useEffect(() => {
        if (editCategory) {
            setFormData({
                categoryName: editCategory.categoryName || '',
                SEOTitle: editCategory.SEOTitle || '',
                categoryDetails: editCategory.categoryDetails || '',
                mainCategory: editCategory.mainCategory || '',
                adminEmail: editCategory.adminEmail || '',
                image: editCategory.image || '',
            });
        } else {
            // Reset form when adding new category
            setFormData({
                categoryName: '',
                SEOTitle: '',
                categoryDetails: '',
                mainCategory: '',
                adminEmail: '',
                image: '',
            });
        }
    }, [editCategory]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="modal show d-block" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{editCategory ? 'Edit Category' : 'Add New Category'}</h5>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label">Category Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="categoryName"
                                            value={formData.categoryName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label">SEO Title</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="SEOTitle"
                                            value={formData.SEOTitle}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label">Main Category</label>
                                        <select
                                            className="form-select"
                                            name="mainCategory"
                                            value={formData.mainCategory}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="">Select main category</option>
                                            {uniqueMainCategories.map((cat) => (
                                                <option key={cat._id} value={cat.name}>
                                                    {cat.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label">Admin Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="adminEmail"
                                            value={user?.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-label">Image URL</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="image"
                                            value={formData.image}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-label">Category Details</label>
                                        <textarea
                                            className="form-control"
                                            name="categoryDetails"
                                            value={formData.categoryDetails}
                                            onChange={handleChange}
                                            rows="4"
                                            required
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer mt-4">
                                <button type="button" className="btn btn-secondary" onClick={onClose}>
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    {editCategory ? 'Update Category' : 'Add Category'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};



// Main Component
const AdminMainCategory = () => {
    const [categories, setCategories] = useState([]);
    const [uniqueMainCategories, setUniqueMainCategories] = useState([]);
    const [user] = useAuthState(auth);
    const [activeTab, setActiveTab] = useState('all');
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingCategory, setEditingCategory] = useState(null);

    // Generate slug function
    const generateSlug = (text) => {
        return text
            .toLowerCase()
            .replace(/[^a-zA-Z0-9]+/g, '-')
            .replace(/^-+|-+$/g, '');
    };

    // Fetch categories
    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await fetch(`https://server.enjoywiki.com/flip-server/categories`);
            const data = await response.json();
            setCategories(data.reverse());

            // Extract unique main categories from the categories data
            const mainCats = [...new Set(data.map(cat => cat.mainCategory))];
            const formattedMainCats = mainCats.map(name => ({
                _id: name.toLowerCase(),
                name: name,
                slug: name.toLowerCase()
            }));
            setUniqueMainCategories(formattedMainCats);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching categories:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [user?.email]);

    // Handle category submission
    const handleCategorySubmit = async (formData) => {
        const slug = generateSlug(formData.categoryName);

        if (!editingCategory) {
            const existingCategory = categories.find(category => category.slug === slug);
            if (existingCategory) {
                toast.error("Category already exists");
                return;
            }
        }

        const categoryData = {
            ...formData,
            slug
        };

        const url = editingCategory
            ? `https://server.enjoywiki.com/flip-server/${editingCategory._id}`
            : 'https://server.enjoywiki.com/flip-server/add-category';

        try {
            const response = await fetch(url, {
                method: editingCategory ? "PUT" : "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify(categoryData),
            });

            const result = await response.json();

            if (result.success) {
                toast.success(`Category ${editingCategory ? 'updated' : 'added'} successfully`);
                fetchData();
            } else {
                toast.error(result.message || 'Something went wrong');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred');
        }

        setIsModalOpen(false);
        setEditingCategory(null);
    };

    // Filter categories based on active tab
    const getFilteredCategories = () => {
        if (activeTab === 'all') {
            return categories;
        }
        return categories.filter(cat => cat.mainCategory.toLowerCase() === activeTab);
    };

    const filteredCategories = getFilteredCategories();

    // Get count of categories for each main category
    const getCategoryCount = (mainCategorySlug) => {
        return categories.filter(cat => cat.mainCategory.toLowerCase() === mainCategorySlug).length;
    };

    // Handle delete category
    const handleDeleteCategory = (categoryId) => {
        if (window.confirm("Are you sure you want to delete this category?")) {
            fetch(`https://server.enjoywiki.com/flip-server/seller-category/${categoryId}`, {
                method: "DELETE",
            })
                .then((res) => res.json())
                .then(() => {
                    const updatedCategories = categories.filter((cat) => cat._id !== categoryId);
                    setCategories(updatedCategories);
                })
                .catch((error) => {
                    console.error("Error deleting category:", error);
                });
        }
    };

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentService = filteredCategories.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    // Reset pagination when changing tabs
    useEffect(() => {
        setCurrentPage(1);
    }, [activeTab]);

    return (
        <main className="dashboard-main min-vh-100">
            <div className="d-flex flex-column gap-4 pb-110 p-4">
                <Helmet>
                    <title>All Categories List | FlipBundle - Manage Your Digital Products Listing</title>
                    <meta name="description" content="View and manage your digital product listings on FlipBundle. Easily update, promote, and track your products for increased sales." />
                </Helmet>

                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <h3 className="text-24 fw-bold text-dark-300 mb-2">Main Categories List</h3>
                        <ul className="d-flex align-items-center gap-2">
                            <li className="text-dark-200 fs-6">Admin Dashboard</li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width={5} height={11} viewBox="0 0 5 11" fill="none">
                                    <path d="M1 10L4 5.5L1 1" stroke="#5B5B5B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </li>
                            <li className="text-lime-300 fs-6">All Categories</li>
                        </ul>
                    </div>
                    <button
                        onClick={() => setIsModalOpen(true)}
                        className="d-flex align-items-center gap-2 w-btn-secondary-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                        </svg>
                        Add Category
                    </button>
                </div>

                {/* Filter Tabs */}
                <div>
                    <nav>
                        <div className="d-flex flex-wrap gap-3 align-items-center" role="tablist">
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'all' ? 'active' : ''}`}
                                onClick={() => setActiveTab('all')}
                            >
                                All Categories ({categories.length})
                            </button>

                            {loading ? (
                                <LoadingAnimations type="pulse" color="#2563eb" size="sm" />
                            ) : uniqueMainCategories.length > 0 ? (
                                uniqueMainCategories.map((mainCat) => (
                                    <button
                                        key={mainCat._id}
                                        className={`dashboard-tab-btn ${activeTab === mainCat.slug ? 'active' : ''}`}
                                        onClick={() => setActiveTab(mainCat.slug)}
                                    >
                                        {mainCat.name} ({getCategoryCount(mainCat.slug)})
                                    </button>
                                ))
                            ) : (
                                <p className="text-muted">No main categories found</p>
                            )}
                        </div>
                    </nav>

                    {/* Content */}
                    <div className="gig-info-card mt-4">
                        <div className="gig-info-body">
                            <div className="overflow-x-auto">
                                <div className="w-100">
                                    <table className="w-100 dashboard-table">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="ps-4">Category</th>
                                                <th scope="col">Main Category</th>
                                                <th scope="col">Edit</th>
                                                <th scope="col" className="text-center">Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? (
                                                <tr>
                                                    <td colSpan="5" className="text-center p-4">
                                                        <LoadingAnimations
                                                            type="pulse"
                                                            color="#2563eb"
                                                            size="md"
                                                        />
                                                    </td>
                                                </tr>
                                            ) : currentService.length === 0 ? (
                                                <tr>
                                                    <td colSpan="5" className="text-center p-4">
                                                        No Category Found!
                                                    </td>
                                                </tr>
                                            ) : (
                                                currentService.map((category) => (
                                                    <tr key={category._id}>
                                                        <td>
                                                            <div className="d-flex gap-3 align-items-center project-name">
                                                                <div className="order-img">
                                                                    {category.image && (
                                                                        <img
                                                                            src={category.image}
                                                                            alt={category.categoryName}
                                                                            style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '8px' }}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    {category.categoryName}
                                                                    <ul className="d-flex gap-1 order-category mt-1">
                                                                        {category.slug && <li className="text-dark-200">{category.slug}</li>}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p className="status-badge in-progress font-bold">
                                                                {category.mainCategory}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <button
                                                                onClick={() => {
                                                                    setEditingCategory(category);
                                                                    setIsModalOpen(true);
                                                                }}
                                                                className="btn btn-primary btn-sm"
                                                            >
                                                                <SquarePen size={15} />
                                                                {' '}Edit
                                                            </button>
                                                        </td>
                                                        <td className="text-center">
                                                            <button
                                                                onClick={() => handleDeleteCategory(category._id)}
                                                                className="btn btn-danger btn-sm"
                                                            >
                                                                <Trash2 size={15} />
                                                                {' '}Delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {/* Pagination */}
                            {filteredCategories.length > itemsPerPage && (
                                <div className="pagination-container mt-4 d-flex justify-content-center">
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={Math.ceil(filteredCategories.length / itemsPerPage)}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Category Modal */}
                <CategoryModal
                    isOpen={isModalOpen}
                    onClose={() => {
                        setIsModalOpen(false);
                        setEditingCategory(null);
                    }}
                    onSubmit={handleCategorySubmit}
                    editCategory={editingCategory}
                    uniqueMainCategories={uniqueMainCategories}
                />
            </div>
        </main>

    );
};

export default AdminMainCategory;
