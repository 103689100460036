import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MessageCircle, Search, MoreVertical, Send, X } from 'lucide-react';
import MessageList from './MessageList';

const ContactMessage = () => {
    const [messages, setMessages] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showReplyModal, setShowReplyModal] = useState(false);
    const [replyData, setReplyData] = useState({
        subject: '',
        message: ''
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchMessages();
    }, []);

    const fetchMessages = async () => {
        try {
            const response = await axios.get('https://server.enjoywiki.com/flip-server/contact-messages');
            setMessages(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching messages:', error);
            setLoading(false);
        }
    };

    const handleMessageSelect = async (messageId) => {
        try {
            const response = await axios.get(`https://server.enjoywiki.com/flip-server/contact-message/${messageId}`);
            setSelectedMessage(response.data);
        } catch (error) {
            console.error('Error selecting message:', error);
        }
    };

    const handleOpenReply = () => {
        setReplyData({
            subject: `Re: ${selectedMessage.subject}`,
            message: ''
        });
        setShowReplyModal(true);
    };

    const handleSendReply = async () => {
        if (!replyData.message.trim()) return;
      
        try {
            await axios.post('https://server.enjoywiki.com/flip-server/send-reply', {
                message: replyData.message,
                subject: replyData.subject,
                recipientEmail: selectedMessage.email,
                recipientName: selectedMessage.name,
                originalMessageId: selectedMessage._id
            });

            // Refresh the selected message to show reply details
            await handleMessageSelect(selectedMessage._id);
            setShowReplyModal(false);
            setReplyData({ subject: '', message: '' });
            fetchMessages();
        } catch (error) {
            console.error('Error sending reply:', error);
        }
    };

    const MessageContent = ({ message }) => (
        <div className="p-3 flex-grow-1" style={{ overflowY: 'auto' }}>
            <div className="original-message mb-4">
                <h6 className="mb-3">Subject: {message.subject}</h6>
                <p style={{ whiteSpace: 'pre-wrap' }}>{message.message}</p>
            </div>
            
            {message.replyDetails && (
                <div className="reply-section mt-4 p-3 bg-light rounded">
                    <h6 className="mb-2">Reply History</h6>
                    <div className="reply-details">
                        <div className="small text-muted mb-2">
                            Replied on: {new Date(message.replyDetails.replyDate).toLocaleString()}
                        </div>
                        <div className="mb-2">
                            <strong>Subject:</strong> {message.replyDetails.replySubject}
                        </div>
                        <p style={{ whiteSpace: 'pre-wrap' }}>{message.replyDetails.replyMessage}</p>
                    </div>
                </div>
            )}
        </div>
    );

    return (
        <main className="dashboard-main min-vh-100">
            <div className="container-fluid p-0">
                <div className="row g-0" style={{ minHeight: '90vh' }}>
                    {/* Sidebar */}
                    <div className="col-md-4 border-end" style={{ backgroundColor: '#f8f9fa' }}>
                        <div className="p-3">
                            <h5 className="mb-3 fw-bold">All Messages ({messages.length})</h5>

                            {/* Search */}
                            <div className="position-relative mb-4">
                                <input
                                    type="text"
                                    className="form-control shadow-none"
                                    placeholder="Search messages..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    style={{ paddingLeft: '40px', borderRadius: '8px' }}
                                />
                                <Search 
                                    className="position-absolute text-muted" 
                                    style={{ left: '12px', top: '50%', transform: 'translateY(-50%)' }} 
                                    size={20} 
                                />
                            </div>
                            <MessageList
                                messages={messages}
                                loading={loading}
                                searchTerm={searchTerm}
                                selectedMessage={selectedMessage}
                                onMessageSelect={handleMessageSelect}
                                messageStatus="UnRead"
                            />
                            <MessageList
                                messages={messages}
                                loading={loading}
                                searchTerm={searchTerm}
                                selectedMessage={selectedMessage}
                                onMessageSelect={handleMessageSelect}
                                messageStatus="Replied"
                            />
                        </div>
                    </div>

                    {/* Main Content */}
                    <div className="col-md-8">
                        {selectedMessage ? (
                            <div className="d-flex flex-column h-100">
                                {/* Message Header */}
                                <div className="p-3 border-bottom">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h5 className="mb-1">{selectedMessage.name}</h5>
                                            <p className="mb-0 text-muted small">
                                                {selectedMessage.email}
                                            </p>
                                            <p className="mb-0 text-muted small">
                                                {new Date(selectedMessage.date).toLocaleDateString()} at {new Date(selectedMessage.date).toLocaleTimeString()}
                                            </p>
                                            {selectedMessage.messageStatus === 'Replied' && (
                                                <span className="badge bg-success mt-2">Replied</span>
                                            )}
                                        </div>
                                        <button
                                            className="btn btn-primary"
                                            onClick={handleOpenReply}
                                        >
                                            Reply
                                        </button>
                                    </div>
                                </div>

                                {/* Message Content */}
                                <MessageContent message={selectedMessage} />
                            </div>
                        ) : (
                            <div className="d-flex align-items-center justify-content-center h-100 text-muted">
                                <div className="text-center">
                                    <MessageCircle size={48} className="mb-3" />
                                    <h5>Select a message to view</h5>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* Reply Modal */}
                {showReplyModal && (
                    <div className="modal show d-block" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Reply to Message</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => setShowReplyModal(false)}
                                    />
                                </div>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <label className="form-label">Subject</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={replyData.subject}
                                            onChange={(e) => setReplyData(prev => ({ ...prev, subject: e.target.value }))}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Message</label>
                                        <textarea
                                            className="form-control"
                                            rows="6"
                                            value={replyData.message}
                                            onChange={(e) => setReplyData(prev => ({ ...prev, message: e.target.value }))}
                                            placeholder="Type your reply..."
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={() => setShowReplyModal(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={handleSendReply}
                                    >
                                        Send Reply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </main>
    );
};

export default ContactMessage;